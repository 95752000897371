/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Button, Col, Grid, Panel } from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReferralPanel from '../components/ReferralPanel';
import PersonalDetailsForm from '../components/PersonalDetailsForm';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import Loader from '../../../components/Loader/Loader';
import { User } from '../../../models/User';
import { RootState } from '../../../store';
import { coin } from '../../../img';
import MyAddressForm from '../components/MyAddressForm';
import './ProfileContainer.scss';
import { selectIsLoggedIn, selectUser } from '../../Auth/duck/selector';
import AddCoinsModal from '../components/AddCoinsModal';
import localization from '../../../localization';
import { selectLanguage } from '../../Settings/duck/selector';

interface Props {
  user?: User;
  isLoggedIn: boolean;
  language: string;
}

const ProfileContainer: React.FC<Props> = ({ user, isLoggedIn }) => {
  if (!isLoggedIn) return <Redirect to="/" />;

  return (
    <div id="profile-container">
      <div className="profile-title">{localization.profile.toUpperCase()}</div>
      <Grid>
        {user ? (
          <Col xs={12} sm={6} smOffset={3}>
            <Panel>
              <Panel.Body>
                <ReferralPanel />
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Body>
                <PersonalDetailsForm />
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Body>
                <div className="title">
                  {localization.resetPassword.toUpperCase()}
                </div>
                <UpdatePasswordForm />
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Body>
                <MyAddressForm />
              </Panel.Body>
            </Panel>
            <Panel>
              <Panel.Body>
                <div className="title">
                  {localization.spoonfulCoins.toUpperCase()}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={coin}
                    width="24"
                    height="24"
                    style={{ marginRight: '0.5em' }}
                  />
                  {`${localization.coins.toUpperCase()}: ${_.floor(
                    user.credits,
                  )}`}
                  <div style={{ flex: 1 }} />
                  <AddCoinsModal />
                </div>
              </Panel.Body>
            </Panel>
          </Col>
        ) : (
          <Col
            xs={12}
            sm={6}
            smOffset={3}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Loader />
          </Col>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  isLoggedIn: selectIsLoggedIn(state),
  language: selectLanguage(state),
});

export default connect(mapStateToProps)(ProfileContainer);
