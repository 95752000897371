/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Navbar,
  Nav,
  NavItem,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import _ from 'lodash';
import {
  clearUser,
  toggleLoginDialog,
  getUser,
} from '../../features/Auth/duck';
import { setLanguage as ActionSetLanguage } from '../../features/Settings/duck';
// import { OrderAction } from '../../features/Order/duck';
import ShoppingCartPreview from '../ShoppingCartPreview/ShoppingCartPreview';
import localization from '../../localization';
import { AppDispatch, history, RootState } from '../../store';
import './MenuBar.scss';
import { logo } from '../../img';
import {
  selectCoinsBalance,
  selectIsLoggedIn,
} from '../../features/Auth/duck/selector';
import { selectLanguage } from '../../features/Settings/duck/selector';

interface Props {
  setLanguage: (lang: string) => void;
  getAccountDetail: () => void;
  openLoginDialog: () => void;
  logout: () => void;
  isLoggedIn: boolean;
  credits: number;
  language: string;
}

const MenuBar: React.FC<Props> = ({
  isLoggedIn,
  credits,
  language,
  setLanguage,
  getAccountDetail,
  openLoginDialog,
  logout,
  children,
}) => {
  React.useEffect(() => {
    if (isLoggedIn) getAccountDetail();
  }, [isLoggedIn]);

  const [menuVisible, setMenuVisible] = React.useState(false);

  const onLangPress = () => {
    setLanguage(language === 'en' ? 'zht' : 'en');
  };

  return (
    <div id="top-bar">
      <Navbar id="nav-container" collapseOnSelect>
        <Navbar.Header>
          <a href="/">
            <img className="nosh-logo" src={logo} />
          </a>
          <span
            className="mobile-only"
            style={{ position: 'absolute', top: 15, right: 80 }}
          >
            <ShoppingCartPreview />
          </span>
          <span
            className="mobile-only"
            style={{ position: 'absolute', top: 15, right: 130 }}
          >
            <NavItem className="nav-item" onClick={onLangPress}>
              {language === 'en' ? '繁' : 'ENG'}
            </NavItem>
          </span>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            {[
              {
                label: _.toUpper(localization.menuBarMenu),
                route: '/menu',
              },
              { label: _.toUpper(localization.faqs), route: '/faq' },
              // { label: 'ABOUT US', route: '/pricing' },
              {
                label: _.toUpper(localization.menuBarCatering),
                route: '/catering',
              },
              // { label: 'BLOG', route: '/blog' },
              // { label: 'CASE STUDY', route: '/casestudy' },
            ].map(({ label, route }) => (
              <NavItem
                className="nav-item"
                href={route}
                key={`${label}::${route}`}
              >
                {label}
              </NavItem>
            ))}
          </Nav>
          <Nav pullRight>
            <span className="desktop-only">
              <ShoppingCartPreview />
            </span>
            {isLoggedIn && (
              <>
                <NavItem className="nav-item">
                  {`${localization.menuBarCredits.toUpperCase()}: ${_.floor(
                    credits,
                  )}`}
                </NavItem>
                <NavItem href="/profile" className="nav-item mobile-only">
                  {localization.menuBarProfile.toUpperCase()}
                </NavItem>
                <NavItem href="/my-orders" className="nav-item mobile-only">
                  {localization.menuBarMyOrders.toUpperCase()}
                </NavItem>
                <NavItem
                  href="/"
                  onSelect={logout}
                  className="nav-item mobile-only"
                >
                  {localization.menuBarLogOut.toUpperCase()}
                </NavItem>
              </>
            )}
            {isLoggedIn ? (
              <DropdownButton
                title={localization.menuBarMyAccount.toUpperCase()}
                id="my-account-dropdown"
                noCaret
                open={menuVisible}
                onToggle={(isOpen, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setMenuVisible((state) => !state);
                }}
              >
                <MenuItem
                  onSelect={() => history.push('/profile')}
                  eventKey="1"
                >
                  {localization.menuBarProfile}
                </MenuItem>
                <MenuItem
                  onSelect={() => history.push('/my-orders')}
                  eventKey="2"
                >
                  {localization.menuBarMyOrders}
                </MenuItem>
                <MenuItem href="/" onSelect={logout} eventKey="3">
                  {localization.menuBarLogOut}
                </MenuItem>
              </DropdownButton>
            ) : (
              <>
                <NavItem
                  key="one"
                  onClick={openLoginDialog}
                  className="nav-item myAccount-btn"
                >
                  {localization.login.toUpperCase()}
                </NavItem>
              </>
            )}
            <NavItem onClick={onLangPress} className="desktop-only">
              {language === 'en' ? '繁' : 'ENG'}
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {children}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  language: selectLanguage(state),
  isLoggedIn: selectIsLoggedIn(state),
  credits: selectCoinsBalance(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setLanguage: (language: string) => {
    dispatch(ActionSetLanguage({ language }));
    // dispatch(OrderAction.getAvailableOrderDates(lang));
  },

  getAccountDetail: () => dispatch(getUser()),

  openLoginDialog: () => dispatch(toggleLoginDialog(true)),

  logout: () => dispatch(clearUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
