/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import localization from '../../../localization';

export type SettingsState = { language: string };

const authSlice = createSlice({
  name: 'settings',
  initialState: {
    language: 'zht',
  } as SettingsState,
  reducers: {
    setLanguage(state, action: PayloadAction<{ language: string }>) {
      state.language = action.payload.language;
      localization.setLanguage(action.payload.language);
    },
  },
});

const { actions, reducer } = authSlice;

export const { setLanguage } = actions;

export default reducer;
