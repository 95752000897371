/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { connect } from 'react-redux';
import './ShoppingCartPreview.scss';
import _ from 'lodash';
import moment from 'moment';
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
} from 'react-bootstrap';
import { CartItem } from '../../models/CartItem';
import { AppDispatch, history, RootState } from '../../store';
import Loader from '../Loader/Loader';
import ReactPixel from '../../ReactPixel';
import { cart, remove, plus, minus } from '../../img';
import { selectLanguage } from '../../features/Settings/duck/selector';
import {
  selectCartItemsByDate,
  selectcartItemCount,
  selectCartItemsDate,
  selectTempCartItemsByDate,
  selectTempCartItemCount,
  selectTempCartItemsDate,
} from '../../features/Orders/duck/selector';
import {
  updateCartItemQuantity as ActionUpdateQuantity,
  getCart as ActionGetCart,
  removeFromCart as ActionRemoveFromCart,
} from '../../features/Orders/duck';
import { selectIsLoggedIn } from '../../features/Auth/duck/selector';
import { DailyMeal } from '../../models/DailyMeal';
import { toggleLoginDialog } from '../../features/Auth/duck';
import localization from '../../localization';

interface Props {
  isLoggedIn: boolean;
  openLoginDialog: () => void;
  cartItems: _.Dictionary<CartItem[]>;
  cartItemCount: number;
  cartItemDates: string[];
  language: string;
  getCart: () => void;

  updateQuantity: (
    meal: DailyMeal,
    quantity: number,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;

  removeFromCart: (
    meal: DailyMeal,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;
}

const ShoppingCartPreview: React.FC<Props> = ({
  isLoggedIn,
  openLoginDialog,
  language,
  cartItems,
  cartItemCount,
  cartItemDates,
  getCart,
  updateQuantity,
  removeFromCart,
}) => {
  // eslint-disable-next-line prettier/prettier
  const [isVisible, setIsVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getCart();
  }, []);

  React.useEffect(() => {
    setLoading(false);
  }, [cartItems]);

  const showModal = () => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 8000);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  const onClickPixelWithPreview = () => {
    setIsVisible(false);
    if (!isLoggedIn) {
      openLoginDialog();
    } else {
      history.push('/checkout');
      ReactPixel.track('InitiateCheckout', {});
    }
  };

  const updateCartItemQuantity = (meal: DailyMeal, quantity: number) => {
    setLoading(true);
    console.log('updateCartItemQuantity');
    updateQuantity(
      meal,
      quantity,
      () => {
        console.log('updateCartItemQuantity success');
        setLoading(false);
      },
      (message) => {
        alert(message);
        setLoading(false);
      },
    );
  };

  const removeItemFromCart = (meal: DailyMeal) => {
    setLoading(true);
    removeFromCart(
      meal,
      () => {
        setLoading(false);
      },
      (message) => {
        alert(message);
        setLoading(false);
      },
    );
  };

  const renderListGroupItem = (dateString: string) => {
    const items = cartItems[dateString];
    const date = moment(dateString, 'MM-DD-YYYY');

    return (
      <ListGroupItem
        key={dateString}
        style={{ fontFamily: 'Josefin Sans', fontSize: 14 }}
      >
        <div className="primary-color">
          {date.locale(language).format('dddd, D MMMM YYYY')}
        </div>
        {items.map((item: CartItem) =>
          item.quantity !== 0 ? (
            <Row
              key={`${item._id}-${item.dailyMeal.dish.name}-${item.dailyMeal.date}-${item.quantity}`}
              className="vertical-spacing"
              style={{ marginRight: '0' }}
            >
              <Col xs={3} sm={2} md={2}>
                <img
                  src={minus}
                  style={{ marginRight: 10, cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (item.quantity > 1) {
                      updateCartItemQuantity(item.dailyMeal, -1);
                    }
                  }}
                />
                {item.quantity}
                <img
                  src={plus}
                  style={{ marginLeft: 10, cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateCartItemQuantity(item.dailyMeal, 1);
                  }}
                />
              </Col>
              <Col xs={5} sm={6} md={6}>
                {language === 'en'
                  ? item.dailyMeal.dish.name
                  : item.dailyMeal.dish.nameZh}
              </Col>
              <Col xs={3} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <span key="real-price-item-preview">
                  {`HK${item.dailyMeal.dish.price * item.quantity}`}
                </span>
              </Col>
              <Col xs={1}>
                <button
                  onClick={() => removeItemFromCart(item.dailyMeal)}
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    marginTop: '-1em',
                  }}
                >
                  <img src={remove} height="24" width="24" />
                </button>
              </Col>
            </Row>
          ) : (
            <Row />
          ),
        )}
        {/* {shoppingCart.error && (
          <div className="error-message">{shoppingCart.error}</div>
        )} */}
      </ListGroupItem>
    );
  };

  const renderShoppingCartIcon = () => {
    return (
      <span className="shopping-cart-container" onMouseEnter={showModal}>
        {cartItemCount > 0 && (
          <div className="cart-items-badge">
            <span className="cart-items-badge-text">{cartItemCount}</span>
          </div>
        )}
        <img className="icon" src={cart} />
        <Modal
          backdropClassName="transparent-backdrop shopping-cart-preview-modal"
          dialogClassName="shopping-cart-preview-modal"
          onHide={hideModal}
          show={isVisible}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'right',
            alignItems: 'right',
            justifyContent: 'right',
            maxHeight: '100%',
            position: 'absolute',
            overflow: 'visible',
            height: '100%',
            width: '100%',
          }}
        >
          <Modal.Body>
            <div style={{ position: 'relative' }}>
              {loading && (
                <div className="dimmed-loader">
                  <Loader size="small" />
                </div>
              )}
              {!_.isEmpty(cartItems) && (
                <ListGroup>
                  {cartItemDates.map((date) => renderListGroupItem(date))}
                </ListGroup>
              )}
              <Button
                onClick={onClickPixelWithPreview}
                className="checkout-btn"
              >
                {localization.cartExpressCheckout}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </span>
    );
  };

  return (
    <div className="shopping-cart-preview">
      {cartItemCount > 0 && (
        <span>
          {renderShoppingCartIcon()}
          <button
            className="mobile-shopping-cart-container"
            onClick={onClickPixelWithPreview}
          >
            <div className="cart-items-badge">
              <span className="cart-items-badge-text">{cartItemCount}</span>
            </div>
            <img className="icon" src={cart} />
          </button>
        </span>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const isLoggedIn = selectIsLoggedIn(state);

  return {
    isLoggedIn,
    language: selectLanguage(state),

    cartItems: isLoggedIn
      ? selectCartItemsByDate(state)
      : selectTempCartItemsByDate(state),

    cartItemDates: isLoggedIn
      ? selectCartItemsDate(state)
      : selectTempCartItemsDate(state),

    cartItemCount: isLoggedIn
      ? selectcartItemCount(state)
      : selectTempCartItemCount(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  openLoginDialog: () => dispatch(toggleLoginDialog(true)),

  getCart: () => dispatch(ActionGetCart()),

  updateQuantity: (
    meal: DailyMeal,
    quantity: number,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => dispatch(ActionUpdateQuantity({ meal, quantity, onSuccess, onError })),

  removeFromCart: (
    meal: DailyMeal,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => dispatch(ActionRemoveFromCart({ meal, onSuccess, onError })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShoppingCartPreview);
