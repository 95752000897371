import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import AuthReducer from '../features/Auth/duck';
import OrdersReducer from '../features/Orders/duck';
import SettingsReducer from '../features/Settings/duck';
import ProfileReducer from '../features/Profile/duck';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    orders: OrdersReducer,
    settings: SettingsReducer,
    profile: ProfileReducer,
  });

export default createRootReducer;
