import _ from 'lodash';

const getErrorMessage = (error: any) => {
  if (error === 'close-msg')
    return 'Spoonful is no longer receiving any new order. Thank you for your support!';
  return (
    _.get(error, 'response.data.error.message.en') ||
    _.get(error, 'response.data.error.message') ||
    _.get(error, 'response.data') ||
    error.message
  );
};

export default {
  getErrorMessage,
};
