/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { setUser } from '../../Auth/duck';
import { User } from '../../../models/User';
import { AppDispatch, history, RootState } from '../../../store';
import * as AccountService from '../../../services/AccountService';
import { ResponseLogin } from '../../../models/ResponseLogin';
import { selectUser } from '../../Auth/duck/selector';
import localization from '../../../localization';
import { selectLanguage } from '../../Settings/duck/selector';

interface FormValues {
  memberName: string;
  email: string;
  phone: string;
}

interface Props {
  onSaveSuccess: (payload: ResponseLogin) => void;
  user: User;
  language: string;
}

const validationSchema = yup.object().shape({
  memberName: yup.string().required('Please provide your name'),
  phone: yup
    .string()
    .max(8, 'Contact number length is incorrect')
    .min(8, 'Contact number length is incorrect')
    .required('Please provide a contact number'),
});

const ReferralPanel: React.FC<Props> = ({ onSaveSuccess, user }) => {
  //

  // referralTitle: 'MY REFERRAL CODE',
  // referralDesc: 'Invite a friend and get rewarded',
  // referralRule1: 'GIVE 30, GET 30',
  // referralRule2: 'SPOONFUL COINS',
  // referralShare: 'Share',
  // referralTips:
  return (
    <>
      {user ? (
        <>
          <div className="title">{localization.referralTitle}</div>
          <div className="desc">{localization.referralDesc}</div>
          <div className="big-desc">
            {localization.referralRule1}
            <br />
            {localization.referralRule2}
          </div>
          <CopyToClipboard
            text={user.referralCode}
            onCopy={() => alert('Referral code copied to your clipboard ')}
          >
            <div className="referral-box">
              <div className="referral-box-code">{user.referralCode}</div>
              <div className="referral-box-share">
                <span>{localization.referralShare}</span>
              </div>
            </div>
          </CopyToClipboard>
          <div className="desc">{localization.referralTips}</div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  language: selectLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSaveSuccess: (payload: any) => {
    dispatch(setUser(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralPanel);
