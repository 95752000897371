export interface Brand {
  id: string;
  name: string;
  imageUrl: string;
}

export class Brand implements Brand {
  constructor(data: any) {
    this.id = data._id;
    this.name = data.name;
    this.imageUrl = data.logoURL;
  }
}
