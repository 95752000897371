/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseLogin } from '../../../models/ResponseLogin';
import { User } from '../../../models/User';
import * as AuthService from '../../../services/auth';

export type AuthState = {
  showLoginDialog: boolean;
  user?: User;
  token?: string;
};

export const getUser = createAsyncThunk('auth/GET_USER', async () => {
  const response = await AuthService.getUser();
  return response.data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    showLoginDialog: false,
  } as AuthState,
  reducers: {
    setUser(state, action: PayloadAction<ResponseLogin>) {
      state.user = action.payload.user;
      if (action.payload.token) {
        state.token = action.payload.token;
      }
      state.showLoginDialog = false;
    },
    clearUser(state) {
      state.user = undefined;
      state.token = undefined;
      localStorage.removeItem('token');
    },
    toggleLoginDialog(state, action: PayloadAction<boolean>) {
      state.showLoginDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user = payload.user;
    });
  },
});

const { actions, reducer } = authSlice;

export const { toggleLoginDialog, setUser, clearUser } = actions;

export default reducer;
