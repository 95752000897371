/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Button, Col, Grid } from 'react-bootstrap';
import { History } from 'history';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { getOrderHistory } from '../../../services/orders';
import { Order } from '../../../models/Order';
import { RootState } from '../../../store';
import { selectLanguage } from '../../Settings/duck/selector';
import { CartItem } from '../../../models/CartItem';
import localization from '../../../localization';
import './OrderHistory.scss';
import { arrowRight } from '../../../img';
import { selectIsLoggedIn } from '../../Auth/duck/selector';

interface Props {
  history: History;
  lang: string;
  isLoggedIn: boolean;
}

const OrderHistory = ({ history, lang, isLoggedIn }: Props) => {
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    getOrderHistory()
      .then((res) => {
        setOrders(res.data.orders.map((order: any) => new Order(order)));
      })
      .catch((error) => {
        console.log('Error getting order history: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!isLoggedIn)
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );

  const onPressHome = () => {
    history.push('/');
  };

  const renderOrderItem = (order: Order) => {
    const orderItemsFormattedDates = _.uniq(
      order.items.map((item: CartItem) =>
        moment(item.dailyMeal.date).format('YYYY-MM-DD'),
      ),
    ).map((orderItemDateString: string) =>
      moment(orderItemDateString).locale(lang).format('D MMM'),
    );

    return (
      <div
        key={order.id}
        className="order-item-container"
        onClick={() => history.push(`/my-orders/${order.id}`)}
      >
        <div>
          <div className="summary-info-container flex">
            <div>{orderItemsFormattedDates.join(', ')}</div>
            <div className="lighter-grey"> | </div>
            <div>{`${order.items.length} items`}</div>
            <div className="lighter-grey"> | </div>
            <div>
              {localization.formatString(localization.hkd, order.totalAmount)}
            </div>
          </div>
        </div>
        <img src={arrowRight} alt="order-detail" />
      </div>
    );
  };
  return (
    <Grid>
      <Col xs={12} sm={8} smOffset={2} lg={6} lgOffset={3}>
        <div id="order-history-container">
          <div className="title">{localization.myOrders.toUpperCase()}</div>
          {orders.length > 0 ? (
            orders.map(renderOrderItem)
          ) : (
            <div className="no-orders-container">
              <div>{localization.noOrders}</div>
              <div className="underlined" />
              <Button style={{ marginTop: '1em' }} onClick={onPressHome}>
                {localization.backToHome.toUpperCase()}
              </Button>
            </div>
          )}
          {/* {
            loading &&
            <div className="flex-center" style={{marginTop: '1em'}}><Loader/></div>
            } */}
        </div>
      </Col>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  lang: selectLanguage(state),
  isLoggedIn: selectIsLoggedIn(state),
});

export default connect(mapStateToProps)(OrderHistory);
