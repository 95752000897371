/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { connect } from 'react-redux';
import localization from '../../localization';
import { bento, corporateArtboard, lunchbox } from '../../img';
import './Catering.scss';
import { RootState } from '../../store';
import { selectLanguage } from '../Settings/duck/selector';

interface Props {
  language: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Catering = ({ language }: Props) => (
  <div className="catering-container" id="scrollToTop">
    <div className="tm-content-box">
      <div className="event-intro-section">
        <div className="event-intro-container">
          <div className="event-intro-box">
            <div className="event-intro-title">{localization.event_title}</div>
            <div className="event-intro-desc">{localization.event_desc1}</div>
            <a href="http://eepurl.com/cOBLwj" target="_blank">
              <div className="nosh-mp-btn contactus-button">
                {localization.start_here}
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="event-details">
        <div className="cs-img-row">
          <div className="cs-img-col">
            <img style={{ height: 'auto', width: '100%' }} src={bento} />
            <h4>{localization.bento_box}</h4>
          </div>
          <div className="cs-img-col">
            <img
              style={{ height: 'auto', width: '100%' }}
              src={corporateArtboard}
            />
            <h4>{localization.sharing_platter_buffer}</h4>
          </div>
          <div className="cs-img-col">
            <img style={{ height: 'auto', width: '100%' }} src={lunchbox} />
            <h4>{localization.lunch_box}</h4>
          </div>
        </div>
      </div>

      <div className="see-menu-section">
        <div className="see-menu-container">
          <div className="see-menu-box">
            <div className="see-menu-title">
              {localization.see_our_catering_menu}
            </div>

            <div className="see-menu-desc">
              {localization.order_weekly_desc}
            </div>
            <div className="see-menu-buttons-wrap">
              {/* <a
                    href="https://spoonfulmeals.com/"
                    target="_blank"
                    style={{flex:1}}
                  >
                    <div className="nosh-mp-btn see-menu-button">
                      {localization.corporate_lunch_box}
                    </div>
                  </a> */}

              {/* <a
                    href="https://www.nosh.hk/CorporateMeals"
                    target="_blank"
                    style={{flex:1}}
                  >
                    <div className="nosh-mp-btn see-menu-button">
                      {localization.junk_menu}
                    </div>
                  </a> */}
              <a
                href="/documents/Spoonful_Corporate_Catering_Menu_May_2020_16_6_20_update_compressed.pdf"
                target="_blank"
              >
                <div className="nosh-mp-btn see-menu-button">
                  {localization.catering_menu}
                </div>
              </a>
              <a
                href="/documents/Canape_Menu_2020_compressed.pdf"
                target="_blank"
              >
                <div className="nosh-mp-btn see-menu-button">
                  {localization.canape_menu}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state: RootState) => ({
  language: selectLanguage(state), // Required for updating UI
});

export default connect(mapStateToProps)(Catering);
