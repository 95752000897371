import { Api, Method } from '../helpers/Api';
import axios, { setToken } from './client';

export const getAccount = (): Promise<any> => {
  const api = new Api({ method: Method.GET, path: 'v1/account' });
  return api.fetch();
};

export const updatePassword = (
  oldPassword: string,
  newPassword: string,
): Promise<any> => {
  const body = {
    password: oldPassword,
    new_password: newPassword,
  };
  const api = new Api({
    method: Method.POST,
    path: 'v1/account/_update_password',
    body,
  });
  return api.fetch();
};

export const updateAddress = (
  address: string,
  district: string,
): Promise<any> => axios.put('v1/user/', { address, district });

export const updateProfile = (data: any): Promise<any> =>
  axios.put('v1/user/', data);
