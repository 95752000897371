import React from 'react';
import * as yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Loader } from '../../../components';
import localization from '../../../localization';
import * as AuthService from '../../../services/auth';
import { setUser } from '../duck';
import { AppDispatch } from '../../../store';
import { ResponseLogin } from '../../../models/ResponseLogin';
import { transformCart, getCart as ActionGetCart } from '../../Orders/duck';

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please provide your email')
    .email('Please provide a valid email'),
  password: yup.string().required('Please provide your password'),
});

interface Props {
  onSignUpPress: () => void;
  onForgotPasswordPress: () => void;
  onLoginSuccess: (payload: ResponseLogin) => void;
  getCart: () => void;
}

const LoginForm: React.FC<Props> = ({
  onForgotPasswordPress,
  onSignUpPress,
  onLoginSuccess,
  getCart,
}) => {
  const onSubmitLoginForm = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    AuthService.login(values)
      .then((response) => {
        onLoginSuccess(response.data);
        getCart();
      })
      .catch((error: Error) => {
        alert(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <div className="title">{localization.login.toUpperCase()}</div>
      <Formik
        // initialValues={{ email: 'test12@test.com', password: '12341234' }}
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitLoginForm}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            {isSubmitting && (
              <div className="dimmed-loader">
                <Loader />
              </div>
            )}
            <Field name="email" type="text" placeholder={localization.email} />
            {errors.email && (
              <div className="error-message">{errors.email}</div>
            )}
            <Field
              name="password"
              type="password"
              placeholder={localization.loginModalPassword}
            />
            {errors.password && (
              <div className="error-message">{errors.password}</div>
            )}
            <Button
              onClick={onForgotPasswordPress}
              className="forgot-pwd-btn"
              bsStyle="link"
            >
              {localization.loginModalForgot}
            </Button>
            <Button
              className="next-btn bottom-spacing"
              type="submit"
              disabled={isSubmitting}
            >
              {localization.login.toUpperCase()}
            </Button>
          </Form>
        )}
      </Formik>
      {/* <FacebookLogin
      appId="713175542467863"
      fields="name,email"
      callback={this.repsonseFacebook}
    /> */}
      <div className="join-us">{localization.wantToJoinUs}</div>
      <Button onClick={onSignUpPress} className="sign-up-btn" bsStyle="link">
        {localization.createAnAccount}
      </Button>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCart: () => dispatch(ActionGetCart()),
  onLoginSuccess: (payload: ResponseLogin) => {
    dispatch(setUser(payload));
    dispatch(transformCart());
  },
});

export default connect(null, mapDispatchToProps)(LoginForm);
