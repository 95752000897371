import moment, { Moment } from 'moment-timezone';
import { CartItem } from './CartItem';

export interface Order {
  id: string;
  totalAmount: number;
  status: string;
  startDate: Moment;
  endDate: Moment;
  creditEarned?: number;
  dishRatingSent?: boolean;
  lastDate?: Moment;
  items: CartItem[];
  saving: number;
  donation: number;
  creditUsed: number;
  discountValue: number;

  subTotal: number;
}

export class Order implements Order {
  constructor(data: any) {
    moment.tz.setDefault('Asia/Hong_Kong');

    this.id = data._id || data.id;
    this.totalAmount = data.total_amount;
    this.status = data.status;
    this.startDate = moment(data.start_date);
    this.endDate = moment(data.end_date);
    this.dishRatingSent = data.dishRatingSent;
    this.lastDate = data.lastDate;
    this.creditEarned = data.credit_earned;
    this.items = data.items.map((item: any) => ({
      ...item,
      dailyMeal: item.dailyMealId,
    }));

    this.saving = data.saving ? data.saving : 0;
    this.creditUsed = data.credit_used ? data.credit_used : 0;
    this.discountValue = data.discount_value ? data.discount_value : 0;
    this.subTotal = data.subtotal_amount;
    // We add the code discount value to the total savings amount
    this.saving += this.discountValue;

    this.donation = data.donation ? data.donation : 0;
  }
}
