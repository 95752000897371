/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import store from '../store';
// import { AuthAction } from '../features/Auth/duck';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum APIRequestStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum APIRequestError {
  UNAUTHORISED = 'Unauthorised',
  NOT_FOUND = 'Not Found',
}

export function actionTypeWithStatus(
  status: APIRequestStatus,
  type: string,
  result?: any,
  error?: any,
) {
  console.log('actionTypeWithStatus', type, result);
  return {
    type,
    payload: { status, result, error },
  };
}

export interface ApiData {
  path: string;
  pathParams?: {
    [key: string]: string;
  };
  method?: Method;
  query?: {
    [key: string]: any;
  };
  body?: {
    [key: string]: any;
  };
  header?: {
    [key: string]: string;
  };
  config?: {
    // axios configs
    [key: string]: string;
  };
}

const initialHeader: Record<string, unknown> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export class Api {
  apiData: ApiData;

  constructor(apiData: ApiData) {
    this.apiData = apiData;
  }

  fetch() {
    const {
      path,
      pathParams,
      method,
      query,
      body,
      header,
      config,
    } = this.apiData;

    let url = `${process.env.REACT_APP_API_BASE_URL}/${
      path.startsWith('/') ? path.substring(1) : path
    }`;

    if (pathParams) {
      for (const key in pathParams) {
        if (key) {
          url = url.replace(`{${key}}`, pathParams[key]);
        }
      }
    }

    // add url query
    if (query) {
      const queryArray: string[] = [];
      for (const key in query) {
        if (key) {
          const val = query[key];

          if (Array.isArray(val)) {
            // make string like id=1&id=2 when key is id and value is [1,2]
            for (const item of val) {
              if (item || item === 0) {
                queryArray.push(
                  `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
                );
              }
            }
          } else if (val || val === 0) {
            queryArray.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
            );
          }
        }
      }
      url += `?${queryArray.join('&')}`;
    }

    let headers;
    if (header) {
      headers = { ...initialHeader, ...header };
    } else {
      headers = { ...initialHeader };
    }

    const token = localStorage.getItem('token');

    if (token) {
      headers['X-Access-Token'] = token;
    }

    return axios({
      method: method || 'GET',
      url,
      data: method !== Method.GET && body ? body : null,
      headers,
      ...config,
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          switch (error.response.status) {
            case 401: {
              // const { isLoggedIn } = store.getState().auth;
              // if (isLoggedIn === false) {
              //   store.dispatch(AuthAction.toggleLoginDialog(true));
              // } else {
              //   store.dispatch(AuthAction.logout());
              //   throw new Error(APIRequestError.UNAUTHORISED);
              // }
              break;
            }
            case 404: {
              throw new Error(APIRequestError.NOT_FOUND);
              break;
            }
            default: {
              throw new Error(error.response.data.message);
              break;
            }
          }
        } else {
          throw new Error(error);
        }
      });
  }

  // Dummy for Catering, TOBE DELETED
  fetchDummy(dummy: boolean) {
    const {
      path,
      pathParams,
      method,
      query,
      body,
      header,
      config,
    } = this.apiData;

    let url = `${process.env.REACT_APP_API_BASE_URL}/${
      path.startsWith('/') ? path.substring(1) : path
    }`;

    if (pathParams) {
      for (const key in pathParams) {
        if (key) {
          url = url.replace(`{${key}}`, pathParams[key]);
        }
      }
    }

    // add url query
    if (query) {
      const queryArray: string[] = [];
      for (const key in query) {
        if (key) {
          const val = query[key];

          if (Array.isArray(val)) {
            // make string like id=1&id=2 when key is id and value is [1,2]
            for (const item of val) {
              if (item || item === 0) {
                queryArray.push(
                  `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
                );
              }
            }
          } else if (val || val === 0) {
            queryArray.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
            );
          }
        }
      }
      url += `?${queryArray.join('&')}`;
    }

    let headers;
    if (header) {
      headers = { ...initialHeader, ...header };
    } else {
      headers = { ...initialHeader };
    }

    const token = localStorage.getItem('token');

    if (token) {
      headers['X-Access-Token'] = token;
    }

    url = '/documents/dummy.json';

    return axios({
      method: method || 'GET',
      url,
      data: method !== Method.GET && body ? body : null,
      headers,
      ...config,
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          switch (error.response.status) {
            case 401: {
              // const { isLoggedIn } = store.getState().auth;
              // if (isLoggedIn === false) {
              //   store.dispatch(AuthAction.toggleLoginDialog(true));
              // } else {
              //   store.dispatch(AuthAction.logout());
              //   throw new Error(APIRequestError.UNAUTHORISED);
              // }
              break;
            }
            case 404: {
              throw new Error(APIRequestError.NOT_FOUND);
              break;
            }
            default: {
              throw new Error(error.response.data.message);
              break;
            }
          }
        } else {
          throw new Error(error);
        }
      });
  }
}
