/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './AuthActionsStyling.scss';
import { Button, Col, Grid } from 'react-bootstrap';
import _ from 'lodash';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Loader } from '../../../components';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Please provide your password')
    .min(8, 'Your new password must be at least 8 characters long'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], "Passwords don't match")
    .min(8, 'Password should be at least 8 characters.')
    .required('Confirm Password is required'),
});

const ResetPasswordContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    const token = _.replace(location.search, '?token=', '');
    if (!token) {
      history.push('/');
    }
  }, []);

  if (submitted) return <Redirect to="/" push />;

  return (
    <Grid id="auth-actions-container">
      <Col xs={12} sm={6} smOffset={3} lg={4} lgOffset={4}>
        <div className="title">RESET PASSWORD</div>
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={validationSchema}
          onSubmit={(
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>,
          ) => {
            // const token = _.replace(props.props.router.location.search, "?token=", "")
            // AuthService.resetPassword(token, values.password)
            //   .then(res => {
            //     props.setSubmitting(false);
            //     props.setStatus("SUBMITTED")
            //     const access = new Access(res.data)
            //     store().store.dispatch(AuthAction.login(access))
            //   })
            //   .catch(error => {
            //     props.setSubmitting(false);
            //     alert("You have already requested to reset password, please check your inbox")
            //     console.log("ERROR RESETING PWD", error)
            //   })
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && (
                <div className="dimmed-loader">
                  <Loader />
                </div>
              )}
              <label htmlFor="password">New password</label>
              <Field id="password" name="password" type="password" />
              <label htmlFor="confirmPassword">Confirm new password</label>
              <Field
                id="confirmPassword"
                name="confirmPassword"
                type="confirmPassword"
              />
              <Button
                className="next-btn bottom-spacing"
                type="submit"
                disabled={isSubmitting}
              >
                RESET PASSWORD
              </Button>
            </Form>
          )}
        </Formik>
      </Col>
    </Grid>
  );
};

export default ResetPasswordContainer;
