/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem } from '../../../models/CartItem';
import { DailyMeal } from '../../../models/DailyMeal';
import * as OrderService from '../../../services/orders';
import { clearUser } from '../../Auth/duck';
import ErrorMessageHelper from '../../../helpers/ErrorMessageHelper';

export type OrdersState = {
  cartItems: CartItem[];
  tempCartItems: { quantity: number; dailyMeal: DailyMeal }[];
  total: number;
};

export const getCart = createAsyncThunk('orders/GET_CART', async () => {
  const {
    data: {
      amount,
      cart: { items },
    },
  } = await OrderService.getCart();

  return {
    total: amount,
    cartItems: items.map(({ dailyMealId, ...otherProps }: any) => ({
      ...otherProps,
      dailyMeal: new DailyMeal(dailyMealId),
    })),
  };
});

export const addToCart = createAsyncThunk<
  any,
  {
    meal: DailyMeal;
    quantity: number;
    onSuccess: () => void;
    onError: (message: string) => void;
  }
>('orders/ADD_TO_CART', async (payload, thunkApi) => {
  const isLoggedIn = !!(thunkApi.getState() as any).auth.token;
  if (isLoggedIn) {
    const response = await OrderService.addToCart(
      payload.meal.id,
      payload.quantity,
    );
    thunkApi.dispatch(getCart());
    payload.onSuccess();
    return response.data;
  }

  thunkApi.dispatch(
    actions.addToTempCart({
      meal: payload.meal,
      quantity: payload.quantity,
    }),
  );
  return null;
});

export const removeFromCart = createAsyncThunk<
  any,
  {
    meal: DailyMeal;
    onSuccess: () => void;
    onError: (message: string) => void;
  }
>('orders/clearItem', async (payload, thunkApi) => {
  const isLoggedIn = !!(thunkApi.getState() as any).auth.token;
  if (isLoggedIn) {
    const response = await OrderService.removeFromCart(payload.meal.id);
    thunkApi.dispatch(getCart());
    payload.onSuccess();
    return response.data;
  }

  payload.onSuccess();
  return null;
});

export const updateCartItemQuantity = createAsyncThunk<
  any,
  {
    meal: DailyMeal;
    quantity: number;
    onSuccess: () => void;
    onError: (message: string) => void;
  }
>('orders/UPDATE_ITEM_QUANTITY', async (payload, thunkApi) => {
  const isLoggedIn = !!(thunkApi.getState() as any).auth.token;

  if (isLoggedIn) {
    const response = await OrderService.updateCartItemQuantity(
      payload.meal.id,
      payload.quantity,
    );
    thunkApi.dispatch(getCart());
    return response.data;
  }
  payload.onSuccess();
  return null;
});

export const transformCart = createAsyncThunk(
  'order/TRANSFORM_CART',
  async (payload, thunkApi) => {
    const {
      orders: { tempCartItems },
    } = thunkApi.getState() as any;
    tempCartItems.forEach(({ quantity, dailyMeal }: any) => {
      thunkApi.dispatch(
        addToCart({
          meal: dailyMeal,
          quantity,
          onSuccess: () => {},
          onError: () => {},
        }),
      );
    });
  },
);
const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    total: 0,
    cartItems: [],
    tempCartItems: [],
  } as OrdersState,
  reducers: {
    addToTempCart(
      state,
      action: PayloadAction<{ meal: DailyMeal; quantity: number }>,
    ) {
      const index = state.tempCartItems.findIndex(
        (item) => item.dailyMeal.id === action.payload.meal.id,
      );
      if (index > -1) {
        state.tempCartItems[index].quantity += action.payload.quantity;
      } else {
        state.tempCartItems = [
          ...state.tempCartItems,
          { dailyMeal: action.payload.meal, quantity: action.payload.quantity },
        ];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCart.fulfilled, (state, { payload }) => {
      state.cartItems = payload.cartItems;
      state.total = payload.total;
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      const {
        meta: { arg },
        error,
      } = action;
      console.log(action);
      arg.onError(
        ErrorMessageHelper.getErrorMessage('close-msg') || error?.message || '',
      );
    });

    builder.addCase(removeFromCart.fulfilled, (state, action) => {
      if (action.payload === null) {
        const {
          meta: { arg },
        } = action;
        state.tempCartItems = state.tempCartItems.filter(
          (item) => item.dailyMeal.id !== arg.meal.id,
        );
      }
    });
    builder.addCase(removeFromCart.rejected, (state, action) => {
      const {
        meta: { arg },
        error,
      } = action;
      arg.onError(
        ErrorMessageHelper.getErrorMessage('close-msg') || error.message || '',
      );
    });
    builder.addCase(updateCartItemQuantity.fulfilled, (state, action) => {
      if (action.payload === null) {
        const {
          meta: { arg },
        } = action;
        const index = state.tempCartItems.findIndex(
          (item) => item.dailyMeal.id === arg.meal.id,
        );
        if (index > -1) {
          const quantity = state.tempCartItems[index].quantity + arg.quantity;
          if (quantity > 0) {
            state.tempCartItems[index].quantity = quantity;
          } else {
            state.tempCartItems = state.tempCartItems.filter(
              (item) => item.dailyMeal.id !== arg.meal.id,
            );
          }
        }
      }
    });
    builder.addCase(updateCartItemQuantity.rejected, (state, action) => {
      const {
        meta: { arg },
        error,
      } = action;
      arg.onError(
        ErrorMessageHelper.getErrorMessage('close-msg') || error.message || '',
      );
    });
    builder.addCase(clearUser, (state) => {
      state.cartItems = [];
      state.tempCartItems = [];
      state.total = 0;
    });
  },
});

const { actions, reducer } = ordersSlice;

// export const {} = actions;

export default reducer;
