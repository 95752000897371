import { createSelector } from 'reselect';
import { RootState } from '../../../store';

const selectProfile = (state: RootState) => state.profile;

export const selectShowAddressDialog = createSelector(
  selectProfile,
  (profile) => profile.showAddressDialog,
);

export const selectShowAddressMode = createSelector(
  selectProfile,
  (profile) => profile.showAddressMode,
);
