/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Geocode from 'react-geocode';
import {
  Button,
  Col,
  Form,
  FormControl,
  Grid,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { History } from 'history';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { toggleLoginDialog as ActiontoggleLoginDialog } from '../Auth/duck';
import { ModalPopup, PartnersLogos } from '../../components';
import {
  appScreen1,
  appScreen2,
  companyGa,
  companyNaam,
  companyNosh,
  companyRustico,
  companySesami,
  companyVeggie,
  downloadAndroid,
  downloadIOS,
  homeBanner2,
  homeIcon1,
  homeIcon2,
  homeIcon3,
  homePricePlan,
  location,
} from '../../img';
import './TermsAndConditions.scss';
import { AppDispatch, RootState } from '../../store';
import { selectIsLoggedIn } from '../Auth/duck/selector';

// Import Swiper styles
import 'swiper/swiper.scss';
import localization from '../../localization';
import { selectLanguage } from '../Settings/duck/selector';

SwiperCore.use([Pagination, Navigation]);

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_API_KEY || '');
Geocode.setRegion('hk');

interface Props {
  history: History;
  isLoggedIn: boolean;
  language: string;
  toggleLoginDialog: () => void;
}

const TermsAndConditions = ({
  history,
  isLoggedIn,
  language,
  toggleLoginDialog,
}: Props) => {
  // Resize Handling
  // const [slidesPerView, setSlidesPerView] = React.useState(1); // Control number of items of sliders

  return (
    <div className="faq-container">
      <div className="faq-banner">Terms and Conditions</div>
      <div className="tnc-content">
        <h2>1. Terms of service</h2>

        <p>
          1.1 Please read the terms and conditions (the “Terms of Service”) set
          forth below as they apply to your access and use of our services,
          including our various websites, APIs, email notifications,
          applications, buttons, and widgets and/or purchasing a Product from us
          (each a “Service” and collectively “Services”), and any information,
          text, graphics, photos, or other materials uploaded, downloaded, or
          appearing on the Services (collectively referred to as “Content”).
          Your access to and use of the Services signifies your acceptance of
          these Terms of Service and agreement to be bound by them and any and
          all other applicable terms referenced herein absolutely.
        </p>
        <p>
          1.2 By accessing and/or using the Services, you represent that you are
          18 years old or above and are legally capable of entering into binding
          contracts.
        </p>
        <p>
          1.3 If you do not accept these Terms of Service, please discontinue
          use of the Services immediately.
        </p>
        <p>
          1.4In these Terms of Service, “Product” means any goods listed on our
          site that you can submit an order for through our site.
        </p>

        <h2>2. Privacy policy</h2>

        <p>
          2.1These Terms of Service refer to the additional terms in our privacy
          policy (the “Privacy Policy”), which also apply to your use of our
          Services.
        </p>
        <p>
          2.2Our Privacy Policy sets out the terms on which we process any
          personal data we collect from you or that you provide to us. By using
          our Services, you consent to such processing and you warrant that all
          data provided by you is accurate. You understand that through your use
          of the Services you consent to the collection and use (as set forth in
          the Privacy Policy) of this information, including the transfer of
          this information both within and outside of Hong Kong for storage,
          processing, and use by us. As part of providing you the Services, we
          may need to provide you with certain communications, such as service
          announcements and administrative messages. These communications are
          considered part of the Services and your Spoonful Account and which
          you may not be able to opt-out from receiving.
        </p>

        <h2>3. Information about us</h2>

        <p>
          The Services are provided and operated by TZCJ Company Limited
          (trading as Spoonful) (we or “Spoonful”). We are a limited liability
          company registered in Hong Kong with registered number
          64729438-000-05-17-9 and have our registered office at Flat/Rm 1914, 6
          Sun Yip Street, Chai Wan, Hong Kong.
        </p>

        <h2>4. Changes of terms</h2>

        <p>
          We may amend the Terms of Service from time to time at our sole
          discretion without notice or liability to you. It is your
          responsibility to check this page periodically for changes. By
          continuing to use the Services following such amendments to the Terms
          of Service, you agree to be bound by such amendments.
        </p>

        <h2>5. Changes to our services</h2>

        <p>
          5.1The Services that we provide are always evolving and the form and
          nature of the Services that we provide may change from time to time
          without prior notice to you. In addition, we may stop (permanently or
          temporarily) providing the Services (or any features within the
          Services) to you or to users generally and not be able to provide you
          with prior notice. We also retain the right to create limits on use
          and storage at our sole discretion at any time and without prior
          notice to you.
        </p>
        <p>
          5.2 We make no representations, warranties, or guarantees, whether
          express or implied, that our Services or any Content on our site is
          accurate, complete, or up to date.
        </p>

        <h2>6. Accessing our services</h2>

        <p>
          6.1We do not guarantee that our Services or any content will always be
          available or be uninterrupted. Access to our Services is permitted on
          a temporary basis. We may suspend, withdraw, discontinue, or change
          all or any part of our Services without notice. We will not be liable
          to you if for any reason our Services are unavailable at any time or
          for any period.
        </p>
        <p>
          6.2You are responsible for making all arrangements necessary for you
          to have access to our Services.
        </p>
        <p>
          6.3You are also responsible for ensuring that all persons who access
          our Services through your internet connection are aware of these Terms
          of Service and other applicable terms and conditions and that they
          comply with them.
        </p>

        <h2>7. Formation of a contract</h2>

        <p>
          7.1The information set out in these Terms of Service and any of the
          Services do not constitute an offer for sale but rather an invitation
          to treat. All orders submitted by you through our site shall be deemed
          to be an offer made by you to purchase the selected Products set out
          in your order upon these Terms of Service (an “Order”). No binding
          agreement for us to sell you any Product is made until we accept an
          Order in accordance with these Terms of Service.
        </p>
        <p>
          7.2 To submit an Order you will be required to follow the online
          shopping process described on the site. After submitting an Order, you
          will receive an order confirmation (the“Order Confirmation”) that will
          act as an acknowledgement of your Order.
        </p>
        <p>
          7.3 Processing of payment of an Order shall not in itself constitute
          acceptance of the Order by SPOONFUL. Where an Order is refused or
          cancelled by SPOONFUL, we will reverse or refund any payment already
          made for such Order in accordance with our prevailing refund policy at
          such time.
        </p>
        <p>
          7.4 An Order is only considered accepted by SPOONFUL upon the Order
          being shipped to the address provided by you.
        </p>

        <h2>8. Product information, price, and payment</h2>

        <p>
          8.1Whilst SPOONFUL makes best efforts to provide you with accurate and
          up-to-date images, details, descriptions, prices, and promotional
          information, there may be instances where errors and differences may
          occur. We cannot guarantee that your electronic display of any Product
          colour or image will be accurate. SPOONFUL shall not be liable for any
          errors or differences in the images, details, descriptions, prices,
          and promotional information listed through our Services.
        </p>
        <p>
          8.2 SPOONFUL is under no obligation to fulfil an Order if the price
          listed on our website is incorrect (even after an Order Confirmation
          has been sent by us). If we discover an error in the price of any
          Products which you have ordered, we will inform you and give you the
          option of reconfirming your Order for such Product at the correct
          price or cancelling it. By confirming your Order for such Product, you
          authorise us to charge the additional amounts to you. If we are unable
          to contact you, we will treat the Order for such Product as cancelled.
          If you cancel your Order for such Product, we will refund payment in
          accordance with our prevailing refund policy at such time.
        </p>
        <p>
          8.3 Where applicable, prices are inclusive of tax and are in Hong Kong
          Dollars.
        </p>
        <p>
          8.4 Where applicable, delivery fees will be charged in addition to the
          price of the Product. Such charges will be displayed and included in
          the final total when you submit your Order.
        </p>
        <p>
          8.5 We accept payment for Orders by the following payment methods:
          Major credit cards, including Visa, Mastercard, Paypal and Amercian
          Express.
        </p>
        <p>
          8.6 By placing your Order, you authorise us to transmit information to
          or obtain information about you from third parties from time to time
          and this may include requesting pre- authorisation on your card to
          ensure there are sufficient funds available to complete the
          transaction, authentication of your identity, validation checks on
          your credit card by the issuer, protection against fraud and to enable
          delivery of your Order. By placing your Order, you are confirming that
          the card belongs to you or that you are the legitimate holder of the
          Promotional Voucher, and have sufficient funds or credit facilities to
          cover the cost of the Order.
        </p>

        <h2>9. Promotions and vouchers</h2>

        <p>
          9.1 SPOONFUL may from time to time run promotions, contests, or other
          privilege programmes (a“Programme”)that are subject to additional
          terms and conditions which are hereby incorporated by reference into
          these Terms of Service and can be found on our site at such applicable
          time.
        </p>
        <p>
          9.2 SPOONFUL may from time to time make available vouchers, codes,
          coupons, credits, or gift vouchers (a“Promotional Voucher”). A
          Promotional Voucher may be subject to additional terms and conditions
          which are hereby incorporated by reference into these Terms of Service
          and can be found either on the Promotional Voucher or on our site at
          such applicable time.
        </p>
        <p>
          9.3 Each Programme and Promotional Voucher is valid for the time
          period specified by SPOONFUL.
        </p>
        <p>
          9.4 Each Promotional Voucher is only eligible for single use and
          cannot be used in conjunction with any other Programme or Promotional
          Voucher unless otherwise stated or explicitly agreed to by SPOONFUL.
        </p>
        <p>9.5 A Promotional Voucher:</p>

        <p>(a)cannot be refunded, redeemed, or exchanged for cash;</p>
        <p>
          (b)is non-replaceable if lost, stolen, destroyed, duplicated, tampered
          with, or otherwise misappropriated;
        </p>
        <p>
          (c)cannot be resold, transferred, or shared, except for a gift voucher
          which may be purchased by you and given to others;
        </p>
        <p>
          (d)cannot be reproduced, adapted, altered, or otherwise tampered with;
        </p>
        <p>
          (e)will be declared null and void by SPOONFUL where, in our sole
          discretion, such Promotional Voucher is suspected to have been used in
          breach of these Terms of Service.
        </p>

        <p>
          9.6SPOONFUL’s decision on all matters relating to each Programme or
          Promotional Voucher is final and binding.
        </p>
        <p>
          9.7 SPOONFUL reserves the right to discontinue any Programme or
          Promotional Voucher programme at any time, in our sole discretion,
          without notice or liability.
        </p>

        <h2>10. Refusal of order</h2>

        <p>
          10.1 SPOONFUL reserves the right to withdraw any Products from sale on
          the site at any time. Whilst SPOONFUL will make best efforts to
          process all Orders, there may be exceptional circumstances where we
          need to refuse to process an Order after an Order Confirmation has
          been sent by us, which we reserve the right to do at our sole
          discretion.
        </p>
        <p>
          10.2 If we refuse or cancel your Order for any reason and you have
          already made payment for your Order, we will refund payment in
          accordance with our prevailing refund policy at such time.
        </p>

        <h2>11. Delivery</h2>

        <p>11.1 Our delivery fees are: Free Delivery</p>
        <p>
          11.2 We will aim to deliver the Product to you at the place of
          delivery specified in your Order at the delivery time indicated by us
          in your Order confirmation according to our delivery route.
        </p>
        <p>
          11.3 We will make best efforts to deliver the Products at the proposed
          delivery time but cannot and do not guarantee that delivery times will
          be met and, to the extent permitted by law, SPOONFUL shall not be
          liable to you or any third party for any losses, liabilities, costs,
          damages, charges, or expenses arising out of late delivery.
        </p>
        <p>
          11.4Your signature may be required to accept delivery. If you are not
          available to sign for the Product upon an attempt of delivery, we may
          leave a card with instructions and terms, hereby incorporated by
          reference, for either re-delivery or collection.
        </p>
        <p>
          11.5There may be locations that we are unable to deliver to, in which
          case we will inform you using the contact details provided by you when
          submitting your Order and arrange for an alternative delivery method
          or cancellation of your Order.
        </p>

        <h2>12. Returns</h2>

        <p>
          We do not accept returns of any Product, except where we are required
          to by law.
        </p>

        <h2>13. Refunds</h2>

        <p>
          Where SPOONFUL determines that a refund is to be made under these
          Terms of Service, payment will be reversed or refunded via the same
          method that payment was originally made. In the event a reversal
          cannot be done, we will contact you via the contact details provided
          when you submit your order to determine an agreed refund method.
        </p>

        <h2>14. Your licence to use the services</h2>

        <p>
          SPOONFUL gives you a personal, worldwide, royalty-free,
          non-assignable, and non- exclusive licence to use the software that is
          provided to you by us as part of the Services. This licence is for the
          sole purpose of enabling you to use and enjoy the benefit of the
          Services as provided by SPOONFUL in the manner permitted by these
          Terms of Service.
        </p>

        <h2>15. Our intellectual property rights</h2>

        <p>
          15.1 All intellectual property rights subsisting in respect of the
          Services belong to SPOONFUL or have been lawfully licensed to SPOONFUL
          for use in connection with the Services. All rights under applicable
          laws are hereby reserved. You agree not to upload, post, publish,
          reproduce, transmit, or distribute in any way any component of the
          website itself, the Content, or any access to any Service, or create
          derivative works with respect thereto, except with the prior written
          consent of SPOONFUL or unless expressly permitted in these Terms of
          Service. The website, Content, and Services are copyrighted under
          applicable laws.
        </p>
        <p>
          15.2 You agree that we are free to use, disclose, adopt, and modify
          all and any ideas, concepts, knowhow, proposals, suggestions,
          comments, and other communications and information provided by you to
          us (the “Feedback”) in connection with the Services and/or your use of
          the Services without any payment to you. You hereby waive and agree to
          waive all and any rights and claims for any consideration, fees,
          royalties, charges, and/or other payments in relation to our use,
          disclosure, adoption, and/or modification of any or all of your
          Feedback.
        </p>

        <h2>16. Limited liability and warranty</h2>

        <p>
          Please read this section carefully since it limits the liability of
          SPOONFUL and its parents, subsidiaries, affiliates, related companies,
          officers, directors, employees, agents, representatives, partners, and
          licensors (collectively, the “SPOONFUL Entities” ). Each of the
          subsections below only applies up to the maximum extent permitted
          under applicable law. Some jurisdictions do not allow the disclaimer
          of implied warranties or the limitation of liability in contracts and,
          as a result, the contents of this section may not apply to you.
          Nothing in this section is intended to limit any rights you may have
          which may not be lawfully limited. All Information is for your general
          reference only. We do not accept any responsibility whatsoever in
          respect of such information.
        </p>
        <p>
          16.1 YOUR ACCESS TO AND USE OF OUR SERVICES IS AT YOUR SOLE RISK AND
          IS PROVIDED &quot;AS IS&quot;, &quot;AS AVAILABLE.&quot; THE SERVICES
          ARE FOR YOUR PERSONAL USE ONLY AND THE SPOONFUL ENTITIES MAKE NO
          REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING,
          WITHOUT LIMITATION, ANY WARRANTIES ON MERCHANTABILITY OR FITNESS FOR
          ANY PARTICULAR PURPOSE OR NON-INFRINGEMENT.
        </p>
        <p>
          16.2WHERE THE BUYER IS DEALING AS ACONSUMER (AS DEFINED IN THE SALE OF
          GOODS ORDINANCE (CAP 26) SECTION 2A AND/OR THE CONTROL OF E X E M P T
          I O N C L AU S E S O R D I N AN C E ( C AP 7 1 ) S E C T I O N 4 ) ,
          AL L O T H E R WARRANTIES, CONDITIONS, OR TERMS RELATING TO FITNESS
          FOR PURPOSE, QUALITY, OR CONDITION OF THE GOODS, WHETHER EXPRESS OR
          IMPLIED BY STATUTE OR COMMON LAW OR OTHERWISE, ARE EXCLUDED TO THE
          FULLEST EXTENT PERMITTED BY LAW.
        </p>
        <p>
          16.3 THE ENTIRE LIABILITY OF THE SPOONFUL ENTITIES AND YOUR EXCLUSIVE
          REMEDY WITH RESPECT TO THE PRODUCTS AND/OR SERVICES OR OTHERWISE, IS
          RE-PERFORMANCE OF DEFECTIVE SERVICES. IN JURISDICTIONS WHICH DO NOT
          ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF LIABILITY, OUR
          LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. WE
          DO NOT ENDORSE, WARRANT, OR GUARANTEE ANY MATERIAL, PRODUCT, OR
          SERVICE OFFERED THROUGH US OR OUR SERVICES. WE ARE NOT AND WILL NOT BE
          A PARTY TO ANY TRANSACTION BETWEEN YOU AND ANY THIRD PARTY.
        </p>
        <p>
          16.4 IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE SPOONFUL
          ENTITIES EXCEED ONE THOUSAND HONG KONG DOLLARS (HKD 1,000).
        </p>
        <p>
          16.5The SPOONFUL Entities shall not guarantee or assume any
          responsibility that:
        </p>

        <p>
          (a) the information presented in our Services is accurate, adequate,
          current, or reliable, or may be used for any purpose other than for
          general reference;
        </p>
        <p>
          (b)the information presented in our Services is free of defect, error,
          omission, virus, or anything which may change, erase, add to, or
          damage your software, data or equipment;
        </p>
        <p>
          (c)messages sent through the internet including in connection with the
          services will be free from interception, corruption, error, delay, or
          loss;
        </p>
        <p>(d)access to the Services will be available or be uninterrupted;</p>
        <p>
          (e)use of the Products and/or Services will achieve any particular
          result; or
        </p>
        <p>(f)defects in the Services will be corrected.</p>

        <p>
          16.6Without limiting the generality of the foregoing, in no event will
          the SPOONFUL Entities be liable to you or any other person for any
          direct, indirect, incidental, special, punitive, or consequential loss
          or damages, including any loss of business or profit, arising out of
          any use, or inability to use, the information or the Products and/or
          Services, even if any of the SPOONFUL Entities has been advised of the
          possibility of such loss or damages.
        </p>
        <p>
          16.7You will exercise and rely solely on your own skill and judgment
          in your use and interpretation of the information and use of the
          Products and/or Services. You are responsible to ensure that your use
          of the information, Products, and/or Services complies with all
          applicable legal requirements.
        </p>
        <p>
          16.8Without prejudice to the foregoing, if your use of the Products
          and/or Services does not proceed satisfactorily and/or where
          applicable you do not receive appropriate responses to such use from
          us, as set out in these Terms of Service or otherwise, you are advised
          to contact us at hello@Spoonful.hk. No such lack of response shall be
          deemed to constitute any acquiescence or waiver.
        </p>
        <p>
          16.9You hereby indemnify SPOONFUL against all liabilities, costs,
          expenses, damages, and losses (including any direct, indirect, or
          consequential losses, loss of profit, loss of reputation, and all
          interest, penalties, and legal and other professional costs and
          expenses) suffered or incurred by SPOONFUL in connection with any
          claim made against SPOONFUL for actual or alleged infringement of a
          third party&apos;s intellectual property rights arising out of or in
          connection with your use of the Products and/or Services. This clause
          shall survive termination of the Terms of Service.
        </p>
        <p>
          16.10The limitation of liability contained in these Terms of Service
          will apply to the fullest extent permitted by applicable laws.
        </p>

        <h2>17. Content copyright policy</h2>

        <p>
          SPOONFUL respects the intellectual property rights of others and
          expects users of the Services to do the same. We will respond to
          notices of alleged copyright infringement that comply with applicable
          law and are properly provided to us. If you believe that your Content
          has been copied in a way that constitutes copyright infringement,
          please provide us with the following information:
        </p>

        <p>
          (i)a physical or electronic signature of the copyright owner or a
          person authorised to act on their behalf;
        </p>
        <p>
          (ii)identification of the copyrighted work claimed to have been
          infringed;
        </p>
        <p>
          (iii)identification of the material that is claimed to be infringing
          or to be the subject of infringing activity and that is to be removed
          or access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material;
        </p>
        <p>
          (iv)your contact information, including your address, your telephone
          number, and an email address;
        </p>
        <p>
          (v)a statement by you that you have a good faith belief that use of
          the material in the manner complained of is not authorised by the
          copyright owner, its agent, or the law; and
        </p>
        <p>
          (vi)a statement that the information in the notification is accurate
          and that you are authorised to act on behalf of the copyright owner.
        </p>

        <h2>18. Use of the services</h2>

        <p>
          18.1We reserve the right at all times (but will not have an
          obligation) to remove or refuse to distribute any Content on the
          Services, to refuse any Order, to merge, suspend or terminate
          Accounts, and to reclaim usernames without liability to you. We also
          reserve the right to access, read, preserve, and disclose any
          information as we reasonably believe is necessary to
        </p>

        <p>
          (i)satisfy any applicable law, regulation, legal process, or
          governmental request,
        </p>
        <p>
          (ii)enforce the Terms of Service, including investigation of potential
          violations hereof,
        </p>
        <p>
          (iii)detect, prevent, or otherwise address fraud, security, or
          technical issues,
        </p>
        <p>
          (iv)respond to user support requests, or (v) protect the rights,
          property, or safety of SPOONFUL, its users, and the public.
        </p>

        <p>
          18.2 We do not guarantee that our Services will be secure or free from
          bugs or viruses. You are responsible for configuring your information
          technology, computer programmes, and platform in order to access our
          site. You should use your own virus protection software.
        </p>
        <p>
          18.3 You may not do any of the following while accessing or using the
          Services:
        </p>

        <p>
          (i)access, tamper with, or use non-public areas of the Services,
          SPOONFUL’s computer systems, or the technical delivery systems of
          SPOONFUL’s providers;
        </p>
        <p>
          (ii)probe, scan, or test the vulnerability of any system or network or
          breach or circumvent any security or authentication measures;
        </p>
        <p>
          (iii)access or search or attempt to access or search the Services by
          any means (automated or otherwise) other than through our currently
          available, published interfaces that are provided by us (and only
          pursuant to those terms and conditions), (scraping the Services
          without our prior consent is expressly prohibited);
        </p>
        <p>
          (iv)forge any TCP/IP packet header or any part of the header
          information in any email or posting, or in any way use the Services to
          send altered, deceptive, or false source- identifying information; or
        </p>
        <p>
          (v)interfere with, or disrupt, (or attempt to do so), the access of
          any user, host, or network, including, without limitation, sending a
          virus, trojan, worm, logic bomb, or other material which is malicious
          or technologically harmful, overloading, flooding, spamming,
          mail-bombing the Services, or scripting the creation of Content in
          such a manner as to interfere with or create an undue burden on the
          Services.
        </p>

        <p>
          18.4 We will report any such breach to the relevant law enforcement
          authorities and we will co- operate with those authorities by
          disclosing your identity to them. In the event of such a breach, your
          right to use our Services will cease immediately.
        </p>

        <h2>19. Linking to us</h2>

        <p>
          You may link to our Services provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it.
          You must not establish a link in such a way as to suggest any form of
          association, approval, or endorsement on our part where none exists.
          You must not establish a link to our Services in any website that is
          not owned by you. We reserve the right to withdraw linking permission
          without notice.
        </p>

        <h2>20. Third-party links and resources in our site</h2>

        <p>
          20.1The links from the Services may take you to other sites or
          services and you acknowledge and agree that SPOONFUL has no
          responsibility for the accuracy or availability of any Information
          provided by third parties’ services and websites.
        </p>
        <p>
          20.2 The Services may include advertisements, which may be targeted to
          the Content or information on the Services, queries made through the
          Services, or other information. The types and extent of advertising by
          SPOONFUL on the Services are subject to change. In consideration for
          us granting you access to and use of the Services, you agree that
          SPOONFUL and its third-party providers and partners may place such
          advertising on the Services or in connection with the display of
          Content or information from the Services whether submitted by you or
          others.
        </p>
        <p>
          20.3Links to other websites and services do not constitute an
          endorsement by us of such websites or services, or the Information,
          products, advertising, or other materials available made available by
          such third parties.
        </p>

        <h2>21. Indemnity</h2>

        <p>
          You agree to defend, indemnify, and hold us harmless from and against
          all liabilities, damages, claims, actions, costs, and expenses
          (including without limitation legal fees), in connection with or
          arising from your breach of any of these Terms of Service and/or your
          use of the website. We may, if necessary, participate in the defence
          of any claim or action and any negotiations for settlement. No
          settlement which may adversely affect our rights or obligations shall
          be made without our prior written approval. We reserve the right, at
          our own expense and on notice to you, to assume exclusive defence and
          control of any claim or action.
        </p>

        <h2>22. Severance</h2>

        <p>
          The illegality, invalidity, or unenforceability of any provision of
          these Terms of Service under the law of any jurisdiction shall not
          affect its legality, validity, or enforceability under the laws of any
          other jurisdiction nor the legality, validity, or enforceability of
          any other provision.
        </p>

        <h2>23. Several users</h2>

        <p>
          If there are two or more persons adhering to these Terms of Service as
          user, their liability under the Terms of Service is joint and several
          and their rights are joint.
        </p>

        <h2>24. Waiver</h2>

        <p>
          No failure or delay by a party to exercise any right or remedy
          provided under this agreement or by law shall constitute a waiver of
          that or any other right or remedy, nor shall it prevent or restrict
          the further exercise of that or any other right or remedy. No single
          or partial exercise of such right or remedy shall prevent or restrict
          the further exercise of that or any other right or remedy.
        </p>

        <h2>25. Termination</h2>

        <p>
          25.1The Terms of Service will continue to apply until terminated by
          either you or us as follows.
        </p>
        <p>
          25.2You may end your agreement with us at any time for any reason by
          discontinuing your use of the Services. You do not need to
          specifically inform us when you stop using the Services.
        </p>
        <p>
          25.3We may cease providing you with all or part of the Services at any
          time for any reason, including, but not limited to, if we reasonably
          believe: (i) you have violated these Terms of Service or (ii) you
          create risk or possible legal exposure for us; or (iii) our provision
          of the Services to you is no longer commercially viable. We will make
          reasonable efforts to notify you by the email address associated with
          your account or the next time you attempt to access your account.
        </p>
        <p>
          25.4In all such cases, any provision of these Terms of Service that
          expressly or by implication is intended to come into or continue in
          force on or after termination of this agreement shall remain in full
          force and effect.
        </p>
        <p>
          25.5Nothing in this section shall affect our rights to change, limit,
          or stop the provision of the Services without prior notice, as
          provided above in Clause 5 Changes to our services.
        </p>

        <h2>26. Force majeure</h2>

        <p>
          26.1No party shall be in breach of these Terms of Service nor liable
          for delay in performing, or failure to perform, any of its obligations
          under these Terms of Service if such delay or failure result from
          events, circumstances, or causes beyond its reasonable control. In
          such circumstances, the affected party shall be entitled to a
          reasonable extension of the time for performing such obligations.
        </p>
        <p>
          26.2The affected party shall use all reasonable endeavours to mitigate
          the effect of a force majeure event on the performance of its
          obligations.
        </p>
        <p>
          26.3A force majeure event shall include such events as an act of God,
          fire, flood, typhoon, storm, war, riot, civil unrest, act of
          terrorism, strikes, industrial disputes, outbreak of epidemic or
          pandemic illness, failure of utility service or transportation,
          request from a government, or interference from civil or military
          authorities.
        </p>

        <h2>27. Governing law and jurisdiction</h2>

        <p>
          These Terms of Service shall be governed by the laws of the Hong Kong
          Special Administrative Region. You agree to submit to the exclusive
          jurisdiction of the Hong Kong courts.
        </p>

        <h2>28. Languages</h2>

        <p>
          In case of discrepancies between the English version and any other
          language versions of these Terms of Service and contents of this site,
          the English version shall prevail.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
  language: selectLanguage(state), // Required for updating UI
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  toggleLoginDialog: () => dispatch(ActiontoggleLoginDialog(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
