import axios from './client';

export const getCart = () => axios.get('v1/cart');

export const addToCart = (
  dailyMealId: string,
  quantity: number,
): Promise<any> => axios.post('v1/cart/add_item', { dailyMealId, quantity });

export const updateCartItemQuantity = (dailyMealId: string, quantity: number) =>
  axios.post('v1/cart/add_item', { dailyMealId, quantity });

export const removeFromCart = (dailyMealId: string) =>
  axios.post('v1/cart/_clear_item', { dailyMealId });

export const clearCart = () => axios.post('v1/cart/_clear');

export const findZone = (lat: number, lng: number) =>
  axios.post('v1/logistic_zone/_find', { lat, lng });

export const addPaymentMethod = (token: string) =>
  axios.post('v1/user/_add_card', { token });

export const checkDiscountCode = (
  code: number | string,
  amount: number | string,
) => axios.get(`v1/discount_code?code=${code}&amount=${amount}`);

export const placeOrder = (
  paymentMethodId: string,
  logisticZoneId: string,
  coordinates: { lat: number; lng: number },
  address: string,
  district: string,
  remarks: string,
  credits: number,
  discount_code: string,
  referral_code: string,
) => {
  return axios.post('v1/orders/_place', {
    payment_method_id: paymentMethodId,
    logistic_zone_id: logisticZoneId,
    coordinates,
    address,
    district: '',
    credits,
    discount_code,
    delivery_remarks: remarks,
    referral_code,
    donation: 0,
    building: district,
  });
};

export const getOrderHistory = (): Promise<any> => axios.get('v1/orders');

export const getOrder = (orderId: string): Promise<any> =>
  axios.get(`v1/orders/${orderId}`);

export const getRatingItems = (orderId: string): Promise<any> =>
  axios.get(`v1/orders/forOrderRating/${orderId}`);

export const submitRatings = (orderId: string, data: any): Promise<any> =>
  axios.post('v1/dish_ratings/many', {
    order_id: orderId,
    data,
  });
