/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Button, Modal } from 'react-bootstrap';
import './AddCoinsModal.scss';
import { connect } from 'react-redux';
import { creditCardWhite } from '../../../img';
import { addCoins } from '../../../services/auth';
import { addPaymentMethod } from '../../../services/orders';
import { AppDispatch, RootState } from '../../../store';
import { getUser as ActionGetUser } from '../../Auth/duck';
import localization from '../../../localization';
import { selectLanguage } from '../../Settings/duck/selector';
import generalTracker from '../../../helpers/trackers';

interface FormValues {
  points: string;
  creditCard: string;
}

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      // iconColor: '#F6CD16',
      color: '#545454',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#545454',
      },
      '::placeholder': {
        color: '#545454',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

interface Props {
  getUser: () => void;
  language: string;
}

const AddCoinsModal: React.FC<Props> = ({ getUser }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [open, setOpen] = React.useState(false);

  const onSubmitLoginForm = async (
    values: FormValues,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<FormValues>,
  ) => {
    if (!stripe || !elements) return;
    const card = elements.getElement(CardElement);

    if (card) {
      const { error, token } = await stripe.createToken(card);

      if (!token || error) {
        setErrors({
          creditCard:
            error?.message || 'There is an error, please try again later.',
        });
        setSubmitting(false);
        return;
      }

      const {
        data: { payment_method },
      } = await addPaymentMethod(token?.id);

      try {
        const extra = Math.floor(parseInt(values.points, 10) / 500) * 35; // Extra $35 for every $500
        const res = await addCoins(
          values.points,
          `${extra}`,
          payment_method._id,
        );
        if (res.status === 200) {
          generalTracker('AddPaymentInfo');
          getUser();
          setSubmitting(false);
          setOpen(false);
          resetForm();
        }
      } catch (e) {
        alert(
          `There is an error, please try again later. ${e.response.data.error.message}`,
        );
        setSubmitting(false);
      }
    }
  };

  // After Dec 2021, the business of Spoonful is paused, so we disabled the add coins functions too
  return null;

  return (
    <>
      <Button className="btn-add-coins" onClick={() => setOpen(true)}>
        {localization.addCoins}
      </Button>
      <Modal
        className="modal-add-coins"
        onHide={() => setOpen(false)}
        show={open}
      >
        <Modal.Header>
          <img
            src={creditCardWhite}
            alt="credit-card"
            className="credit-card-icon"
          />
          <div>Add Coins - Credit Card Infomation</div>
        </Modal.Header>
        <Button className="close-btn" onClick={() => setOpen(false)} />
        <Modal.Body style={{ padding: 40 }}>
          <Formik
            initialValues={{ points: '250', creditCard: '' }}
            onSubmit={onSubmitLoginForm}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => (
              <Form>
                <fieldset className="radio-groups">
                  <div className="radio-group">
                    <input
                      id="points-250"
                      type="radio"
                      name="points"
                      checked={values.points === '250'}
                      onChange={() => {
                        setFieldValue('points', '250');
                      }}
                    />
                    <label htmlFor="points-250">250 Coins/$250</label>
                  </div>
                  <div className="radio-group">
                    <input
                      id="points-500"
                      type="radio"
                      name="points"
                      checked={values.points === '500'}
                      onChange={() => {
                        setFieldValue('points', '500');
                      }}
                    />
                    <label htmlFor="points-500">535 Coins/$500</label>
                  </div>
                  <div className="radio-group">
                    <input
                      id="points-1000"
                      type="radio"
                      name="points"
                      checked={values.points === '1000'}
                      onChange={() => {
                        setFieldValue('points', '1000');
                      }}
                    />
                    <label htmlFor="points-1000">1070 Coins/$1000</label>
                  </div>
                </fieldset>
                {/* <RadioGroup
                  aria-label="points"
                  name="points"
                  value={values.points}
                  onChange={(event) => {
                    setFieldValue('points', event.target.value);
                  }}
                >
                  <FormControlLabel
                    checked={values.points === '250'}
                    value="250"
                    control={<Radio />}
                    label="250 Coins/$250"
                  />
                  <FormControlLabel
                    checked={values.points === '500'}
                    value="500"
                    control={<Radio />}
                    label="535 Coins/$500"
                  />
                  <FormControlLabel
                    checked={values.points === '1000'}
                    value="1000"
                    control={<Radio />}
                    label="1070 Coins/$1000"
                  />
                </RadioGroup> */}
                <div className="description">
                  {'*For every 500 HKD of spoonful coins get an '}
                  <span className="orange">EXTRA 35 HKD</span>
                  {' of free credit. Make sure to top up!'}
                </div>
                <div className="label">Credit Card Number</div>
                <div style={{ border: '0.5px solid #e7e7e7', padding: 25 }}>
                  <CardElement options={CARD_OPTIONS} />
                  {errors.creditCard && (
                    <div className="error-message">{errors.creditCard}</div>
                  )}
                </div>
                <Button
                  type="submit"
                  className="btn-add-coins"
                  disabled={isSubmitting}
                >
                  ADD COINS
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  language: selectLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(ActionGetUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCoinsModal);
