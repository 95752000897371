/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Grid } from 'react-bootstrap';
import { ModalPopup } from '../../../components';
import { toggleLoginDialog as ActiontoggleLoginDialog } from '../duck';
import { AppDispatch, history, RootState } from '../../../store';
import './LoginModal.scss';
import LoginForm from '../components/LoginForm';
import SignUpForm from '../components/SignUpForm';
import { selectShowLoginDialog } from '../duck/selector';

declare global {
  interface Window {
    FB: any;
  }
}

interface Props {
  showLoginDialog: boolean;

  toggleLoginDialog: (open: boolean) => void;
}

const LoginModalPopup: React.FC<Props> = ({
  showLoginDialog,
  toggleLoginDialog,
}) => {
  const [isSignUp, setIsSignUp] = React.useState(false);

  const onForgotPasswordPress = () => {
    toggleLoginDialog(false);
    history.push('/account/request_reset_password');
  };

  return (
    <ModalPopup
      isVisible={showLoginDialog}
      onHide={() => toggleLoginDialog(false)}
      onExited={() => setIsSignUp(false)}
      className="login-modal-container"
    >
      <Grid id="login-container">
        <Col xs={12} className="no-padding text-center">
          {isSignUp ? (
            <SignUpForm onLoginPress={() => setIsSignUp(false)} />
          ) : (
            <LoginForm
              onSignUpPress={() => setIsSignUp(true)}
              onForgotPasswordPress={onForgotPasswordPress}
            />
          )}
        </Col>
      </Grid>
    </ModalPopup>
  );
};

const mapStateToProps = (state: RootState) => ({
  showLoginDialog: selectShowLoginDialog(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  toggleLoginDialog: (open: boolean) => dispatch(ActiontoggleLoginDialog(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModalPopup);
