import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './index.css';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration: any) => {
    registration.unregister();
  });
  console.log('unreg service worker');
}

const persistor = persistStore(store) as any;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Elements>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
