/* eslint-disable @typescript-eslint/indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { Grid, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { History } from 'history';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Helmet } from 'react-helmet';
import { AppDispatch, RootState } from '../../../store';
import ReactPixel from '../../../ReactPixel';
import localization from '../../../localization';
import { DailyMeal } from '../../../models/DailyMeal';
import useAvailableDates from '../../../hooks/useAvailableDates';
import { dateArrow, forwardWhiteArrow } from '../../../img';
import * as MealsService from '../../../services/meals';
import { MealCard } from '../components';
import { toggleLoginDialog } from '../../Auth/duck';
import { selectIsLoggedIn } from '../../Auth/duck/selector';
import { selectLanguage } from '../../Settings/duck/selector';
import {
  selectcartItemCount,
  selectCartTotal,
  selectTempCartItemCount,
  selectTempCartTotal,
} from '../../Orders/duck/selector';
import MenuFilter from '../../../components/MenuFilter/MenuFilter';
import './MenuContainer.scss';
import { HomeBanner } from '../../../components';
import generalTracker from '../../../helpers/trackers';

// Import Swiper styles
import 'swiper/swiper.scss';

SwiperCore.use([Pagination, Navigation]);

interface Props {
  language: string;
  isLoggedIn: boolean;
  cartTotal: number;
  cartItemCount: number;
  openLoginDialog: () => void;
  history: History;
}
interface DishType {
  label: string;
  value: string;
  name_eng: string;
  name_zht: string;
  selected: boolean;
}

const OrderContainer: React.FC<Props> = ({
  language,
  isLoggedIn,
  cartTotal,
  cartItemCount,
  openLoginDialog,
  history,
}) => {
  const {
    orderDates,
    weekOf,
    nextWeek,
    prevWeek,
    canBack,
    canNext,
    firstAviableDate,
  } = useAvailableDates();
  const [showAppPromo, setShowAppPromo] = React.useState(false);
  const [dateSelected, setDateSelected] = React.useState<Moment>();
  const [filters, setFilters] = React.useState<
    { label: string; value: string; selected: boolean }[]
  >();
  const [types, setTypes] = React.useState<DishType[]>();
  const [meals, setMeals] = React.useState<any>();
  const mealRefs = React.useRef<Array<HTMLDivElement | null>>([]);
  // const mainsRef = React.useRef<HTMLDivElement | null>();
  // const sidesRef = React.useRef<HTMLDivElement | null>();
  // const kidsRef = React.useRef<HTMLDivElement | null>();
  // const proteinRef = React.useRef<HTMLDivElement | null>();

  React.useEffect(() => {
    ReactPixel.trackCustom('CorporateLunchOrderPage', {});
    MealsService.getFilters().then((data) => {
      setFilters(data.map((item) => ({ ...item, selected: false })));
    });
    MealsService.getTypes().then((data) => {
      setTypes(data.map((item: any) => ({ ...item, selected: false })));
    });
    generalTracker('ViewContent');
    setTimeout(() => {
      setShowAppPromo(true);
    }, 1000);
  }, []);

  React.useEffect(() => {
    if (!orderDates || !firstAviableDate) return;
    if (
      !dateSelected ||
      !orderDates.find((date) => date.isSame(dateSelected, 'date'))
    ) {
      setDateSelected(
        orderDates.find((date) => date.isAfter(firstAviableDate)),
      );
    }
  }, [orderDates, dateSelected, firstAviableDate]);

  React.useEffect(() => {
    if (dateSelected && types)
      MealsService.getDailyMeals(dateSelected.format('DD-MM-YYYY'), filters)
        .then(({ data: { dailyMeals } }) => {
          const newMeals: any = {};
          types.forEach((type) => {
            const typeKey = type.value;
            const mealsOfThisType = dailyMeals
              .filter((item: any) => item.dishId.type === typeKey)
              .map((item: any) => new DailyMeal(item));
            if (mealsOfThisType.length) {
              newMeals[type.value] = mealsOfThisType;
            }
          });
          setMeals({ ...newMeals });
          // setMeals({
          //   main: dailyMeals
          //     .filter((item: any) => item.dishId.type === 'main')
          //     .map((item: any) => new DailyMeal(item)),
          //   sides: dailyMeals
          //     .filter((item: any) => item.dishId.type === 'side')
          //     .map((item: any) => new DailyMeal(item)),
          //   kids: dailyMeals
          //     .filter((item: any) => item.dishId.type === 'kids')
          //     .map((item: any) => new DailyMeal(item)),
          //   protein: dailyMeals
          //     .filter((item: any) => item.dishId.type === 'protein')
          //     .map((item: any) => new DailyMeal(item)),
          // });
        })
        .catch((e) => {
          setMeals(undefined);
        });
  }, [dateSelected, filters, types]);

  // React.useEffect(() => {
  //   console.log('types', types);
  //   console.log('meals', meals);
  // }, [meals]);

  // Resize Handling
  const [slidesPerView, setSlidesPerView] = React.useState(1); // Control number of items of sliders
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (windowWidth > 1024) {
        setSlidesPerView(6);
      } else if (windowWidth > 640) {
        setSlidesPerView(5);
      } else if (windowWidth > 480) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(1);
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderDatesSelector = () => {
    return (
      <Col className="dates-selector-container">
        {!_.isEmpty(orderDates) && <div className="week-title">{weekOf}</div>}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ButtonGroup>
            <Button className="prev" onClick={prevWeek} disabled={!canBack}>
              {/* <ArrowLeftIcon
                fontSize="large"
                htmlColor={canBack ? '#000' : '#fff'}
              /> */}
              {'<'}
            </Button>
            {orderDates.map((date) => {
              const disabled =
                firstAviableDate && firstAviableDate.isAfter(date);
              const isSelected = dateSelected && date.isSame(dateSelected);
              let className = 'arrow-btn';
              if (disabled) className += ' disabled-btn';
              if (isSelected) className += ' active';

              return (
                <Button
                  className={className}
                  key={`id-${date}`}
                  disabled={disabled}
                  onClick={() => setDateSelected(date)}
                >
                  <img src={dateArrow} />
                  <div>{date.locale(language).format('ddd').toUpperCase()}</div>
                  <div className="day-date">
                    {date.format('D').toUpperCase()}
                  </div>
                </Button>
              );
            })}
            <Button className="next" onClick={nextWeek} disabled={!canNext}>
              {/* <ArrowRightIcon fontSize="large" /> */}
              {'>'}
            </Button>
          </ButtonGroup>
        </div>
        <div className="separator" />
      </Col>
    );
  };

  const onPressNextDay = () => {
    if (orderDates && dateSelected) {
      const index = orderDates.findIndex((date) =>
        date.isSame(dateSelected, 'date'),
      );
      if (index > -1) {
        if (index === orderDates.length - 1) {
          nextWeek();
        } else {
          setDateSelected(orderDates[index + 1]);
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  const canNextDay = () => {
    let flag = true;
    if (orderDates && dateSelected) {
      const index = orderDates.findIndex((date) =>
        date.isSame(dateSelected, 'date'),
      );
      if (index > -1) {
        if (index === orderDates.length - 1 && !canNext) {
          flag = false;
        }
      }
    }
    return flag;
  };

  const onPressCheckout = () => {
    if (!isLoggedIn) openLoginDialog();
    else history.push('/checkout');
  };

  const onSelectFilter = (filter: any) => {
    if (filters) {
      setFilters(
        filters.map((item) =>
          item.value === filter.value
            ? { ...item, selected: !item.selected }
            : item,
        ),
      );
    }
  };

  const scrollToRef = (
    // ref: React.MutableRefObject<HTMLDivElement | null | undefined>,
    ref: any,
  ) => {
    // console.log('scrollToRef', ref);
    if (ref) {
      window.scrollTo({
        top: ref.getBoundingClientRect().top - 90,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div style={{ width: '100%' }} id="order-container">
      <HomeBanner />
      <Grid className="dates-selector-grid">
        <Row>{renderDatesSelector()}</Row>
      </Grid>
      <div id="order-content-container" className="container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 12,
          }}
        >
          {dateSelected && (
            <span className="selected-date-text">
              {dateSelected
                .locale(localization.getLanguage())
                .format(
                  localization.getLanguage() === 'zht'
                    ? 'dddd, M 月 D 號'
                    : 'dddd, D MMMM',
                )}
            </span>
          )}
          <MenuFilter onSelect={onSelectFilter} data={filters} />
        </div>
        {/* <Row>
          <Grid className="category-container">
            <Row>
              {types &&
                types?.map((type, key) => (
                  <React.Fragment key={type?.value}>
                    {meals ? (
                      <Col xs={6} md={2}>
                        <Button
                          className="btn-light btn-category"
                          onClick={() => scrollToRef(mealRefs?.current[key])}
                          disabled={!(meals && meals[type?.value])}
                        >
                          {type?.label}
                        </Button>
                      </Col>
                    ) : null}
                  </React.Fragment>
                ))}
            </Row>
          </Grid>
        </Row> */}

        <div className="category-filter-slider">
          <div className="category-filter-slider-body">
            <Swiper
              spaceBetween={10}
              centerInsufficientSlides
              navigation={{
                hideOnClick: false,
                nextEl: '.next-el',
                prevEl: '.prev-el',
              }}
              slidesPerView={slidesPerView}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {types &&
                types?.map((type, key) => {
                  console.log('type code', localization.mealType, type?.label);
                  return (
                    <React.Fragment key={type?.value}>
                      <h1 className="a11y-hidden">Food Delivery Hong Kong</h1>
                      <Helmet>
                        <title>
                          Ready to eat Meal Delivery | Spoonful Meals
                        </title>
                        <meta
                          name="description"
                          content="Spoonful Meals offers a wide selection of ready to eat
                          meals in Hong Kong. Download our app and order food
                          online the easy way!"
                        />
                      </Helmet>
                      {meals && meals && meals[type?.value] ? (
                        <SwiperSlide>
                          <Button
                            className="btn-light btn-category"
                            onClick={() => {
                              scrollToRef(mealRefs?.current[key]);
                            }}
                            disabled={!(meals && meals[type?.value])}
                          >
                            {/* {_.get(localization.mealType, `${type?.label}`) ||
                              type?.label} */}
                            {language.toLowerCase() === 'en'
                              ? type?.name_eng
                              : type?.name_zht}
                          </Button>
                        </SwiperSlide>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </Swiper>
          </div>
          <div className="category-filter-slider-control">
            <div className="prev-el">
              <div className="swiper-custom-arrow-left" />
            </div>
            <div className="next-el">
              <div className="swiper-custom-arrow-right" />
            </div>
          </div>
        </div>
        {types &&
          types?.map((type, key) => (
            <React.Fragment key={`meal-${type?.value}`}>
              {meals && meals[type?.value] ? (
                <>
                  <div
                    className="section-title"
                    ref={(ref) => {
                      mealRefs.current[key] = ref;
                    }}
                  >
                    {language.toLowerCase() === 'en'
                      ? type?.name_eng
                      : type?.name_zht}
                  </div>
                  <Row className="meals-container">
                    {meals &&
                      meals[type?.value]?.map((meal: any) => (
                        <Col key={meal.id} xs={12} sm={6} md={4}>
                          <MealCard meal={meal} />
                        </Col>
                      ))}
                  </Row>
                </>
              ) : null}
            </React.Fragment>
          ))}

        <div className="separator" />
        <div id="desktop-action-buttons" className="desktop-only flex-between">
          <div className="total-text">
            {localization.menuTotal}
            <span>
              {localization.formatString(localization.hkd, cartTotal)}
            </span>
          </div>
          <div>
            <Button
              onClick={onPressNextDay}
              className="border-btn"
              disabled={!canNextDay()}
            >
              <span>{localization.nextDay.toUpperCase()}</span>
              <img src={forwardWhiteArrow} width="18" />
            </Button>
            <Button
              onClick={onPressCheckout}
              className="next-btn"
              disabled={cartItemCount === 0}
            >
              <span>
                {localization.menuCheckOut.toUpperCase().replace(' ', '')}
              </span>
              <img src={forwardWhiteArrow} width="18" />
            </Button>
          </div>
        </div>
        <div className="justify-content-space-around mobile-bottom-fixed-btn">
          <div className="total-text">
            {localization.menuTotal}
            <span style={{ color: '#fff' }}>{` HK$ ${cartTotal}`}</span>
          </div>
          <Button
            onClick={onPressNextDay}
            className="border-btn"
            disabled={!canNextDay()}
          >
            <span>{localization.nextDay.toUpperCase()}</span>
            <img src={forwardWhiteArrow} width="18" />
          </Button>
          <Button
            className="next-btn"
            onClick={onPressCheckout}
            disabled={cartItemCount === 0}
          >
            <span>{localization.menuCheckOut}</span>
            <img src={forwardWhiteArrow} width="18" />
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const isLoggedIn = selectIsLoggedIn(state);
  return {
    isLoggedIn,
    cartTotal: isLoggedIn ? selectCartTotal(state) : selectTempCartTotal(state),
    cartItemCount: isLoggedIn
      ? selectcartItemCount(state)
      : selectTempCartItemCount(state),
    language: selectLanguage(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  openLoginDialog: () => dispatch(toggleLoginDialog(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);
