/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Grid } from 'react-bootstrap';
import { ModalPopup } from '../../../components';
import { toggleAddressDialog as ActionToggleAddressDialog } from '../duck';
import { AppDispatch, history, RootState } from '../../../store';
import './AddressModalContainer.scss';
import MyAddressForm from '../components/MyAddressForm';
import {
  selectShowAddressDialog,
  selectShowAddressMode,
} from '../duck/selector';
import localization from '../../../localization';

declare global {
  interface Window {
    FB: any;
  }
}

interface Props {
  showAddressDialog?: boolean;
  showAddressMode: string;

  toggleAddressDialog?: (open: boolean) => void;
}

const AddressModalContainer: React.FC<Props> = ({
  showAddressDialog = true,
  showAddressMode,
  toggleAddressDialog = () => {
    console.log('toggleAddressDialog');
  },
}) => {
  const [isSignUp, setIsSignUp] = React.useState(false);

  const onForgotPasswordPress = () => {
    toggleAddressDialog(false);
    history.push('/account/request_reset_password');
  };

  return (
    <ModalPopup
      isVisible={showAddressDialog}
      onHide={() => toggleAddressDialog(false)}
      onExited={() => setIsSignUp(false)}
      className="login-modal-container"
    >
      <Grid id="login-container">
        <Col xs={12} className="no-padding text-center">
          <MyAddressForm
            isPopup
            title={localization.feedMe}
            mode={showAddressMode}
          />
        </Col>
      </Grid>
    </ModalPopup>
  );
};

const mapStateToProps = (state: RootState) => ({
  showAddressDialog: selectShowAddressDialog(state),
  showAddressMode: selectShowAddressMode(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  toggleAddressDialog: (open: boolean) =>
    dispatch(
      ActionToggleAddressDialog({
        open,
        mode: 'signup',
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressModalContainer);
