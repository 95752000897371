/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Glyphicon, Label, Panel } from 'react-bootstrap';
import { AppDispatch, RootState } from '../../../../store';
import { toggleLoginDialog } from '../../../Auth/duck';

import { selectIsLoggedIn } from '../../../Auth/duck/selector';
import { selectLanguage } from '../../../Settings/duck/selector';
import MealDetailModal from '../MealDetailsModal/MealDetailModal';
import { addToCart as ActionAddToCart } from '../../../Orders/duck';
import ReactPixel from '../../../../ReactPixel';
import localization from '../../../../localization';
import './MealCard.scss';
import { DailyMeal } from '../../../../models/DailyMeal';
import generalTracker from '../../../../helpers/trackers';

interface Props {
  meal: DailyMeal;
  addToCart: (
    dish: DailyMeal,
    quantity: number,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;
  language: string;
}

const MealCard: React.FC<Props> = ({ meal, language, addToCart }) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const getLocalizedMealAttribute = (enAttr: string, zhAttr: string) => {
    if (language === 'zht')
      return (meal.dish as any)[zhAttr] || (meal.dish as any)[enAttr];

    return (meal.dish as any)[enAttr];
  };

  const onPressAdd = (event: React.MouseEvent<Button, MouseEvent>) => {
    event.stopPropagation();
    if (meal.stock === 0) return;
    // ReactPixel.track('AddToCart', {});
    generalTracker('AddToCart');
    addToCart(
      meal,
      1,
      () => {},
      (message) => {
        alert(message);
      },
    );
  };

  return (
    <>
      <MealDetailModal
        meal={meal}
        isVisible={showDetail}
        onHide={() => setShowDetail(false)}
      />
      <Panel className="meal-card" onClick={() => setShowDetail(true)}>
        <div className="meal-image-holder">
          <img className="meal-image" src={meal.dish.imageUrl} />
          {meal.stock === 0 && (
            <Label
              style={{
                position: 'absolute',
                right: 10,
                top: 10,
                fontSize: 14,
                backgroundColor: '#f7b703e3',
              }}
            >
              <Glyphicon
                glyph="ban-circle"
                style={{
                  marginRight: 5,
                  fontSize: 18,
                  verticalAlign: 'text-bottom',
                }}
              />
              SOLD OUT
            </Label>
          )}
          {meal.dish.brand && (
            <img className="cuisine-image" src={meal.dish.brand.imageUrl} />
          )}
        </div>
        <Panel.Body className="text">
          <div className="primary-text">
            {getLocalizedMealAttribute('name', 'nameZh')}
          </div>
          <div className="flex-center-between" style={{ marginBottom: '2em' }}>
            <div className="nutrition-container">
              <div>
                <div className="secondary-text">
                  {localization.calories.toLowerCase()}
                </div>
                <div className="secondary-text">{meal.dish.calories}</div>
              </div>
              <div>
                <div className="secondary-text">
                  {localization.protein.toLowerCase()}
                </div>
                <div className="secondary-text">{meal.dish.protein}</div>
              </div>
              <div>
                <div className="secondary-text">
                  {localization.carbs.toLowerCase()}
                </div>
                <div className="secondary-text">{meal.dish.carbs}</div>
              </div>
              <div>
                <div className="secondary-text">
                  {localization.fats.toLowerCase()}
                </div>
                <div className="secondary-text">{meal.dish.fat}</div>
              </div>
            </div>
          </div>
        </Panel.Body>
        <div>
          <div className="bottom-content-container">
            <div className="price-text">{`$${meal.dish.price}`}</div>
            <Button
              className="add-btn"
              onClick={onPressAdd}
              disabled={meal.stock === 0}
            >
              +
            </Button>
          </div>
        </div>
      </Panel>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
  language: selectLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addToCart: (
    meal: DailyMeal,
    quantity: number,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => dispatch(ActionAddToCart({ meal, quantity, onSuccess, onError })),

  showLoginDialog: () => dispatch(toggleLoginDialog(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealCard);
