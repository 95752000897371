/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import _ from 'lodash';
import { AppDispatch, RootState } from '../../store';
import RatingCard from './components/RatingCard/RatingCard';
import localization from '../../localization';
import { selectLanguage } from '../Settings/duck/selector';
import { getRatingItems, submitRatings } from '../../services/orders';
import './Rating.scss';

interface Props {
  history: History;
  // isLoggedIn: boolean;
  language: string;
  // toggleLoginDialog: () => void;
}

const Rating = ({ history, language }: Props) => {
  const [status, setStatus] = useState('');
  const [userId, setUserId] = useState('');
  const [order, setOrder] = useState<any>(null);
  const [orderId, setOrderId] = useState('');
  const [ratingItems, setRatingItems] = useState<any>(null);
  const [ratings, setRatings] = useState<any>({});

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    setUserId(query.get('userId') || '');
    setOrderId(query.get('orderId') || '');
  }, []);

  useEffect(() => {
    if (!orderId) return;
    getRatingItems(orderId).then((res) => {
      // console.log('getRatingItems', res);
      const thisRatingItems = res?.data?.order?.items;
      if (thisRatingItems) {
        setOrder(res?.data?.order);
        setRatingItems(thisRatingItems);
      }
    });
  }, [orderId]);

  // useEffect(() => {
  //   if (!ratingItems) return;
  //   setRatings()
  // }, [ratingItems]);

  const updateRatings = (target: string, ratingData: any) => {
    const targetRating = { ...ratings };
    _.set(targetRating, target, ratingData);
    setRatings(targetRating);
  };

  const submitRating = () => {
    // console.log('submitRating', ratings);
    const submitData = _.values(ratings);
    console.log('submitData', submitData);
    // window.alert(localization.ratingSubmittedMsg);
    submitRatings(orderId, submitData)
      .then((res) => {
        setStatus('success');
      })
      .catch((error) => {
        alert(`There is an error submitting the result: ${error}`);
      })
      .finally(() => {
        // setSubmitting(false);
      });
  };

  return (
    <div className="rating">
      <div className="rating-body">
        {status === 'success' || (order && order?.dishRatingSent) ? (
          <>
            <h2 className="rating-msg">
              We have recieved your feedback.
              <br />
              Thank you!
            </h2>
          </>
        ) : (
          <>
            <div className="rating-cards">
              {
                // Array(5)
                //   .fill({
                //     image_url:
                //       'https://nosh-corporate.s3.ap-southeast-1.amazonaws.com/prod/dish/dish_BGXk1Qkfvq1MhIe8BnkGiwvyHW.jpeg',
                //     ingredients:
                //       'Potato ( Potatoes, Rosemary, Thyme, Butter, Olive Oil, Salt, Pepper), Zucchini, Eggplant, Mix Peppers, Beef, Tomato Sauce (Tomato, Tomato Puree, Celery, Carrot, Onion, Herbs, Olive Oil, Salt, Pepper',
                //     name_eng: 'Aussie Steak with Ratatouille, Roasted Potatoes ',
                //     name_zh: '香草牛肉蔬菜雜燴配焗馬鈴薯',
                //     _id: '5fbe37782eb416219ba4dcf0',
                //   })
                ratingItems &&
                  ratingItems.map((item: any, index: number) => {
                    const dishInfo = item?.dailyMealId?.dishId;
                    if (!dishInfo) return null;
                    return (
                      <React.Fragment
                        key={`rating-card-${index + 1}-${dishInfo._id}`}
                      >
                        <RatingCard
                          dishInfo={dishInfo}
                          language={language}
                          updateRatings={updateRatings}
                        />
                      </React.Fragment>
                    );
                  })
              }
            </div>
            <div className="rating-control">
              <button
                className="rating-submit"
                type="button"
                onClick={() => submitRating()}
              >
                {localization.ratingSubmit}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  // isLoggedIn: selectIsLoggedIn(state),
  language: selectLanguage(state), // Required for updating UI
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  // toggleLoginDialog: () => dispatch(ActiontoggleLoginDialog(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
