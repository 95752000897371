import _ from 'lodash';
import * as React from 'react';
import { Checkbox, Dropdown, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { selectLanguage } from '../../features/Settings/duck/selector';
import localization from '../../localization';
import { RootState } from '../../store';
import './MenuFilter.scss';

export interface Filter {
  value: string;
  label: string;
  selected: boolean;
}
interface Props {
  data?: Filter[];
  language: string;

  onSelect: (filter: Filter) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuFilter: React.FC<Props> = ({ data, language, onSelect }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dropdown
      id="menu-filter-dropdown"
      pullRight
      open={open}
      onToggle={(isOpen, e, { source }) => {
        e.preventDefault();
        e.stopPropagation();
        if (source !== 'select') setOpen(isOpen);
      }}
    >
      <Dropdown.Toggle>{localization.mealFilter.filter}</Dropdown.Toggle>
      <Dropdown.Menu>
        {data?.map((item) => (
          <MenuItem
            eventKey={item.value}
            key={item.value}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onSelect(item);
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Checkbox inline checked={item.selected} />
              {_.get(
                localization.mealFilter,
                item.label.toLowerCase().trim(),
              ) || item.label}
            </div>
          </MenuItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  language: selectLanguage(state), // Required for updating UI
});

export default connect(mapStateToProps)(MenuFilter);
