/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { setUser } from '../../Auth/duck';
import { User } from '../../../models/User';
import { AppDispatch, history, RootState } from '../../../store';
import * as AccountService from '../../../services/AccountService';
import { ResponseLogin } from '../../../models/ResponseLogin';
import { selectUser } from '../../Auth/duck/selector';
import localization from '../../../localization';
import { selectLanguage } from '../../Settings/duck/selector';

interface FormValues {
  memberName: string;
  email: string;
  phone: string;
}

interface Props {
  onSaveSuccess: (payload: ResponseLogin) => void;
  user: User;
  language: string;
}

const validationSchema = yup.object().shape({
  memberName: yup.string().required('Please provide your name'),
  phone: yup
    .string()
    .max(8, 'Contact number length is incorrect')
    .min(8, 'Contact number length is incorrect')
    .required('Please provide a contact number'),
});

const PersonalDetailsForm: React.FC<Props> = ({ onSaveSuccess, user }) => {
  const onSubmitProfile = (
    values: FormValues,
    { setSubmitting, setStatus, resetForm }: FormikHelpers<FormValues>,
  ) => {
    console.log('on onSubmitProfile');
    AccountService.updateProfile(values)
      .then((response) => {
        resetForm();
        setStatus('success');
        onSaveSuccess({
          ...response.data,
          token: localStorage.getItem('token'),
        });
        window.alert(localization.profileSaved);
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <>
      <div className="title">{localization.personalDetails.toUpperCase()}</div>
      {user ? (
        <Formik
          onSubmit={onSubmitProfile}
          initialValues={{
            memberName: user.memberName,
            email: user.email,
            phone: user.phone,
          }}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting, errors, status }) => (
            <Form>
              <label>{localization.name}</label>
              <Field name="memberName" type="text" />
              {errors.memberName && (
                <div className="error-message">{errors.memberName}</div>
              )}
              <label>{localization.email}</label>
              <Field name="email" type="text" disabled />
              <label>{localization.contactNo}</label>
              <Field name="phone" type="text" />
              {errors.phone && (
                <div className="error-message">{errors.phone}</div>
              )}
              <Button
                className="next-btn bottom-spacing"
                type="submit"
                disabled={isSubmitting || !values.memberName || !values.phone}
              >
                {localization.save.toUpperCase()}
              </Button>
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  language: selectLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSaveSuccess: (payload: any) => {
    dispatch(setUser(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalDetailsForm);
