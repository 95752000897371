/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';
import localization from '../localization';

import * as MealsService from '../services/meals';

moment.tz.setDefault('Asia/Hong_Kong');
const ORDER_START_TIME = moment('02:30p', 'HH:mm a');

const useAvailableDates = () => {
  const [currentWeek, setCurrentWeek] = useState(0);
  const [orderDates, setOrderDates] = useState<Moment[]>([]);
  const [cutoffDate, setCutoffDate] = useState<Moment>();
  const [firstAviableDate, setFirstAviableDate] = useState<Moment>();
  const [cutoffText, setCutoffText] = useState('');
  const [weekOf, setWeekOf] = useState('');
  const [canBack, setCanBack] = useState(false);
  const [canNext, setCanNext] = useState(false);

  useEffect(() => {
    // Get cutoff info from server
    MealsService.getCutoff().then((data) => {
      // console.log('cutoff', data);
      if (data.cutoff) {
        setCutoffDate(moment(data.cutoff));
      }
      if (data.firstAvailable) {
        setFirstAviableDate(moment(data.firstAvailable));
      }
    });
  }, []);

  useEffect(() => {
    if (cutoffDate) console.log('cutoffDate', cutoffDate);
  }, [cutoffDate]);

  useEffect(() => {
    if (!firstAviableDate) return;

    const cutOffTimeText = `${
      localization.orderingFor1
    } ${firstAviableDate.format('dddd')} ${localization.orderingFor2}?`;

    // switch (moment().day()) {
    //   case 0:
    //   case 6:
    //     cutOffTimeText = `Ordering for ${localization.monday}?`;
    //     break;
    //   case 1:
    //   case 2:
    //   case 3:
    //   case 4: {
    //     cutOffTimeText = isBeforeCutoff
    //       ? 'Ordering for today?'
    //       : localization.orderingForTomorrow;
    //     break;
    //   }
    //   case 5: {
    //     cutOffTimeText = isBeforeCutoff
    //       ? 'Ordering for today?'
    //       : `Ordering for ${localization.monday}?`;
    //     break;
    //   }
    //   default:
    //     break;
    // }

    setCutoffText(cutOffTimeText);
  }, [firstAviableDate, localization]);

  const nextWeek = () => {
    setCurrentWeek((state) => state + 1);
  };

  const prevWeek = () => {
    setCurrentWeek((state) => (state > 0 ? state - 1 : state));
  };

  useEffect(() => {
    // Before 9:00 am
    const isBeforeCutoff = moment().isBefore(ORDER_START_TIME);

    const startDate = moment().set({ hour: 10, minute: 30, second: 0 });
    if ((moment().day() === 4 && !isBeforeCutoff) || moment().day() > 4) {
      // After Thursday 9:00 am, should show next week
      startDate.day(8);
    }
    startDate.day(currentWeek * 7 + 1);

    const dates = [];
    for (let i = 0; i < 5; i++) {
      dates.push(startDate.clone().add(i, 'day'));
    }

    setOrderDates(dates);

    const weekOfMonth =
      dates[0].week() === 1
        ? 1
        : dates[0].week() - dates[0].clone().startOf('month').week() + 1;

    if (localization.getLanguage() === 'en') {
      setWeekOf(
        `Week ${_.first(dates)!
          .locale(localization.getLanguage())
          .format('D MMM')} - ${_.last(dates)!
          .locale(localization.getLanguage())
          .format('D MMM')}`,
      );
    } else if (localization.getLanguage() === 'zht') {
      setWeekOf(
        `${_.first(dates)!.locale('zht').format('M 月 D')} - ${_.last(dates)!
          .locale('zht')
          .format(
            _.first(dates)?.isSame(_.last(dates), 'month')
              ? 'D 號'
              : 'M 月 D 號',
          )}`,
      );
    }
  }, [currentWeek, localization.getLanguage()]);

  useEffect(() => {
    setCanBack(currentWeek !== 0);
    setCanNext(currentWeek <= 1);
  });

  return {
    cutoffDate,
    cutoffText,
    weekOf,

    orderDates,
    firstAviableDate,

    nextWeek,
    prevWeek,
    canBack,
    canNext,
  };
};

export default useAvailableDates;
