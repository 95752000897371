import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import 'swiper/css/swiper.css';
import './PartnersLogos.scss';
import localization from '../../localization';

const PartnersLogos = () => {
  return (
    <section className="partners-logos">
      <div className="partners-logos-inner">
        <h2 className="spoonful-app-feature">
          {localization.ourBrandPartners}
        </h2>
        {/* <p className="features-sub-text">{localization.ourBrandPartnersDesc}</p> */}
        <div className="partners-logos-items">
          <a
            className="partners-logos-item mod-nosh"
            href="http://www.nosh.hk/?utm_source=Spoonful&utm_medium=referral&utm_campaign=Spoonful_home_landingpage"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="partners-logos-item-img"
              style={{
                backgroundImage: 'url(/img/partners-logos/logo-nosh.png)',
              }}
            />
          </a>
          <a
            className="partners-logos-item mod-south"
            href="https://www.southstreammarket.com/?utm_source=Spoonful&utm_medium=Referral&utm_campaign=Spoonful_home_landingpage"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="partners-logos-item-img"
              style={{
                backgroundImage:
                  'url(/img/partners-logos/logo-south-stream.png)',
              }}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default PartnersLogos;
