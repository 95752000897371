/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { Button, Col, Grid } from 'react-bootstrap';
import * as yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Redirect } from 'react-router-dom';
import { Loader } from '../../../components';
import './AuthActionsStyling.scss';

interface FormValues {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required('Please provide your email'),
});

const ResendEmailVerifyContainer = () => {
  const [submitted, setSubmitted] = React.useState(false);

  if (submitted) return <Redirect to="/" push />;

  return (
    <Grid id="auth-actions-container">
      <Col xs={12} sm={6} smOffset={3} lg={4} lgOffset={4}>
        <div className="title">RESEND EMAIL VERIFICATION</div>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={(
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>,
          ) => {
            // AuthService.resendEmailVerify(values.email)
            //   .then(res => {
            //     alert("An email has been sent to verify your account. Please checkout your email box")
            //     props.setSubmitting(false);
            //     props.setStatus("SUBMITTED")
            //   })
            //   .catch(error => {
            //     props.setSubmitting(false);
            //     alert(error)
            //     console.log("ERROR RESENDING EMAIL", error)
            //   })
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              {isSubmitting && (
                <div className="dimmed-loader">
                  <Loader />
                </div>
              )}
              <label htmlFor="email">Email</label>
              <Field id="email" name="email" type="email" />
              {errors.email && (
                <div style={{ color: 'red', marginTop: '.5rem' }}>
                  {errors.email}
                </div>
              )}
              <Button
                className="next-btn bottom-spacing"
                type="submit"
                disabled={isSubmitting}
              >
                SEND
              </Button>
            </Form>
          )}
        </Formik>
      </Col>
    </Grid>
  );
};

export default ResendEmailVerifyContainer;
