import moment, { Moment } from 'moment-timezone';
import { Meal } from './Meal';

export interface DailyMeal {
  id: string;
  date: Moment;
  discountRatio: number;
  dish: Meal;
  stock: number;
}

export class DailyMeal implements DailyMeal {
  constructor(data: any) {
    moment.tz.setDefault('Asia/Hong_Kong');
    this.id = data._id;
    this.date = moment(data.date);
    this.discountRatio = data.discount_ratio;
    this.dish = new Meal(data.dishId);
    this.stock = data.stock;
  }
}
