import * as React from 'react';
import Countdown from 'react-countdown-now';
import { connect } from 'react-redux';
import localization from '../../localization';
import { RootState } from '../../store';
import useAvailableDates from '../../hooks/useAvailableDates';
import './CutOffTimeBanner.scss';
import { selectLanguage } from '../../features/Settings/duck/selector';

interface Props {
  language: string;
}
const CutOffTimeBanner: React.FC<Props> = ({ language }) => {
  const { cutoffDate, firstAviableDate } = useAvailableDates();

  const weekdays: any = {
    en: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      // 'Saturday',
      'Monday',
    ],
    zht: [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      // '星期六',
      '星期一',
    ],
  };

  const cutOffDate = firstAviableDate
    ? weekdays[language][firstAviableDate.day()]
    : '';

  return (
    <div>
      <div className="cutOffTime-container">
        <span className="cutOffTime-text">
          {`${localization.orderingFor1} ${cutOffDate} ${localization.orderingFor2}?`}
        </span>
        <span className="cutOffTime-text time">
          {`${localization.youHave} `}
          {cutoffDate && <Countdown date={cutoffDate.toDate()} daysInHours />}
          {` ${localization.left}`}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  language: selectLanguage(state),
});

export default connect(mapStateToProps)(CutOffTimeBanner);
