/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Geocode from 'react-geocode';
import {
  Button,
  Col,
  Form,
  FormControl,
  Grid,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { toggleLoginDialog as ActiontoggleLoginDialog } from '../Auth/duck';
import { ModalPopup, PartnersLogos } from '../../components';
import {
  appScreen1,
  appScreen2,
  companyGa,
  companyNaam,
  companyNosh,
  companyRustico,
  companySesami,
  companyVeggie,
  downloadAndroid,
  downloadIOS,
  homeBanner2,
  homeIcon1,
  homeIcon2,
  homeIcon3,
  homePricePlan,
  location,
} from '../../img';
import './Home.scss';
import { findZone } from '../../services/orders';
import { AppDispatch, RootState } from '../../store';
import { selectIsLoggedIn } from '../Auth/duck/selector';

// Import Swiper styles
import 'swiper/swiper.scss';
import localization from '../../localization';
import { selectLanguage } from '../Settings/duck/selector';

SwiperCore.use([Pagination, Navigation]);

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_API_KEY || '');
Geocode.setRegion('hk');

interface Props {
  history: History;
  isLoggedIn: boolean;
  language: string;
  toggleLoginDialog: () => void;
}

const Home = ({ history, isLoggedIn, language, toggleLoginDialog }: Props) => {
  const [address, setAddress] = React.useState('');
  const [error, setError] = React.useState('');

  const validateAddress = (event: React.FormEvent<Button>) => {
    event.preventDefault();
    if (address) {
      Geocode.fromAddress(`${address}`)
        .then((response: any) => response.results[0].geometry.location)
        .then(({ lat, lng }) => findZone(lat, lng))
        .then((res) => {
          history.push('/menu');
        })
        .catch((e) => {
          setError(e.message);
        });
    }
  };

  // Resize Handling
  const [slidesPerView, setSlidesPerView] = React.useState(1); // Control number of items of sliders
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (windowWidth > 1024) {
        setSlidesPerView(5);
      } else if (windowWidth > 640) {
        setSlidesPerView(4);
      } else if (windowWidth > 480) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(2);
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Redirect signed up user to menu
  React.useEffect(() => {
    // const token = localStorage.getItem('token');
    if (isLoggedIn) {
      history.push('/menu');
    }
  }, [isLoggedIn]);
  // Popup
  const [showPopup, setShowPopup] = React.useState(false);

  return (
    <div id="company-signup-container">
      <ModalPopup
        isVisible={showPopup}
        onHide={() => setShowPopup(false)}
        onExited={() => {}}
        className="login-modal-container"
      >
        <Grid id="login-container">
          <Col xs={12} className="no-padding text-center">
            <div className="title">
              {'Spoonful Corporate has moved, please click '}
              <a href="http://corporate.spoonfulmeals.com/" target="_blank">
                here.
              </a>
            </div>
          </Col>
        </Grid>
      </ModalPopup>
      <div className="banner-container">
        <img src={homeBanner2} />
        <div className="location-container">
          <div className="title">{localization.homeBannerMain}</div>
          {/* <div className="text">
            {'Tell us where you at,\nlet us search food in your surroundings!'}
          </div> */}
          <Form onSubmit={validateAddress}>
            <div className="home-hero-input">
              <InputGroup>
                <InputGroup.Addon>
                  <img src={location} />
                </InputGroup.Addon>
                <FormControl
                  className="location-input"
                  placeholder={localization.homeBannerInput}
                  onChange={(event: any) => setAddress(event.target.value)}
                />
              </InputGroup>
              <Button type="submit">{localization.homeBannerSubmit}</Button>
            </div>
          </Form>

          <div className="text">{localization.homeBannerSub}</div>
        </div>
      </div>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Row>
          <div className="spoonful-app-feature">
            {localization.homeBodyHowItWorks}
          </div>
        </Row>
        <Row>
          <Col xs={2} />
          <Col xs={8}>
            <div className="features-sub-text">
              {localization.homeBodyHowItWorksText}
            </div>
          </Col>
          <Col xs={2} />
        </Row>
        <Row>
          <div className="spoonful-app-feature">
            {localization.homeBodySpoonfulFeatures}
          </div>
        </Row>
        <Row>
          <Col xs={12} sm={4}>
            <img src={homeIcon1} className="app-feature-icon" />
            <div className="app-feature-description">
              {localization.homeBodySpoonfulFeaturesText1}
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <img src={homeIcon2} className="app-feature-icon" />
            <div className="app-feature-description">
              {localization.homeBodySpoonfulFeaturesText2}
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <img src={homeIcon3} className="app-feature-icon" />
            <div className="app-feature-description">
              {localization.homeBodySpoonfulFeaturesText3}
            </div>
          </Col>
        </Row>
        <div className="app-feature-btn-row">
          <Link to="/menu" className="app-feature-btn">
            {localization.homeSeeOurMenu}
          </Link>
        </div>
        <PartnersLogos />
        {/* <Row>
          <div className="spoonful-app-feature">
            {localization.homeBodyOurBrands}
          </div>
        </Row>
        <Row>
          <Col xs={2} />
          <Col xs={8}>
            <div className="features-sub-text">
              {localization.homeBodyOurBrandsText}
            </div>
          </Col>
          <Col xs={2} />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <div
              className="features-container-2 mod-desktop"
              style={{ paddingBottom: 0 }}
            >
              <img className="virtual_logo_2" src={companyNosh} />
              <img className="virtual_logo_2" src={companyGa} />
              <img className="virtual_logo_2" src={companyVeggie} />
              <img className="virtual_logo_2" src={companySesami} />
              <img className="virtual_logo_2" src={companyRustico} />
              <img className="virtual_logo_2" src={companyNaam} />
            </div>
            <div
              className="features-container-2 mod-mobile"
              style={{ paddingBottom: 0 }}
            >
              <div className="features-container-2-swiper">
                <Swiper
                  spaceBetween={0}
                  navigation={{
                    hideOnClick: false,
                    nextEl: '.next-el',
                    prevEl: '.prev-el',
                  }}
                  slidesPerView={slidesPerView}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <img className="virtual_logo_2" src={companyNosh} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="virtual_logo_2" src={companyGa} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="virtual_logo_2" src={companyVeggie} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="virtual_logo_2" src={companySesami} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="virtual_logo_2" src={companyRustico} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="virtual_logo_2" src={companyNaam} />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="features-container-2-swiper-control">
                <div className="prev-el">
                  <div className="swiper-custom-arrow-left" />
                </div>
                <div className="next-el">
                  <div className="swiper-custom-arrow-right" />
                </div>
              </div>
            </div>
          </Col>
        </Row> */}
        <Row>
          <div className="spoonful-app-feature">
            {localization.homeBodyOurPricingPlan}
          </div>
        </Row>
        <Row>
          <Col xs={0} sm={1} />
          <Col xs={12} lgHidden mdHidden smHidden>
            <img src={homePricePlan} style={{ width: '100%' }} />
          </Col>
          <Col xs={12} sm={10}>
            <div className="price-plan-container">
              <img src={homePricePlan} />
              {language === 'zht' && (
                <div className="margin-top">
                  {localization.homeBodyOurPricingPlanText1}
                </div>
              )}
              <div className="bold">
                {localization.formatString(localization.hkd, '100')}
              </div>
              <div className="margin-bottom">
                {localization.homeBodyOurPricingPlanText2}
              </div>
              {language === 'zht' && (
                <div className="margin-top">
                  {localization.homeBodyOurPricingPlanText3}
                </div>
              )}
              <div className="bold">
                {localization.formatString(localization.hkd, '200')}
              </div>
              <div className="margin-bottom">
                {localization.homeBodyOurPricingPlanText4}
              </div>
              <div>
                {localization.formatString(
                  localization.homeBodyOurPricingPlanText5,
                  `${localization.formatString(localization.hkd, 500)}`,
                )}
                <span className="orange">
                  {localization.formatString(localization.hkd, 35)}
                </span>
                {localization.homeBodyOurPricingPlanText6}
              </div>
            </div>
          </Col>
          <Col xs={0} sm={1} />
        </Row>
        <Row>
          <div className="section-app">
            <div className="section-app-wrapper">
              <div className="section-app-download">
                <div className="download-app">
                  {localization.homeBodyDownloadToday}
                </div>
                <div className="download-buttons">
                  <a
                    href="https://apps.apple.com/hk/app/spoonful-meals/id1447225332?l=en"
                    target="_blank"
                  >
                    <img src={downloadIOS} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.nosh.corporate&hl=en_US&gl=US"
                    target="_blank"
                  >
                    <img src={downloadAndroid} />
                  </a>
                </div>
              </div>
              <div className="section-app-img">
                <img src={appScreen1} className="app-screen" />
              </div>
            </div>
          </div>

          {/* <Col xs={0} sm={1} />
          <Col xs={12} sm={6} style={{ textAlign: 'start' }}>
            <div className="download-app">Download our APP today</div>
            <div className="download-buttons">
              <a
                href="https://play.google.com/store/apps/details?id=com.nosh.corporate"
                target="_blank"
              >
                <img src={downloadAndroid} />
              </a>
              <a
                href="https://apps.apple.com/hk/app/spoonful-meals/id1447225332?l=en"
                target="_blank"
              >
                <img src={downloadIOS} />
              </a>
            </div>
          </Col>
          <Col xs={3} smHidden />
          <Col xs={4} sm={3}>
            <img src={appScreen1} className="app-screen" />
          </Col>
          <Col xs={6} sm={3}>
            <img src={appScreen1} className="app-screen" />
          </Col>
          <Col xs={0} sm={1} /> */}
        </Row>
      </Grid>
      <Modal
        className="modal-location-not-found"
        onHide={() => setError('')}
        onExited={() => setError('')}
        show={!!error}
      >
        <Modal.Body>
          <div className="title">OOPS!</div>
          <div className="description">
            Sorry that we don’t deliver meal plans in your surroundings. Stay
            tuned and look for food for other places!
          </div>
          <div className="btn-container">
            <Button
              className="border-btn"
              onClick={() => history.push('/menu')}
            >
              SEE OUR MENU ANYWAY
            </Button>
            {!isLoggedIn && (
              <Button
                className="solid-btn"
                onClick={() => {
                  setError('');
                  toggleLoginDialog();
                }}
              >
                Sign Up
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
  language: selectLanguage(state), // Required for updating UI
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  toggleLoginDialog: () => dispatch(ActiontoggleLoginDialog(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
