import React from 'react';
import { connect } from 'react-redux';
import localization from '../../localization';
import { RootState } from '../../store';
import { selectLanguage } from '../../features/Settings/duck/selector';
import './HomeBanner.scss';

interface Props {
  language: string;
}
const HomeBanner: React.FC<Props> = ({ language }) => {
  return (
    <div className="home-banner-container">
      <div
        className="home-banner-bg"
        style={{ backgroundImage: 'url(/img/banner-desktop.jpg)' }}
      >
        <img src="/img/banner-mobile.jpg" alt="banner" />
      </div>
      <div className="home-banner-content">
        <div className="home-banner-txt">
          <strong>{localization.menuBannerTxt1}</strong>
          <span>{localization.menuBannerTxt2}</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  language: selectLanguage(state),
});

export default connect(mapStateToProps)(HomeBanner);
