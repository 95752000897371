import React from 'react';
import * as ReactGA from 'react-ga';
import moment from 'moment-timezone';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { history } from './store';
import {
  CutOffTimeBanner,
  Footer,
  MenuBar,
  Faqs,
  Blog,
  CaseStudy,
  ModalPopup,
} from './components';

import './App.scss';
import Catering from './features/Catering/Catering';
import ForgotPasswordContainer from './features/Auth/containers/ForgotPasswordContainer';
import ResetPasswordContainer from './features/Auth/containers/ResetPasswordContainer';
import VerifyEmailContainer from './features/Auth/containers/VerifyEmailContainer';
import ResendEmailVerifyContainer from './features/Auth/containers/ResendEmailVerifyContainer';
import LoginModal from './features/Auth/containers/LoginModal';
import ProfileContainer from './features/Profile/containers/ProfileContainer';
import AddressModalContainer from './features/Profile/containers/AddressModalContainer';
import MenuContainer from './features/Menu/containers/MenuContainer';
import Checkout from './features/Orders/container/Checkout';
import CheckoutSuccess from './features/Orders/container/CheckoutSuccess';
import Home from './features/Home/Home';
import OrderHistory from './features/Orders/container/OrderHistory';
import OrderHistoryDetail from './features/Orders/container/OrderHistoryDetail';
import Rating from './features/Rating/Rating';
import Test from './features/Test/Test';
import TermsAndConditions from './features/TermsAndConditions/TermsAndConditions';
import { popup, disableBanner } from './img';
import { DISABLE_SITE } from './helpers/constants';

const MONTHS = [
  '一月',
  '二月',
  '三月',
  '四月',
  '五月',
  '六月',
  '七月',
  '八月',
  '九月',
  '十月',
  '十一月',
  '十二月',
];

const WEEKDAYS = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

moment.locale('zht', {
  months: MONTHS,
  monthsShort: MONTHS,
  monthsParseExact: true,
  weekdays: WEEKDAYS,
  weekdaysShort: WEEKDAYS,
  weekdaysMin: WEEKDAYS,
  weekdaysParseExact: true,
});

const App: React.FC = () => {
  React.useEffect(() => {
    ReactGA.initialize('UA-133442515-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [open, setOpen] = React.useState(true);

  if (DISABLE_SITE) {
    return (
      <a href="https://nosh.hk/">
        <div className="banner-wrapper">
          <img src={disableBanner} alt="" className="closing-banner" />
        </div>
      </a>
    );
  }
  return (
    <>
      <ModalPopup
        isVisible={open}
        onHide={() => {
          setOpen(false);
        }}
        onBackdropClick={() => {
          window.location.href = 'https://nosh.hk/en/';
        }}
        className="announcement-popup"
        backdrop="static"
        style={{ overflowY: 'hidden', cursor: 'pointer' }}
        modalBodyStyle={{ padding: 0 }}
      >
        <div>
          <a href="https://nosh.hk/en/">
            <img src={popup} alt="" className="popup-img" />
          </a>
        </div>
      </ModalPopup>
      <h1 className="a11y-hidden">Food Delivery Service in Hong Kong</h1>
      <Helmet>
        <title>
          Ready to eat meals | Food Delivery Hong Kong | Spoonful Meals
        </title>
        <meta
          name="description"
          content="Spoonful Meals make food delivery easy. Just click, select and we bring ready-to-eat meals right to your doorstep. Easy and convenient. Sign up today!"
        />
      </Helmet>
      <div>
        <MenuBar>
          <CutOffTimeBanner />
        </MenuBar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            minHeight: '70vh',
            alignItems: 'center',
            marginTop: '80px',
          }}
        >
          <div
            style={{
              minHeight: '70vh',
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
              width: '100%',
            }}
          >
            <LoginModal />
            <AddressModalContainer />
            <ConnectedRouter history={history}>
              <Switch>
                <Route
                  exact
                  path="/account/request_reset_password"
                  component={ForgotPasswordContainer}
                />
                <Route
                  exact
                  path="/account/forget_password"
                  component={ResetPasswordContainer}
                />
                <Route
                  exact
                  path="/account/verify_email"
                  component={VerifyEmailContainer}
                />
                <Route
                  exact
                  path="/account/resend_verify_email"
                  component={ResendEmailVerifyContainer}
                />
                <Route exact path="/checkout" component={Checkout} />
                <Route
                  exact
                  path="/order/success"
                  component={CheckoutSuccess}
                />
                <Route exact path="/profile" component={ProfileContainer} />
                <Route exact path="/faq" component={Faqs} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/casestudy" component={CaseStudy} />
                <Route exact path="/catering" component={Catering} />
                <Route exact path="/menu" component={MenuContainer} />
                <Route exact path="/members">
                  <Redirect to="/menu" />
                </Route>
                <Route exact path="/rating" component={Rating} />
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/my-orders" component={OrderHistory} />
                <Route
                  exact
                  path="/my-orders/:id"
                  component={OrderHistoryDetail}
                />
                <Route exact path="/devinfo" component={Test} />
                <Route
                  exact
                  path="/TermsAndConditions"
                  component={TermsAndConditions}
                />
              </Switch>
            </ConnectedRouter>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default App;
