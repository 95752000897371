export default {
  spoonfulmembers: 'Spoonful 會員',
  corporateLunch: '商務午餐',
  mealPlans: '精選食譜',
  orderingFor1: '預訂',
  orderingFor2: '的美食',
  orderingForTomorrow: '預訂明天的美食?',
  youHave: '你尚有',
  left: ' ',
  nextWeekMenuAvailability: '下星期的餐單將於星期五上午9時30分公佈',
  nextWeekMenu: '下星期的餐單',
  returnToOrder: '回去本星期的餐單',
  sidesAndDrinks: '單點和飲料 (自選)',
  haveSomethingElseWithYourMeal: '要不要加配其他食品?',
  dontHaveACorporateAccount: '沒有商務用戶賬號?',
  wantToJoinUs: '想加入我們嗎？',
  lookingForLargerTeamOrders: '想替多過30 個人訂午餐?',
  completedSelections: '你已經選擇完畢',
  getReadyForAnAwesomeWeek: '準備下週吃更好更健康的美食吧!',
  ourSuppliers: '我們的供應商',
  login: '登入',
  signup: '註冊',
  createAnAccount: '建立帳户',
  nextDay: '下一天',
  weekOf: '週',
  mon: '星期一',
  tue: '星期二',
  wed: '星期三',
  thu: '星期四',
  fri: '星期五',
  monday: '星期一',
  tuesday: '星期二',
  wednesday: '星期三',
  thursday: '星期四',
  friday: '星期五',
  faqs: '常見問題',
  aboutNosh: '關於 Spoonful',
  needHelp: '需要幫忙？',
  getInTouch: '聯絡我們',
  company: '公司',
  termsAndConditions: '相關條約',
  privacyPolicy: '私隱條例',
  calories: '卡路里',
  protein: '蛋白質',
  carbs: '碳水化合物',
  fats: '脂肪',
  q1: '甚麼是即食餐點？',
  a1:
    '我們的餐點是每天早上新鮮準備好，然後再存入攝氏1度的商用雪櫃。所以冷藏好的餐點會送到你手上，讓你可以於48小時內隨時翻熱及享用。收到餐點後放進雪櫃保存即可。',
  q2: '你們和Deliveroo、Food Panda 等等有什麼分別？',
  a2:
    '我們是一個虛擬的自助餐廳，烹飪自己精心設計的飯菜。我們以批發價格出售食品，讓您不用支付餐廳高昂的價格。',
  q3: '你們收取外賣費嗎?',
  a3:
    '訂單少於每天港幣200元，我們將會收取 HKD 25 - 35 運費。運費會視乎送餐地區不等。\n訂單每天滿港幣200元，運費全免！',
  q4: '我可以選擇喜歡的食譜嗎？',
  a4:
    '當然可以！我們每天提供6-8個選項，由美式燒烤至日式或泰式咖哩等菜式應有盡有。',
  q5: '我可以在那裏看到餐單？',
  a5: '您可以上 https://www.spoonfulmeals.com 觀看我們一星期的選擇。',
  q6: '為何你們只顯示星期一至五？',
  a6: '我們會以一週作為訂單單位，您可以選擇減少送餐日子。',
  q7: '你們可以因為食物敏感而替換食材嗎？',
  a7: '不好意思，我們不會替換食材，請在下單前檢視菜式裏的成分。',
  q8: '你們有素食、純素或魚素者餐單嗎？',
  a8: '我們在2018年九月開始提供素食。',
  q9: '我可以點早餐嗎？',
  a9:
    '我們在現階段只專注於午餐訂購服務，為商戶提供最好和最新鮮的飯盒。請電郵件corporate@nosh.hk 來自訂早餐。',
  q10: '截單時間是什麼時候？',
  a10:
    '我們會於下午2時30分截單，以確保餐點翌日送到。若你希望星期一送餐，可於星期六下午2時30分前下單。',
  q11: '如果我要去旅行怎麼辦？',
  a11:
    '您可以到帳戶內的設定去取消任何一天的訂單。我們會以Spoonful現金券給你退款。',
  q12: '我可以取消在節日的訂單嗎？',
  a12: '當然可以。您可以選擇取消任何訂單。',
  q13: '我可以以個人身份登記商務午餐嗎？',
  a13:
    '商務午餐是為最少10個訂單而設的送貨服務。以個人餐單來說，可以去 nosh.hk/mealplans 選購個人餐單。',
  q14: '如何付款？',
  a14:
    '我們會透過Stripe接受以不同信用卡的付款。你亦可以選擇購買Spoonful 硬幣積分 - 每當你購買港幣500元的Spoonful 硬幣積分，你將會免費穫得港幣35元積分回贈。',
  faqSupport1: '你的問題尚未解決？',
  faqSupport2: '請致電我們',
  company_signup_main_header: 'Corporate Virtual Cafeteria for your office',
  company_signup_main_subheader: 'POWER YOUR TEAM',
  signup_now: 'SIGN UP',
  eat_smarter_achieve_more: 'How It Works. Eat Smarter.',
  eat_smarter_description:
    'Give your employees the ability to order the delicious meals delivered to the office at a company discounted rate.',
  key_features_title: 'Key Features of the Corporate Lunch Program',
  western_asian_cuisines: 'Western & Asian Cuisines',
  corporate_rate: 'Corporate Rate',
  no_minimum_order: 'No Minimum Order',
  menu_changes_everyday: 'Menu Changes Every Day',
  free_delivery: 'Free Delivery',
  biodegradable_packaging: 'Biodegradable Packaging',
  catering: '備辦食物',
  bento_box: '便當盒',
  sharing_platter_buffer: '拼盤／自助餐',
  lunch_box: '午餐盒',
  see_our_catering_menu: '看我們的餐飲菜單',
  order_weekly_desc:
    '只需選擇你的菜系、人數及訂購日數，熱辣辣的美食將會每天為你送上。',
  catering_menu: '到會餐牌',
  canape_menu: '開胃菜餐牌',
  corporate_lunch_box: 'CORPORATE LUNNCH BOX',
  junk_menu: 'JUNK MENU',
  company_team_title2: 'OUR BRANDS',
  company_team_desc2:
    'NOSH owns and operates two brands for its omni channel strategy where we sell to corporates, direct to consumer, and through retail outlets. Our mission is to design food for delivery and help Hong Kong eat smarter.',
  retail_info: 'RETAIL INFO',
  event_title: '派對及活動到會',
  event_desc1:
    '我們可以提供商務會議、小型會議和大型企業活動到會服務。具有彈性的菜單和定價，30人團餐到500人大型活動到會皆可包攬。',
  start_here: '立即開始',
  hk: '香港',
  sg: '新加坡',
  menuBannerTxt1: '搵唔到嫌太多Spoonful喺雪櫃嘅理由。',
  menuBannerTxt2: '食好啲，罷煮。',

  menuBarMenu: '餐牌',
  menuBarCatering: '派對及活動到會',
  menuBarCredits: '積分',
  menuBarMyAccount: '我的帳户',
  menuBarProfile: '個人資料',
  menuBarMyOrders: '我的訂單',
  menuBarLogOut: '登出',

  homeBannerMain: '外送的零罪惡、飽足感滿分的療癒餐點',
  homeBannerSub:
    ' Spoonful是全亞洲最大規模即食餐點的外送服務, 每天最低消費為 $100。',
  homeBannerInput: '8 Finance St, Central',
  homeBannerSubmit: '送到口邊',

  homeBodyHowItWorks: '我們如何運作',
  homeBodyHowItWorksText:
    '我們是一間網上即食餐點送遞服務。我們每天新鮮製造餐點，細心包裝，讓你不用再憂心處理污糟的碗碟。',
  homeBodySpoonfulFeatures: 'Spoonful 特點',
  homeBodySpoonfulFeaturesText1: '每天早上7-9點送到',
  homeBodySpoonfulFeaturesText2: '前一天下單，或計劃好整個星期的餐',
  homeBodySpoonfulFeaturesText3: '翻熱即食',
  homeBodyOurBrands: '我們的品牌',
  homeBodyOurBrandsText:
    '我們精心設計每日不同的餐牌，集合中、日、西餐，讓你永不厭倦！',
  homeBodyOurPricingPlan: '我們的價格',
  homeBodyOurPricingPlanText1: '每天最低消費',
  homeBodyOurPricingPlanText2: '',
  homeBodyOurPricingPlanText3: '落單每天滿',
  homeBodyOurPricingPlanText4: '可享免運費',
  homeBodyOurPricingPlanText5: '每購買{0}的硬幣積分，你將穫得',
  homeBodyOurPricingPlanText6: '回贈。',
  homeBodyDownloadToday: '即時下載我們的應用程式吧！',
  homeSeeOurMenu: '睇餐牌',

  mealType: {
    main: '主食',
    side: '配菜/小食',
    breakfast: '早餐',
    protein: '蛋白質',
    special: '特餐',
    kids: '小童',
    drink: '飲品',
    undefined: '--',
  },
  mealFilter: {
    filter: '篩選',

    chicken: '雞肉',
    beef: '牛肉',
    pork: '豬肉',
    lamb: '羊肉',
    fish: '魚類',
    vegetarian: '素菜',
    vegan: '素食',
    spicy: '辣味',
    not_spicy: '不辣',
    kids_friendly: '適合兒童',
  },

  menuTotal: '總額：',
  menuCheckOut: '結帳',

  menuModalSoldOut: '售罄',
  menuModalDescription: '內容',
  menuModalIngredients: '成份',
  menuModalAddToCart: '加入購物車',
  menuModalUserRatings: '用户評價',

  cartExpressCheckout: '特快結帳',

  loginModalPassword: '密碼',
  loginModalForgot: '忘記密碼？',
  loginModalPhoneNumber: '電話號碼',
  loginModalConfirm: '確認密碼',
  loginModalAlreadySignedUp: '已經登記？',

  hkd: '港幣{0}元',
  feedMe: '送到口邊',
  address: '地址',

  oops: '噢！',
  locationNotDelivered:
    '對不起，我們現在未能送到這個地址。當Spoonful擴闊到這個送餐地區時，我們會馬上讓你知道！',
  viewMenuAnyway: '繼續查看餐牌',
  changeAddress: '更改地址',
  addressSaved: '你的更新已經儲存！',

  profile: '個人資料',
  resetPassword: '重設密碼',
  spoonfulCoins: 'Spoonful 硬幣',
  coins: '硬幣',
  profileSaved: '你的更新已經儲存！',
  personalDetails: '個人資料',
  name: '姓名',
  email: '電郵地址',
  contactNo: '聯絡電話',
  contactNumber: '聯絡電話',
  save: '儲存',
  newPassword: '新密碼',
  confirmNew: '確認新密碼',
  buildingApartmentUnit: '樓棟/公寓  單位',
  myAddress: '我的地址',
  addCoins: '賺買硬幣',

  myOrders: '我的訂單',
  noOrders: '你現在沒有訂單',
  backToHome: '回到首頁',

  cartSmallBasketSurcharge: '額外費用',
  cartMoa: '你需要購買最少港幣100元。',
  cartDeliveryFee: '運費',
  cartDelivery:
    '價值低於每天港幣200元的訂單，我們將會收取運費。視乎送餐地區，運費由港幣25元 - 港幣35元不等。訂單滿每天港幣200元，，運費全免！',
  cartItems: '項目',
  deliveryInfo: '送餐資料',
  deliveryNotesToDriver: '送餐司機備示錄？',
  payment: '付款',
  discountReferralCode: '折扣碼/介紹碼',
  creditCard: '信用卡',
  creditCardDetails: '信用卡資料',
  spoonfulCoinsRemaining: 'Spoonful 硬幣餘額',
  confirmAndPay: '確認及付款',
  cartTotal: '總值：',
  free: '免費',
  ourBrandPartners: '合作品牌',
  ourBrandPartnersDesc:
    'We curate a daily, ever changing menu of Japanese, Chinese, and Western meals so that you are never bored.',
  ratingSubmit: '提交',
  ratingComments: '評語',
  ratingSubmittedMsg: '評價已提交，謝謝！',
  langKey: 'zh',
  referralTitle: '我的推薦碼',
  referralDesc: '邀請你的朋友以獲得獎勵',
  referralRule1: '送30, 賺30',
  referralRule2: 'SPOONFUL 硬幣',
  referralShare: '分享',
  referralTips:
    '分享以上專屬你的推薦碼！ 當你的朋首次下單時使用此代碼，你們雙方將會同時獲得30個Spoonful硬幣。',
};
