import { ResponseLogin } from '../models/ResponseLogin';
import { ResponseSignUp } from '../models/ResponseSignUp';
import { User } from '../models/User';
import axios, { setToken } from './client';

export const login = (payload: { email: string; password: string }) =>
  axios.post<ResponseLogin>('v1/access/login', payload).then((res) => {
    setToken(res.data.token);
    localStorage.setItem('token', res.data.token);
    return res;
  });

export const signUp = async (payload: {
  email: string;
  password: string;
  phone: string;
  name: string;
}) => {
  const {
    data: { token },
  } = await axios.post<ResponseSignUp>('v1/access/signup', {
    ...payload,
    username: payload.email,
    phone: parseInt(payload.phone, 10),
  });
  setToken(token);
  localStorage.setItem('token', token);
  const {
    data: { user },
  } = await axios.get<{ user: User }>('v1/user');
  return { token, user };
};
export const loginFacebook = (
  email: string,
  facebookId: string,
  accessToken: string,
  memberName: string,
) =>
  axios.post('v1/access/facebook_login', {
    email,
    facebookId,
    accessToken,
    memberName,
  });

export const resetPassword = (email: string, password: string) =>
  axios.post('v1/access/_reset_password', { email, password });

export const changePassword = (email: string, password: string) =>
  axios.post('v1/user/change_password', { email, password });

export const getUser = () => axios.get<{ user: User }>('v1/user');

export const getLatestPaymentMethod = () =>
  axios.get('v1/user/payment_methods');

export const addCoins = (
  amount: string,
  extra: string,
  payment_method_id: string,
) => {
  return axios.post('/v1/user/add_coins', {
    amount: parseInt(amount, 10),
    extra: parseInt(extra, 10),
    payment_method_id,
  });
};
