/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { AppDispatch, RootState } from '../../../../store';
import localization from '../../../../localization';
import './RatingCard.scss';

interface Props {
  dishInfo: any;
  // isLoggedIn: boolean;
  language: string;
  updateRatings: (target: string, ratingData: any) => void;
}

const RatingCard = ({ dishInfo, language, updateRatings }: Props) => {
  const [rating, setRating] = React.useState(0);
  const [comments, setComment] = React.useState('');
  const [hoverRating, setHoverRating] = React.useState(0);
  // const [error, setError] = React.useState('');
  React.useEffect(() => {
    updateRatings(dishInfo._id, {
      dish_id: dishInfo._id,
      comment: comments,
      rating,
    });
  }, [rating, comments]);

  return (
    <div className="rating-card">
      <div className="rating-card-body">
        <div className="rating-card-info">
          <div
            className="rating-card-img"
            style={{ backgroundImage: `url(${dishInfo?.image_url})` }}
          />
          <div className="rating-card-name">
            {dishInfo[`name_${localization.langKey}`] || '--'}
          </div>
        </div>
        <div className="rating-card-rating">
          {Array(5)
            .fill(0)
            .map((item: any, index: number) => {
              const adjustKey = index + 1;
              return (
                <React.Fragment
                  key={`rating-star-${adjustKey}-${dishInfo?._id}`}
                >
                  <button
                    className={`rating-star ${
                      rating >= adjustKey ? 'mod-active' : ''
                    } ${hoverRating >= adjustKey ? 'mod-hover' : ''}`}
                    type="button"
                    onClick={() => {
                      setRating(adjustKey);
                    }}
                    onMouseOver={() => {
                      setHoverRating(adjustKey);
                    }}
                    onMouseOut={() => {
                      setHoverRating(0);
                    }}
                    onFocus={() => {
                      setHoverRating(adjustKey);
                    }}
                    onBlur={() => {
                      setHoverRating(adjustKey);
                    }}
                  >
                    {index}
                  </button>
                </React.Fragment>
              );
            })}
        </div>
        <div className="rating-comment-box">
          <div className="rating-comment-label">
            {localization.ratingComments}
          </div>
          <textarea
            value={comments}
            onChange={(e) => setComment(e.target.value)}
            className="rating-comment-input"
          />
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state: RootState) => ({
//   // isLoggedIn: selectIsLoggedIn(state),
//   // language: selectLanguage(state), // Required for updating UI
// });

// const mapDispatchToProps = (dispatch: AppDispatch) => ({
//   // toggleLoginDialog: () => dispatch(ActiontoggleLoginDialog(true)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(RatingCard);
export default RatingCard;
