/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik, FormikHelpers, Form, Field } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { RootState } from '../../../store';
import Loader from '../../../components/Loader/Loader';
import * as AuthService from '../../../services/auth';
import { User } from '../../../models/User';
import { selectUser } from '../../Auth/duck/selector';
import localization from '../../../localization';
import { selectLanguage } from '../../Settings/duck/selector';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password should be at least 8 characters.')
    .required('Please provide your password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], "Passwords don't match")
    .min(8, 'Password should be at least 8 characters.')
    .required('Confirm Password is required'),
});

interface Props {
  user: User;
  language: string;
}

const UpdatePasswordForm: React.FC<Props> = ({ user }) => {
  const onSubmit = (
    values: FormValues,
    { setSubmitting, setStatus, resetForm }: FormikHelpers<FormValues>,
  ) => {
    AuthService.changePassword(user.email, values.password)
      .then((response) => {
        resetForm();
        setStatus('success');
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, errors, status }) => (
          <Form>
            {isSubmitting && (
              <div className="dimmed-loader">
                <Loader />
              </div>
            )}
            <label htmlFor="password">{localization.newPassword}</label>
            <Field
              id="password"
              name="password"
              type="password"
              autocomplete="new-password"
            />
            {errors.password && (
              <div className="error-message">{errors.password}</div>
            )}
            <label htmlFor="confirmPassword">{localization.confirmNew}</label>
            <Field
              id="confirmPassword"
              name="confirmPassword"
              type="password"
            />
            {errors.confirmPassword && (
              <div className="error-message">{errors.confirmPassword}</div>
            )}
            <Button
              className="next-btn bottom-spacing"
              type="submit"
              disabled={
                isSubmitting || !values.password || !values.confirmPassword
              }
            >
              {localization.save}
            </Button>
            {status === 'success' && (
              <div style={{ marginTop: '1em' }}>
                Your password has been reset successfully
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  language: selectLanguage(state),
});
export default connect(mapStateToProps)(UpdatePasswordForm);
