/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './ModalPopup.scss';

interface Props {
  isVisible: boolean;
  onHide: () => void;
  onExited?: () => void;
  className?: string;
  popupId?: string;
  children: JSX.Element;
  backdrop?: string;
  onBackdropClick?: () => void;
  style?: any;
  modalBodyStyle?: any;
}

const ModalPopup: React.FC<Props> = ({
  children,
  isVisible,
  onHide,
  onExited,
  className,
  popupId,
  backdrop,
  onBackdropClick,
  style,
  modalBodyStyle,
}) => (
  <Modal
    id={popupId}
    className={`modal-popup-container ${className}`}
    onHide={onHide}
    onExited={onExited}
    show={isVisible}
    backdrop={backdrop}
    style={style}
  >
    <Modal.Body style={modalBodyStyle}>
      <button onClick={onBackdropClick} className="backdrop" type="button" />
      {children}
      <Button className="close-btn" onClick={onHide} />
    </Modal.Body>
  </Modal>
);

export default ModalPopup;
