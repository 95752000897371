import LocalizedStrings from 'react-localization';
import en from './en';
import zht from './zht';

const localization = new LocalizedStrings({
  en,
  zht,
});

export default localization;
