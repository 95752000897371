/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import Geocode from 'react-geocode';
import { AppDispatch, history, RootState } from '../../../store';
import { setUser } from '../../Auth/duck';
import { selectUser } from '../../Auth/duck/selector';
import { findZone } from '../../../services/orders';
import * as AccountService from '../../../services/AccountService';
import { toggleAddressDialog as ActionToggleAddressDialog } from '../duck';
import { ResponseLogin } from '../../../models/ResponseLogin';
import './MyAddressForm.scss';
import localization from '../../../localization';
import { selectLanguage } from '../../Settings/duck/selector';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_API_KEY || '');
Geocode.setRegion('hk');

interface FormValues {
  address: string;
  district: string;
}

interface Props {
  // address: any;
  toggleAddressDialog: (open: boolean) => void;
  onSaveSuccess: (payload: ResponseLogin) => void;
  user: any;
  title?: string;
  isPopup?: boolean;
  mode?: string;
  language: string;
}

const MyAddressForm: React.FC<Props> = ({
  // address,
  toggleAddressDialog,
  onSaveSuccess,
  user,
  title,
  isPopup = false,
  mode,
}) => {
  const [addressStatus, setAddressStatus] = React.useState('');
  const onSubmit = (
    values: FormValues,
    { setSubmitting, setStatus, resetForm }: FormikHelpers<FormValues>,
  ) => {
    const { address, district } = values;
    AccountService.updateAddress(address, district)
      .then((response) => {
        resetForm();
        setStatus('success');
        onSaveSuccess({
          ...response.data,
          token: localStorage.getItem('token'),
        });
        if (isPopup) {
          // Validation
          if (address && address.length > 3) {
            Geocode.fromAddress(`${address}`)
              .then((thisRes: any) => {
                const { location } = thisRes.results[0].geometry;
                if (location.lat && location.lng) {
                  findZone(location.lat, location.lng)
                    .then((res) => {
                      setAddressStatus('success');
                      toggleAddressDialog(false);
                    })
                    .catch((e) => {
                      setAddressStatus('fail');
                    });
                }
              })
              .catch((e) => {
                setAddressStatus('fail');
              });
          } else {
            setAddressStatus('fail');
          }
        } else {
          setAddressStatus('success');
          window.alert(localization.addressSaved);
        }
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {/* {mode ? <div className="title">{mode}</div> : null}
      {addressStatus ? <div className="title">{addressStatus}</div> : null} */}
      {!isPopup || (addressStatus === '' && mode !== 'fail') ? (
        <>
          <div className="title">
            {title || localization.myAddress.toUpperCase()}
          </div>
          {user ? (
            <Formik enableReinitialize initialValues={user} onSubmit={onSubmit}>
              <Form>
                {!isPopup ? (
                  <>
                    <label>{localization.buildingApartmentUnit}</label>
                    <Field name="district" type="text" />
                  </>
                ) : null}
                <label>{localization.address}</label>
                <Field name="address" type="text" />
                <Button
                  className="next-btn bottom-spacing"
                  type="submit"
                  // disabled={
                  //   isSubmitting || !values.password || !values.confirmPassword
                  // }
                >
                  {isPopup && !(mode === 'checkout')
                    ? localization.feedMe.toUpperCase()
                    : localization.save.toUpperCase()}
                </Button>
              </Form>
            </Formik>
          ) : null}
        </>
      ) : null}
      {isPopup && addressStatus === 'success' ? (
        <>
          <div className="title">You&apos;re all set to go!</div>
          <Button
            className="next-btn bottom-spacing"
            type="button"
            onClick={() => {
              toggleAddressDialog(false);
            }}
          >
            Okay
          </Button>
        </>
      ) : null}
      {(isPopup && addressStatus === 'fail') || mode === 'fail' ? (
        <>
          <div className="title">{localization.oops.toUpperCase()}</div>
          <p className="address-modal-desc">
            {localization.locationNotDelivered}
          </p>
          <Button
            className="next-btn bottom-spacing"
            type="button"
            onClick={() => {
              toggleAddressDialog(false);
            }}
          >
            LEAVE MY EMAIL
          </Button>
          <Button
            className="next-btn bottom-spacing"
            type="button"
            onClick={() => {
              toggleAddressDialog(false);
            }}
          >
            {mode === 'signup'
              ? localization.viewMenuAnyway.toUpperCase()
              : 'OKAY'}
          </Button>
          <Button
            className="next-btn bottom-spacing"
            type="button"
            onClick={() => {
              setAddressStatus('');
            }}
          >
            {localization.changeAddress.toUpperCase()}
          </Button>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  language: selectLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSaveSuccess: (payload: any) => {
    dispatch(setUser(payload));
  },
  toggleAddressDialog: (open: boolean) =>
    dispatch(ActionToggleAddressDialog({ open, mode: 'signup' })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAddressForm);
