/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { facebook, instagram, payment } from '../../img';
import { RootState } from '../../store';
import { selectLanguage } from '../../features/Settings/duck/selector';
import localization from '../../localization';
import ReactPixel from '../../ReactPixel';
import './Footer.scss';

const Footer: React.FC = () => (
  <div id="footer">
    <Grid className="footer-content">
      <Row>
        <Col className="menus-container" xs={12} sm={8}>
          <Row>
            <Col xs={6} sm={3}>
              <div className="text title">{localization.mealPlans}</div>
              <div>
                <a href="https://www.nosh.hk/our-menu/" className="text link">
                  {localization.mealPlans}
                </a>
              </div>
              <div>
                <a
                  href="https://spoonfulmeals.com/catering"
                  className="text link"
                >
                  {localization.catering}
                </a>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="text title">{localization.needHelp}</div>
              <div>
                <a href="/faq" className="text link">
                  {localization.faqs}
                </a>
              </div>
              {/* <div>
                <a href="https://www.nosh.hk/Suppliers" className="text link">
                  {localization.aboutNosh}
                </a>
              </div> */}
              {/* <div>
                <a href="/blog" className="text link">
                  Blog
                </a>
              </div> */}
            </Col>
            <Col xs={6} sm={3}>
              <div className="text title">{localization.getInTouch}</div>
              <div>
                <a
                  onClick={() => {
                    ReactPixel.track('Contact', {});
                  }}
                  href="tel:+852 3613 0399"
                  className="text link"
                >
                  +852 3613 0399
                </a>
              </div>
              <div>
                <a
                  onClick={() => {
                    ReactPixel.track('Contact', {});
                  }}
                  href="mailto:hello@spoonfulmeals.com"
                  className="text link"
                >
                  hello@spoonfulmeals.com
                </a>
              </div>
              <div>
                <a
                  href="https://www.happyer.io/companies/spoonful-hongkong#Jobs"
                  className="text link"
                >
                  Join Us
                </a>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="text title">{localization.company}</div>
              <div>
                <a href="/TermsAndConditions" className="text link">
                  {localization.termsAndConditions}
                </a>
              </div>
              <div>
                <a
                  href="https://www.nosh.hk/PrivatePolicy"
                  className="text link"
                >
                  {localization.privacyPolicy}
                </a>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={1} sm={1}>
          <a href="https://www.facebook.com/spoonfulmeals" target="_blank">
            <img className="facebook-icon" src={facebook} />
          </a>
        </Col>
        <Col xs={1} sm={1}>
          <a href="https://www.instagram.com/spoonful.meals/" target="_blank">
            <img className="instagram-icon" src={instagram} />
          </a>
        </Col>
        <Col xs={1} sm={1}>
          <img className="payment-logos" src={payment} />
        </Col>
      </Row>
    </Grid>
  </div>
);

const mapStateToProps = (state: RootState) => ({
  language: selectLanguage(state),
});

export default connect(mapStateToProps)(Footer);
