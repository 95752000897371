import { createSelector } from 'reselect';
import { RootState } from '../../../store';

const selectAuth = (state: RootState) => state.auth;

export const selectIsLoggedIn = createSelector(
  selectAuth,
  (auth) => !!auth.token,
);

export const selectShowLoginDialog = createSelector(
  selectAuth,
  selectIsLoggedIn,
  (auth, loggedIn) => auth.showLoginDialog && !loggedIn,
);

export const selectUser = createSelector(selectAuth, (auth) => auth.user);

export const selectCoinsBalance = createSelector(
  selectUser,
  (user) => user?.credits || 0,
);
