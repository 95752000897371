import React from 'react';
import { Button, Col, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { History } from 'history';
import { successIcon } from '../../../img';
import { RootState } from '../../../store';
import { selectIsLoggedIn } from '../../Auth/duck/selector';
import './CheckoutSuccess.scss';

interface Props {
  history: History;
}

const CheckoutSuccess = ({ history }: Props) => {
  const goToHome = () => {
    history.replace('/');
  };

  const viewOrders = () => {
    history.replace('/my-orders');
  };

  return (
    <Grid id="payment-success-container">
      <Col sm={12} md={8}>
        <img src={successIcon} width="80" alt="success" />
        <div className="title">Payment Success</div>
        <div className="subtitle">
          We will send you an email to confirm your order shortly
        </div>
        <div>
          <Button className="button-white" onClick={goToHome}>
            BACK TO HOME
          </Button>
        </div>
        <Button onClick={viewOrders}>VIEW MY ORDERS</Button>
      </Col>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
});
export default connect(mapStateToProps)(CheckoutSuccess);
