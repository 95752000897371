import * as _ from 'lodash';
import { Brand } from './Brand';

export enum CusinieType {
  asian = 'asian',
  western = 'western',
  ericKayser = 'eric_kayser',
}

export enum Temperature {
  cold = 'cold',
  warm = 'warm',
}

export enum Recipe {
  vegetarian = 'vegeterian',
  spicy = 'spicy',
  beef = 'beef',
  chicken = 'chicken',
  glutenFree = 'gluten free',
  pescatarian = 'pescatarian',
}

export interface Meal {
  id: string;
  name: string;
  nameZh: string;
  description: string;
  descriptionZh: string;
  ingredients: string;
  ingredientsZh: string;
  cuisineType?: CusinieType;
  category: string;
  imageUrl: string;
  calories: number;
  protein: number;
  carbs: number;
  fat: number;
  price: number;
  brand?: Brand;
  discountRatio: number;
  recipes: Recipe[];
  temperature: Temperature | string;
}

export class Meal implements Meal {
  constructor(data: any) {
    this.id = data._id;
    this.name = data.name_eng;
    this.nameZh = data.name_zh;
    this.description = data.description_eng;
    this.descriptionZh = data.description_zh;
    this.ingredients = data.ingredient_label_eng;
    this.ingredientsZh = data.ingredient_label_zh;
    this.imageUrl = data.image_url;
    this.calories = data.total_calories;
    this.protein = data.total_protein;
    this.carbs = data.total_carb;
    this.fat = data.total_fat;
    this.cuisineType = data.cuisine;
    this.price = data.price;
    this.recipes = _.orderBy(data.recipes);
    this.temperature = data.temperature;
    this.category = data.type;
    if (data.brandId) {
      this.brand = new Brand(data.brandId);
    }
    this.discountRatio = data.discount_ratio;
  }

  getIconImageFor = (recipe: Recipe) => {
    switch (recipe) {
      case Recipe.vegetarian:
        return '/img/recipes/vegetarian.png';
      case Recipe.spicy:
        return '/img/recipes/spicy.png';
      case Recipe.beef:
        return '/img/recipes/beef.png';
      case Recipe.chicken:
        return '/img/recipes/chicken.png';
      case Recipe.glutenFree:
        return '/img/recipes/gluten-free.png';
      case Recipe.pescatarian:
        return '/img/recipes/pescatarian.png';
      default:
        return undefined;
    }
  };
}
