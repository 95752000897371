import _ from 'lodash';
import { Filter } from '../components/MenuFilter/MenuFilter';
import axios from './client';

export const getDailyMeals = (date: string, filters?: Filter[]): Promise<any> =>
  axios.get('v1/daily_meals', {
    params: {
      date,
      filters: filters
        ?.filter((item) => item.selected)
        ?.map((item) => item.value)
        ?.join(','),
    },
  });

export const getFilters = (): Promise<{ value: string; label: string }[]> =>
  axios
    .get('v1/filters')
    .then(({ data: { filters } }) =>
      filters.map((item: any) => ({ value: item._id, label: item.name })),
    );

export const getTypes = (): Promise<{ value: string; label: string }[]> =>
  axios.get('v1/dish_types').then(({ data: { types } }) =>
    types.map((item: any) => ({
      value: item._id,
      label: item.name,
      name_eng: _.capitalize(item.name),
      name_zht: item?.name_zht || _.capitalize(item.name),
    })),
  );

export const getCutoff = (): Promise<{
  cutoff: string;
  firstAvailable: string;
}> => axios.get('v1/daily_meals/cutoff').then(({ data }) => data);

export const getDishRating = (dishId: string): Promise<any> =>
  axios.get(`v1/dish_ratings/${dishId}`);
