declare global {
  interface Window {
    faq: any;
    gtag: any;
    dataLayer: any;
  }
}

const trackWindow: any = window;

export const gtagEventTracker = (eventName: string, option?: any) => {
  if (trackWindow.gtag) {
    if (option) {
      trackWindow.gtag('event', eventName, option);
    } else {
      trackWindow.gtag('event', eventName);
    }
  }
};

export const dataLayerTracker = (data: any) => {
  if (trackWindow.dataLayer) {
    trackWindow.dataLayer.push(data);
  }
};

export const generalTracker = (pattern: string, value?: any) => {
  if (value) {
    trackWindow.fbq('track', pattern, value);
  } else {
    trackWindow.fbq('track', pattern);
  }
};

export default generalTracker;
