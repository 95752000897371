import React from 'react';
import { Col, Grid, Row, Button } from 'react-bootstrap';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Order } from '../../../models/Order';
import { getOrder } from '../../../services/orders';
import { getUser as ActionGetUser } from '../../Auth/duck';
import { selectUser, selectIsLoggedIn } from '../../Auth/duck/selector';
import { selectLanguage } from '../../Settings/duck/selector';
import { AppDispatch, RootState } from '../../../store';
import './OrderHistoryDetail.scss';

import { DailyMeal } from '../../../models/DailyMeal';
import { User } from '../../../models/User';

interface Props {
  isLoggedIn: boolean;
  lang: string;
  user: User;
  getUser: () => void;
}

const OrderHistoryDetail = ({ lang, isLoggedIn, user, getUser }: Props) => {
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = React.useState<Order>();
  const history = useHistory();
  React.useEffect(() => {
    getUser();
    getOrder(id).then((res) => {
      const dataOrder = res.data.order;
      const dataItems = dataOrder.items;
      const dateList = dataItems.map((item: any) =>
        moment(item.dailyMealId.date),
      );
      const lastDate = moment.max(dateList);
      setOrder(
        new Order({
          ...dataOrder,
          items: dataItems.map((item: any) => ({
            ...item,
            dailyMealId: new DailyMeal(item.dailyMealId),
          })),
          lastDate,
        }),
      );
    });
  }, []);

  React.useEffect(() => {
    console.log('order', order);
  }, [order]);

  if (!isLoggedIn)
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );

  if (!order) {
    return (
      <div className="flex-center" style={{ marginTop: '2em' }}>
        <span>Loading...</span>
      </div>
    );
  }

  const orderStringDates = _.uniq(
    order.items.map((item) => {
      const date = moment(item.dailyMeal.date)
        .locale(lang)
        .format('YYYY-MM-DD');
      return date;
    }),
  );

  const groupedItemsByDate = _.groupBy(order.items, (item) =>
    moment(item.dailyMeal.date).format('YYYY-MM-DD'),
  );

  const startDateString = () => {
    if (order.startDate.isSame(order.endDate, 'day')) {
      return order.startDate.locale(lang).format('D MMM YYYY');
    }
    if (order.startDate.isSame(order.endDate, 'year')) {
      return order.startDate.locale(lang).format('D MMM');
    }
    return order.startDate.locale(lang).format('D MMM YYYY');
  };

  const endDateString = order.startDate.isSame(order.endDate, 'day')
    ? ''
    : order.endDate.locale(lang).format('- D MMM YYYY');

  return (
    <div id="order-history-details-container">
      <Grid>
        <Col xs={12} sm={8} smOffset={2} lg={6} lgOffset={3}>
          <div id="order-history-details-container">
            <div className="title">{`${startDateString()} ${endDateString}`}</div>
            {orderStringDates.map((dateString) => {
              const today = moment().startOf('day');
              const isAfterToday = today.isAfter(order.lastDate);
              return (
                <div key={dateString}>
                  <div className="items-date-title">
                    {moment(dateString, 'YYYY-MM-DD')
                      .locale(lang)
                      .format('dddd, D MMM')}
                  </div>
                  {groupedItemsByDate[dateString].map((cartItem) => {
                    console.log('keith', cartItem);
                    return (
                      <div className="cart-item-details-container">
                        <div className="quantity-container">{`${cartItem.quantity}x`}</div>
                        <div>
                          <div className="meal-title">
                            {cartItem.dailyMeal?.dish?.name}
                          </div>
                          <div className="rate-dish-container">
                            <span>{`HK$ ${cartItem.dailyMeal?.dish?.price}`}</span>
                            {isAfterToday && (
                              <Button
                                disabled={order.dishRatingSent}
                                onClick={() => {
                                  history.push(
                                    `/rating?orderId=${id}&userId=${user._id}`,
                                  );
                                }}
                                size={20}
                              >
                                {order.dishRatingSent
                                  ? 'DISH RATED'
                                  : 'RATE DISH'}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="separator" />
                </div>
              );
            })}
          </div>
          <Row>
            {order.subTotal > 0 && (
              <Col>
                <span className="gray-color" style={{ fontSize: '1.2em' }}>
                  {'Subtotal: '}
                  <strong>
                    HK$
                    {order.subTotal}
                  </strong>
                </span>
              </Col>
            )}

            {order.creditUsed > 0 && (
              <Col>
                <span className="gray-color" style={{ fontSize: '1.2em' }}>
                  {'Spoonful Credits used: '}
                  <strong className="primary-color">{order.creditUsed}</strong>
                </span>
              </Col>
            )}
          </Row>
          <Row style={{ margin: 0, marginTop: '3em' }}>
            <Col xs={16}>
              <div className="order-total-amount">
                {`Total: HK$ ${order.totalAmount}`}
              </div>
            </Col>
          </Row>
        </Col>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  isLoggedIn: selectIsLoggedIn(state),
  lang: selectLanguage(state),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(ActionGetUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryDetail);
