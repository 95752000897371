export default {
  spoonfulmembers: 'Members',
  corporateLunch: 'Corporate Lunch',
  mealPlans: 'Meal Plans',
  orderingFor1: 'Ordering for ',
  orderingFor2: '',
  orderingForTomorrow: 'Ordering for tomorrow?',
  youHave: 'You have',
  left: 'left',
  nextWeekMenuAvailability:
    "Next week's menu will be displayed on Friday 9.30am",
  nextWeekMenu: "Click here to see next week's menu",
  returnToOrder: 'Return to Order Page',
  sidesAndDrinks: 'Snacks & Drinks (Optional)',
  haveSomethingElseWithYourMeal:
    'Why not have something to go along with your meal?',
  dontHaveACorporateAccount: "Don't have an account?",
  wantToJoinUs: 'Want to Join Us?',
  lookingForLargerTeamOrders:
    'Looking for larger team orders for more than 30 people?',
  completedSelections: 'You have completed your selections',
  getReadyForAnAwesomeWeek: 'Get ready for an awesome week of eating better!',
  ourSuppliers: 'Our Suppliers',
  login: 'Login',
  signup: 'Sign Up',
  createAnAccount: 'Create an Account',
  nextDay: 'Next day',
  weekOf: 'Week of',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  faqs: 'FAQs',
  aboutNosh: 'About Spoonful',
  needHelp: 'Need Help?',
  getInTouch: 'Get in Touch',
  company: 'Company',
  termsAndConditions: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  calories: 'calories',
  protein: 'protein',
  carbs: 'carbs',
  fats: 'fats',
  q1: 'What are ready to heat meals?',
  a1:
    'Our meals are cooked fresh daily but then chilled to 1 degree in our commercial fridges.  They are delivered cold for you to heat up at any time over the following 48 hours.  Keep them in your refrigerator.',
  q2: 'What makes you different from Deliveroo, Food Panda, etc?',
  a2:
    'We are a virtual cafeteria that actually cooks and designs our own meals. In addition we focus on WHOLESALE prices so that you are not paying restaurant prices for delivery.',
  q3: 'Do you charge a delivery fee?',
  a3:
    'We offer free delivery for orders above HKD 200 per day. For orders below this amount, we charge a delivery fee of HKD 25 - 35 per day, depending on your delivery zone. ',
  q4: 'Do we get to choose our meals?',
  a4:
    'Yes! We have 6-10 options per day that incorporate a variety of cooking styles from American BBQ to Japanese to Thai Curries. Enjoy!',
  q5: 'Where can I look at the menu?',
  a5:
    'Feel free to check out the latest menu on https://www.spoonfulmeals.com. Our menu choices are shown across a whole week.',
  q6: 'Why do you show me Mon-Fri?',
  a6:
    'Our program is driven by weekly purchasing: you may choose to skip a day.',
  q7: 'Do you cater to allergies?',
  a7:
    'No we do not cater to specific requests. Please review the ingredient list on each dish prior to purchasing.',
  q8: 'Do you cater to vegetarian, pescatarian or vegan diet?',
  a8: 'We have at least one vegetarian option on our menu every day.',
  q9: 'Can we have breakfast included?',
  a9:
    'We do have a separate one-off catering menu for breakfast. Feel free to email hello@spoonfulmeals.com for any breakfast requests.',
  q10: 'When is the cut-off time?',
  a10:
    'Our cut-off time is 2:30 pm for next day delivery. For a Monday delivery, please order before Saturday 2:30 pm. ',
  q11: "What happens if I'm travelling and need to skip?",
  a11:
    'Please contact us directly for any order changes via email hello@spoonfulmeals.com or at number 36130399',
  q12: 'Can I skip on a holiday?',
  a12: 'Sure! Please choose to skip a day when you place the order.',
  q13: 'Can I order this corporate lunch subscription individually?',
  a13:
    'This program is designed for corporate lunches with a minimum order of 10 people. We would suggest ordering meal plans at nosh.hk/mealplans so that you can enjoy nutritious and delicious meals delivered straight to you.',
  q14: 'How does the payment work?',
  a14:
    'We accept a variety of credit cards through our Stripe integration. In addition you can choose to add Spoonful Coin Credits and receive 35 HKD for FREE for every 500 HKD you top up.',
  faqSupport1: "Can't find what you are looking for?",
  faqSupport2: 'Give us a call',
  company_signup_main_header: 'Corporate Virtual Cafeteria for your office',
  company_signup_main_subheader: 'POWER YOUR TEAM',
  signup_now: 'SIGN UP',
  eat_smarter_achieve_more: 'How It Works. Eat Smarter.',
  eat_smarter_description:
    'Give your employees the ability to order delicious meals delivered to the office at a company discounted rate.',
  key_features_title: 'Key Features of the Corporate Lunch Program',
  western_asian_cuisines: 'Western & Asian Cuisines',
  corporate_rate: 'Corporate Rate',
  no_minimum_order: 'No Minimum Order',
  menu_changes_everyday: 'Menu Changes Every Day',
  free_delivery: 'Free Delivery',
  biodegradable_packaging: 'Biodegradable Packaging',
  catering: 'Catering',
  bento_box: 'Bento Box',
  sharing_platter_buffer: 'Sharing Platter / Buffet',
  lunch_box: 'Lunch Box',
  see_our_catering_menu: 'SEE OUR CATERING MENU',
  order_weekly_desc: 'Feed your crowd with chef-curated recipes.',
  catering_menu: 'Catering Menu',
  canape_menu: 'Canapé Menu',
  corporate_lunch_box: 'CORPORATE LUNNCH BOX',
  junk_menu: 'JUNK MENU',
  company_team_title2: 'OUR BRANDS',
  company_team_desc2:
    'NOSH owns and operates two brands for its omni channel strategy where we sell to corporates, direct to consumer, and through retail outlets. Our mission is to design food for delivery and help Hong Kong eat smarter.',
  retail_info: 'RETAIL INFO',
  event_title: 'CATERING FOR LARGER EVENTS',
  event_desc1:
    'We cater to conference, meeting, and large corporate event. We offer speciality menu choice and pricing for group of 30 up to event of 500.',
  start_here: 'Start Here',
  hk: 'Hong Kong',
  sg: 'Singapore',
  menuBannerTxt1:
    "There's no such thing as too many Spoonful meals in your fridge.",
  menuBannerTxt2: 'Eat well, cook never.',

  menuBarMenu: 'menu',
  menuBarCatering: 'catering',
  menuBarCredits: 'credits',
  menuBarMyAccount: 'My Account',
  menuBarProfile: 'Profile',
  menuBarMyOrders: 'My Orders',
  menuBarLogOut: 'Log Out',

  homeBannerMain: 'Guilt-Free Comfort Food.\nNo Cooking Required.',
  homeBannerSub:
    "Spoonful by NOSH is Asia's largest ready-to-eat meal delivery service. Enter your address to get started!",
  homeBannerInput: '8 Finance St, Central',
  homeBannerSubmit: 'Feed Me',

  homeBodyHowItWorks: 'How It Works',
  homeBodyHowItWorksText:
    "Spoonful Meals is your go-to meal delivery service in Hong Kong. As your reliable food delivery platform, we prepare your meals with fresh produce and deliver them right to your doorstep.\nDownload our app to experience the finest food delivery service.\n\nYou won't have a dirty dish in sight! ",
  homeBodySpoonfulFeatures: 'Spoonful Features',
  homeBodySpoonfulFeaturesText1: 'Delivered Daily between\n7-9 am',
  homeBodySpoonfulFeaturesText2:
    'Order one day in advance\nOR plan your whole week',
  homeBodySpoonfulFeaturesText3: 'Ready-to-eat, reheating\nrequired',
  homeBodyOurBrands: 'Our Brands',
  homeBodyOurBrandsText:
    'We curate a daily, ever changing menu of Japanese, Chinese, and Western meals so that you are never bored.',
  homeBodyOurPricingPlan: 'Our Pricing Plan',
  homeBodyOurPricingPlanText1: '',
  homeBodyOurPricingPlanText2: 'Minimum Order per Day',
  homeBodyOurPricingPlanText3: '',
  homeBodyOurPricingPlanText4: 'Order Amount per Day For Free Delivery',
  homeBodyOurPricingPlanText5: 'For every {0} of credit purchased, receive ',
  homeBodyOurPricingPlanText6: ' for FREE',
  homeBodyDownloadToday: 'Download our APP today',
  homeSeeOurMenu: 'See Our Menu',

  mealType: {
    main: 'main',
    side: 'Side/Snack',
    breakfast: 'Breakfast',
    protein: 'Protein',
    special: 'Special',
    kids: 'Kids',
    drink: 'Drinks',
    undefined: '--',
  },
  mealFilter: {
    filter: 'FILTER',

    chicken: 'Chicken',
    beef: 'Beef',
    pork: 'Pork',
    lamb: 'Lamb',
    fish: 'Fish',
    vegetarian: 'Vegetarian',
    vegan: 'Vegan',
    spicy: 'Spicy',
    not_spicy: 'Not Spicy',
    kids_friendly: 'Kids Friendly',
  },

  menuTotal: 'Total:',
  menuCheckOut: 'Check Out',

  menuModalSoldOut: 'SOLD OUT',
  menuModalDescription: 'DESCRIPTION',
  menuModalIngredients: 'INGREDIENTS',
  menuModalAddToCart: 'ADD TO CART',
  menuModalUserRatings: 'User Ratings',

  cartExpressCheckout: 'EXPRESS CHECKOUT',

  loginModalPassword: 'Password',
  loginModalForgot: 'Forgot password?',
  loginModalPhoneNumber: 'Phone Number',
  loginModalConfirm: 'Confirm Password',
  loginModalAlreadySignedUp: 'Already Signed up?',

  hkd: '{0} HKD',
  feedMe: 'Feed Me',
  address: 'Address',

  oops: 'Oops!',
  locationNotDelivered:
    "We don't deliver to this location yet! We'll let you know when Spoonful lands in your delivert zone.",
  viewMenuAnyway: 'View Menu Anyway',
  changeAddress: 'Change Address',
  addressSaved: 'Address has been saved!',

  profile: 'Profile',
  resetPassword: 'Reset Password',
  spoonfulCoins: 'Spoonful Coins',
  coins: 'Coins',
  profileSaved: 'Profile has been saved!',
  personalDetails: 'Personal Details',
  name: 'Name',
  email: 'Email',
  contactNo: 'Contact No.',
  contactNumber: 'Contact Number',
  save: 'Save',
  newPassword: 'New password',
  confirmNew: 'Confirm new password',
  buildingApartmentUnit: 'Building / Apartment / Unit',
  myAddress: 'My Address',
  addCoins: 'Add Coins',

  myOrders: 'My Orders',
  noOrders: 'You have no orders',
  backToHome: 'Back to Home',

  cartSmallBasketSurcharge: 'Small Basket Surcharge',
  cartMoa: 'You need a MOA of 100 HKD to checkout',
  cartDeliveryFee: 'Delivery Fee',
  cartDelivery:
    'We charge a delivery fee for orders below HKD 200 per day. Depending on your delivery zone, the fee is between HKD 25 - 35 per day. Order over HKD 200 per day to receive free delivery.',
  cartItems: 'items',
  deliveryInfo: 'Delivery Info',
  deliveryNotesToDriver: 'Delivery notes to driver?',
  payment: 'Payment',
  discountReferralCode: 'Discount / Referral Code',
  creditCard: 'Credit Card',
  creditCardDetails: 'Credit Card Details',
  spoonfulCoinsRemaining: 'Spoonful Coins Remaining',
  confirmAndPay: 'Confirm & Pay',
  cartTotal: 'Total:',
  free: 'Free',
  ourBrandPartners: 'OUR BRAND PARTNERS',
  ourBrandPartnersDesc:
    'We curate a daily, ever changing menu of Japanese, Chinese, and Western meals so that you are never bored.',
  ratingSubmit: 'Submit',
  ratingComments: 'Comments',
  ratingSubmittedMsg: 'Comments submitted, thank you!',
  langKey: 'eng',
  referralTitle: 'MY REFERRAL CODE',
  referralDesc: 'Invite a friend and get rewarded',
  referralRule1: 'GIVE 30, GET 30',
  referralRule2: 'SPOONFUL COINS',
  referralShare: 'Share',
  referralTips:
    "Share your referral code above! For every friend to uses this code at checkout on their first purchase, we'll give you both 30 Spoonful Coins.",
};
