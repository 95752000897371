/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Grid,
  ListGroup,
  ListGroupItem,
  Panel,
  Button,
} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import * as yup from 'yup';
import Geocode from 'react-geocode';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Redirect } from 'react-router-dom';
import { History } from 'history';
// import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import localization from '../../../localization';
import { CartItem } from '../../../models/CartItem';
import { getLatestPaymentMethod } from '../../../services/auth';
import { AppDispatch, RootState } from '../../../store';
import * as MealsService from '../../../services/meals';
import {
  updateCartItemQuantity as ActionUpdateQuantity,
  getCart as ActionGetCart,
  removeFromCart as ActionRemoveFromCart,
} from '../duck';
import { getUser as ActionGetUser } from '../../Auth/duck';
import { selectLanguage } from '../../Settings/duck/selector';
import { toggleAddressDialog as ActionToggleAddressDialog } from '../../Profile/duck';
import {
  selectShowAddressDialog,
  selectShowAddressMode,
} from '../../Profile/duck/selector';
import {
  selectcartItemCount,
  selectCartItemsByDate,
  selectCartItemsDate,
  selectCartTotal,
} from '../duck/selector';
import './Checkout.scss';
import {
  selectCoinsBalance,
  selectIsLoggedIn,
  selectUser,
} from '../../Auth/duck/selector';
import { User } from '../../../models/User';
import {
  addPaymentMethod,
  placeOrder,
  findZone,
  checkDiscountCode,
} from '../../../services/orders';

import {
  creditCard,
  credits as IconCoin,
  forwardWhiteArrow,
  creditCards,
  minus,
  plus,
  remove,
} from '../../../img';
import AddCoinsModal from '../../Profile/components/AddCoinsModal';
import { Loader, ModalPopup } from '../../../components';
import {
  generalTracker,
  gtagEventTracker,
  dataLayerTracker,
} from '../../../helpers/trackers';
import { DailyMeal } from '../../../models/DailyMeal';
import ErrorMessageHelper from '../../../helpers/ErrorMessageHelper';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_API_KEY || '');
Geocode.setRegion('hk');

const validationSchema = yup.object().shape({
  name: yup.string().required('Please provide a name.'),
  phone: yup
    .string()
    .max(8, 'Contact number length is incorrect')
    .min(8, 'Contact number length is incorrect')
    .required('Please provide a contact number'),
  address: yup.string().required('Please provide an address.'),
  district: yup.string().required('Please provide an district.'),
});

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      // iconColor: '#F6CD16',
      color: '#545454',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#545454',
      },
      '::placeholder': {
        color: '#545454',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

interface FormValues {
  name: string;
  email: string;
  phone: string;
  address: string;
  apartment: string;
  district: string;
  delivery_remarks: string;
  discount_code: string;
  creditCard: string;
}

interface Props {
  user: User;

  isLoggedIn: boolean;

  credits: number;

  cartItems: _.Dictionary<CartItem[]>;

  cartItemDates: string[];

  cartItemCount: number;

  cartTotal: number;

  getCart: () => void;

  getUser: () => void;

  language: string;

  history: History;

  showAddressDialog: boolean;
  showAddressMode: string;
  toggleAddressDialog: (open: boolean, mode?: string) => void;

  updateQuantity: (
    meal: DailyMeal,
    quantity: number,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;

  removeFromCart: (
    meal: DailyMeal,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;
}

const Checkout: React.FC<Props> = ({
  user,
  credits,
  isLoggedIn,
  getCart,
  getUser,
  cartItems,
  cartItemCount,
  cartItemDates,
  cartTotal,
  language,
  history,
  showAddressDialog,
  showAddressMode,
  toggleAddressDialog,
  updateQuantity,
  removeFromCart,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = React.useState(false);
  const [location, setLocation] = React.useState<{
    lat: number;
    lng: number;
  }>();
  const [logisticZone, setLogisticZone] = React.useState<{
    _id: string;
    price: number;
  }>();
  const [invalidAddress, setInvalidAddress] = React.useState<string>();
  const [paymentMethod, setPaymentMethod] = React.useState('creditCard');
  const [savedCreditCards, setSavedCreditCards] = React.useState<any[]>();
  const [types, setTypes] = React.useState<
  { label: string; value: string; selected: boolean }[]
  >();
  const [selectCreditCard, setSelectCreditCard] = React.useState<string>();
  const [infoModal, setInfoModal] = React.useState<string>('');
  const [inputDiscountCode, setInputDiscountCode] = React.useState<string>('');
  const [discountCode, setDiscountCode] = React.useState<any>();
  const [invalidDiscount, setInvalidDiscount] = React.useState<string>();


 
  // Back to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (!isLoggedIn) return;
    generalTracker('InitiateCheckout');
    getCart();

    getLatestPaymentMethod()
      .then((res) => {
        const {
          data: { payment_methods },
        } = res;
        setSavedCreditCards(payment_methods);
        if (payment_methods?.length > 0) {
          // setSelectCreditCard(payment_methods?.[0]?._id);
        }
      })
      .catch((error) => {});

    MealsService.getTypes().then((data) => {
      setTypes(data.map((item) => ({ ...item, selected: false })));
    });
  }, []);

  React.useEffect(() => {
    setLoading(false);
  }, [cartItems]);

  const getBrandLabel = (brandId: string) => {
    const brandName: string =
      types?.find((item) => item.value === brandId)?.label || '';
    return _.get(localization.mealType, brandName) || brandName;
  };

  const updateCartItemQuantity = (meal: DailyMeal, quantity: number) => {
    setLoading(true);
    updateQuantity(
      meal,
      quantity,
      () => {
        setLoading(false);
      },
      (message) => {
        alert(message);
        setLoading(false);
      },
    );
  };

  const removeItemFromCart = (meal: DailyMeal) => {
    setLoading(true);
    removeFromCart(
      meal,
      () => {
        setLoading(false);
      },
      (message) => {
        alert(message);
        setLoading(false);
      },
    );
  };

  React.useEffect(() => {
    setLogisticZone(undefined);
    setInvalidAddress(undefined);

    if (location) {
      findZone(location.lat, location.lng)
        .then((res) => {
          setLogisticZone(res.data.logisticZone);
        })
        .catch((e) => {
          setInvalidAddress('Please enter the correct address.');
        });
    } else {
      setInvalidAddress('Please enter the correct address.');
    }
  }, [location]);

  const validateAddress = React.useCallback(
    _.debounce((address: string) => {
      setLocation(undefined);
      console.log('address', address);
      if (address && address.length > 3) {
        return Geocode.fromAddress(`${address}`)
          .then((response: any) => {
            if (response?.results?.length) {
              setLocation(response.results[0].geometry.location);
            } else {
              setLocation(undefined);
            }
          })
          .catch((e) => {
            setLocation(undefined);
          });
      }
      return null;
    }, 1000),
    [],
  );

  React.useEffect(() => {
    if (user && user.address) {
      validateAddress(user.address);
    }
    if (user && !user.address) {
      toggleAddressDialog(true);
    }
  }, [user]);


  const renderListGroupItem = (dateString: string) => {
    const items = cartItems[dateString];
    const dayTotal = items.reduce(
      (acc, item) => acc + item.dailyMeal.dish.price * item.quantity,
      0,
    );
    const smallBasketSurcharge = Math.max(0, 100 - dayTotal);
    return (
      <ListGroupItem key={dateString}>
        <div className="cart-item-date">
          {moment(dateString, 'MM-DD-YYYY')
            .locale(language)
            .format('dddd, D MMMM YYYY')}
        </div>

        {items.map((item: CartItem) => {
          const { _id, dailyMeal, quantity } = item;
          return (

            <div
              key={`${_id}-${dailyMeal.dish.name}-${dailyMeal.date}-${quantity}`}
              className="vertical-spacing"
              style={{ display: 'flex', flexDirection: 'row', color: 'black' }}
            >
              <div className="cart-item-quantity">
                <img
                  src={minus}
                  style={{
                    marginRight: 10,
                    cursor: 'pointer',
                    marginBottom: 3,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (quantity > 1) {
                      updateCartItemQuantity(dailyMeal, -1);
                    }
                  }}
                />
                {quantity}
                <img
                  src={plus}
                  style={{ marginLeft: 10, cursor: 'pointer', marginBottom: 3 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateCartItemQuantity(item.dailyMeal, 1);
                  }}
                />
              </div>
              <div className="cart-item-name">
                {language === 'zht'
                  ? dailyMeal.dish.nameZh
                  : dailyMeal.dish.name}
              </div>
              <span key="real-price" className="real-price">
                {localization.formatString(
                  localization.hkd,
                  dailyMeal.dish.price * item.quantity,
                )}
              </span>
              <button
                onClick={() => removeItemFromCart(dailyMeal)}
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  marginTop: '-0.3em',
                  alignSelf: 'flex-start',
                  paddingRight: 0,
                }}
              >
                <img src={remove} height="24" width="24" />
              </button>
            </div>
          );
        })}

        <div
          className="vertical-spacing"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div className="surcharge-label">
            {localization.cartSmallBasketSurcharge}
          </div>
          <span className="surcharge">
            <button
              onClick={() => {
                setInfoModal('small-basket');
              }}
              className="btn-info"
              type="button"
              style={{
                backgroundImage: 'url(/img/icon-info.png)',
              }}
            >
              <span>Know More</span>
            </button>
            {' '}
            {(smallBasketSurcharge > 0 &&
              localization.formatString(
                localization.hkd,
                smallBasketSurcharge,
              )) ||
              localization.free}
          </span>
        </div>
        <div
          className="vertical-spacing"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div className="surcharge-label">{localization.cartDeliveryFee}</div>
          <span className="surcharge">
            <button
              onClick={() => {
                setInfoModal('delivery-fee');
              }}
              className="btn-info"
              type="button"
              style={{
                backgroundImage: 'url(/img/icon-info.png)',
              }}
            >
              <span>Know More</span>
            </button>
            {' '}
            {dayTotal < 200
              ? localization.formatString(
                localization.hkd,
                (logisticZone && logisticZone.price) || '--',
              )
              : localization.free}
          </span>
        </div>
      </ListGroupItem>
    );
  };

  const totalWithSurcharge = cartItemDates.reduce((acc, dateString) => {
    const items = cartItems[dateString];
    const dayTotal = items.reduce(
      (dayAcc, item) => dayAcc + item.dailyMeal.dish.price * item.quantity,
      0,
    );
    const smallBasketSurcharge = Math.max(0, 100 - dayTotal);
    const deliveryFee = dayTotal < 200 && logisticZone ? logisticZone.price : 0;

    return acc + dayTotal + smallBasketSurcharge + deliveryFee;
  }, 0);

  const checkoutSuccess = (res: any) => {
    let totalCharge = totalWithSurcharge;
    if (discountCode && discountCode.discount_amount) {
      totalCharge -= discountCode.discount_amount;
    }

    generalTracker('Purchase', { value: `${totalCharge}`, currency: 'HKD' });
    console.log('order info', res);
    const orderInfo = res?.data?.order || {};

    const orderMeals = Object.values(cartItems);
    const orderMealsFlatten = orderMeals.reduce((a, b) => {
      return a.concat(b);
    }, []);
    const orderItems = orderMealsFlatten?.map((item: any) => ({
      id: item._id,
      name: item?.dailyMeal?.dish?.name,
      brand: getBrandLabel(item?.dailyMeal?.dish?.category || ''),
      category: 'SPOONFUL_B2C',
      quantity: item?.quantity,
      price: item?.dailyMeal?.dish?.price,
    }));

    const purchaseInfo = {
      transaction_id: orderInfo?._id,
      affiliation: 'SPOONFUL_B2C',
      value: orderInfo?.total_amount || 0 + orderInfo?.credits_spent || 0,
      currency: 'HKD',
      items: orderItems,
    };
    // const ecommerceInfo = {
    //   event: 'purchase',
    //   ecommerce: {
    //     purchase: {
    //       actionField: {
    //         id: orderInfo?._id,                         // Transaction ID. Required for purchases and refunds.
    //         affiliation: 'SPOONFUL_B2C',
    //         revenue: orderInfo?.total_amount || 0 + orderInfo?.credits_spent || 0,
    //       },
    //       products: orderItems,
    //     },
    //   },
    // };
    console.log('purchase event', purchaseInfo);
    // dataLayerTracker(ecommerceInfo);
    gtagEventTracker('purchase', purchaseInfo);

    setTimeout(() => {
      getCart();
      getUser();
      history.push('/order/success');
    }, 250);
  };

  const displayErr = (err: any) => {
    const errMsg = err.response.data.error.message || '';
    if (errMsg.indexOf('close-msg') > -1) {
      alert(
        ErrorMessageHelper.getErrorMessage('close-msg'),
      );
    } else if (errMsg.indexOf('Can only use referral code for first order') > -1) {
      alert(
        'Whoops! Referral codes are valid for your first Spoonful order only. Please try a different discount code. ',
      );
    } else {
      alert(`There is an error, please try again later. ${errMsg}`);
    }
  };

  const onSubmitLoginForm = async (
    values: FormValues,
    { setSubmitting, setErrors }: FormikHelpers<FormValues>,
  ) => {
    console.log('onSubmitLoginForm', values);
    if (!logisticZone || !location) {
      setErrors({ address: 'Invalid Address' });
      if (!showAddressDialog) {
        toggleAddressDialog(true, 'fail');
      }
      return;
    }

    console.log('paymentMethod', paymentMethod);
    if (paymentMethod === 'creditCard') {
      let creditCardId;
      if (selectCreditCard) {
        creditCardId = selectCreditCard;
      } else {
        if (!stripe || !elements) return;
        const card = elements.getElement(CardElement);

        console.log('card', card);
        if (card) {
          const { error, token } = await stripe.createToken(card);

          if (!token || error) {
            setErrors({
              creditCard:
                error?.message || 'There is an error, please try again later.',
            });
            return;
          }

          const {
            data: { payment_method },
          } = await addPaymentMethod(token?.id);
          creditCardId = payment_method._id;
        } else {
          setErrors({
            creditCard: 'Please input your credit card no.',
          });
          return;
        }
      }

      try {
        const res = await placeOrder(
          creditCardId,
          logisticZone._id,
          location,
          values.address,
          values.district,
          values.delivery_remarks,
          0,
          discountCode && discountCode.coupon ? values.discount_code : '',
          discountCode && discountCode.referredUser ? values.discount_code : '',
        );
        if (res.status === 200) checkoutSuccess(res);
      } catch (e) {
        displayErr('close-msg');
      }
    } else {
      const totalPrice = totalWithSurcharge;
      let totalPriceWithDiscount = totalPrice;
      if (discountCode && discountCode.discount_amount) {
        totalPriceWithDiscount -= discountCode.discount_amount;
      }
      if (credits < totalPriceWithDiscount) {
        alert('close-msg');
        return;
      }

      try {
        const res = await placeOrder(
          '',
          logisticZone._id,
          location,
          values.address,
          values.district,
          values.delivery_remarks,
          totalPrice,
          discountCode && discountCode.coupon ? values.discount_code : '',
          discountCode && discountCode.referredUser ? values.discount_code : '',
        );
        if (res.status === 200) checkoutSuccess(res);
      } catch (e) {
        displayErr('close-msg');
      }
    }
  };

  const subtotalText =
    paymentMethod === 'creditCard'
      ? `HK$ ${cartTotal}`
      : `${cartTotal}Spoonful Coins`;

  const totalText = () => {
    let displayText = totalWithSurcharge;
    if (discountCode && discountCode.discount_amount) {
      displayText -= discountCode.discount_amount;
    }

    return paymentMethod === 'creditCard'
      ? `${localization.cartTotal} ${localization.formatString(
        localization.hkd,
        displayText,
      )}`
      : `${localization.cartTotal} ${Math.round(displayText)} ${
        localization.spoonfulCoins
      }`;
  };

  const discountText = () => {
    let displayText = 0;
    if (discountCode && discountCode.discount_amount) {
      displayText += discountCode.discount_amount;
    }
    if (displayText) {
      return paymentMethod === 'creditCard'
        ? `Discount: ${localization.formatString(
          localization.hkd,
          displayText,
        )}`
        : `Discount: ${displayText} ${localization.spoonfulCoins}`;
    }
    return '';
  };

  React.useEffect(() => {
    setDiscountCode(null);
    setInvalidDiscount('');
    if (!inputDiscountCode) return;
    console.log('checkDiscountCode user', user);
    if (inputDiscountCode === user.referralCode) {
      setInvalidDiscount(
        "Oops! Looks like you've used your own referral code. Please try a code from a different user.",
      );
      return;
    }
    checkDiscountCode(inputDiscountCode, totalWithSurcharge)
      .then((res) => {
        console.log('discount res', res);
        setDiscountCode(res.data);
      })
      .catch((e) => {
        setInvalidDiscount('Invalid discount code.');
      });
  }, [inputDiscountCode, totalWithSurcharge]);

  const validateDiscount = React.useCallback(
    _.debounce((discount: string) => {
      console.log('discount', discount);
      // referredUser
      if (discount) {
        setInputDiscountCode(discount);
      } else {
        setInputDiscountCode('');
      }
      return null;
    }, 1000),
    [],
  );

  return (
    <Grid id="order-summary">
      {loading && (
      <div className="dimmed-loader">
        <Loader size="small" />
      </div>
      )}
      {!isLoggedIn || cartItemCount === 0 && <Redirect to="/" />}
      {/* <Col sm={12} mdOffset={2} md={8}>
        <p className="paragraph">
          {localization.completedSelections}
          <br />
          {localization.getReadyForAnAwesomeWeek}
        </p>
      </Col> */}
      <ModalPopup
        isVisible={infoModal === 'small-basket'}
        onHide={() => setInfoModal('')}
        onExited={() => setInfoModal('')}
        className="login-modal-container"
      >
        <Grid id="login-container">
          <Col xs={12} className="no-padding text-center">
            <p className="desc">{localization.cartMoa}</p>
          </Col>
        </Grid>
      </ModalPopup>
      <ModalPopup
        isVisible={infoModal === 'delivery-fee'}
        onHide={() => setInfoModal('')}
        onExited={() => setInfoModal('')}
        className="login-modal-container"
      >
        <Grid id="login-container">
          <Col xs={12} className="no-padding text-center">
            <p className="desc">{localization.cartDelivery}</p>
          </Col>
        </Grid>
      </ModalPopup>
      <Col sm={12} mdOffset={2} md={8}>
        <Panel>
          
          <Panel.Heading
            className="flex-between"
            style={{ borderColor: 'transparent' }}
          >
            
            <span className="heading-text">
              {localization.myOrders.toUpperCase()}
            </span>
            <span>{`${cartItemCount} ${localization.cartItems}`}</span>
          </Panel.Heading>
          {!_.isEmpty(cartItemDates) && (
            <ListGroup>
              {cartItemDates.map((date) => renderListGroupItem(date))}
            </ListGroup>
          )}
        </Panel>
        <Formik
          initialValues={{
            email: user.email,
            name: user.memberName,
            phone: user.phone,
            address: user.address,
            apartment: '',
            district: user.district,
            discount_code: '',
            delivery_remarks: '',
            creditCard: '',
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmitLoginForm}
        >
          {({ isSubmitting, errors, handleChange, values, submitForm }) => (
            <Form>
              <Panel>
                <Panel.Heading>
                  <span className="heading-text">
                    {localization.deliveryInfo.toUpperCase()}
                  </span>
                </Panel.Heading>
                <ListGroup key="delivery-info">
                  <ListGroupItem key="delivery-name">
                    <label>{localization.name}</label>
                    <Field name="name" type="text" />
                    {errors.name && (
                      <div className="error-message">{errors.name}</div>
                    )}
                    <label>{localization.email}</label>
                    <Field name="email" type="text" disabled />
                    <label>{localization.contactNumber}</label>
                    <Field name="phone" type="text" />
                    {errors.phone && (
                      <div className="error-message">{errors.phone}</div>
                    )}
                    <label>{localization.address}</label>
                    <Field
                      name="address"
                      type="text"
                      onChange={(e: any) => {
                        if (e.target.value !== values.address) {
                          validateAddress(e.target.value);
                        }
                        handleChange(e);
                      }}
                    />
                    {errors.address && (
                      <div className="error-message">{errors.address}</div>
                    )}
                    <span className="error-message">{invalidAddress}</span>
                    <label>{localization.buildingApartmentUnit}</label>
                    <Field name="district" type="text" />
                    {errors.district && (
                      <div className="error-message">{errors.district}</div>
                    )}
                    <label>{localization.deliveryNotesToDriver}</label>
                    <Field name="delivery_remarks" type="text" />
                    {/* <label>District</label>
                    <Field name="district" component="select">
                      <option value="HK Island">HK Island</option>
                      <option value="Kowloon">Kowloon</option>
                      <option value="New Territories">New Territories</option>
                    </Field> */}
                  </ListGroupItem>
                </ListGroup>
              </Panel>
              <Panel>
                <Panel.Heading>
                  <span className="heading-text">
                    {localization.payment.toUpperCase()}
                  </span>
                  {/* <div>
                    <label>Subtotal</label>
                  </div>
                  <div className="value-text">{subtotalText}</div> */}
                  <div>
                    <label>{localization.discountReferralCode}</label>
                    <Field
                      name="discount_code"
                      type="text"
                      onChange={(e: any) => {
                        if (e.target.value !== values.discount_code) {
                          validateDiscount(e.target.value);
                        }
                        handleChange(e);
                      }}
                    />
                    {discountText() && <label>{discountText()}</label>}
                  </div>

                  {errors.discount_code && (
                    <div className="error-message">{errors.discount_code}</div>
                  )}
                  <span className="error-message">{invalidDiscount}</span>
                  <div className="margin-top big-title">{totalText()}</div>
                </Panel.Heading>
                <Panel.Body>
                  <div className="flex" style={{ alignItems: 'center' }}>
                    <Button
                      className={`${
                        paymentMethod === 'creditCard' ? 'selected' : ''
                      } payment-option-selector`}
                      onClick={() => setPaymentMethod('creditCard')}
                    >
                      <img src={creditCard} />
                      <span>{localization.creditCard}</span>
                    </Button>
                    <div style={{ width: '1em' }} />
                    <Button
                      className={`${
                        paymentMethod === 'coins' ? 'selected' : ''
                      } payment-option-selector`}
                      onClick={() => setPaymentMethod('coins')}
                    >
                      <img src={IconCoin} />
                      <span>{localization.spoonfulCoins}</span>
                    </Button>
                  </div>
                  {paymentMethod === 'creditCard' ? (
                    <>
                      <label>{localization.creditCardDetails}</label>
                      {/* <RadioGroup 
                        aria-label="credit-card" 
                        name="credit-card" 
                        value={selectCreditCard} 
                        onChange={(event) => setSelectCreditCard(event.target.value)}
                      >
                        {savedCreditCards?.map((card: any, index) => {
                          const {
                            source: { brand, last4 },
                          } = card;
                          const creditCardImg = (creditCards as any)[
                            brand.toLowerCase()
                          ];

                          return (
                            <FormControlLabel
                              checked={card._id === selectCreditCard}
                              value={card._id}
                              control={<Radio />}
                              label={(
                                <div>
                                  {creditCardImg && (
                                  <img
                                    src={creditCardImg}
                                    style={{ height: 16, marginRight: 24 }}
                                  />
                                  )}
                                  {`xxxx-xxxx-xxxx-${last4}`}
                                </div>
                              )}
                            />
                          );
                        })}
                      </RadioGroup> */}
                      <div
                        style={{ border: '0.5px solid #e7e7e7', padding: 25 }}
                      >
                        <CardElement
                          options={CARD_OPTIONS}
                          // onBlur={}
                          onFocus={() => {
                            setSelectCreditCard(undefined);
                          }}
                        />
                      </div>
                      {errors.creditCard && (
                        <div className="error-message">{errors.creditCard}</div>
                      )}
                    </>
                  ) : (
                    <>
                      <label className="coins-remaining">
                        {localization.spoonfulCoinsRemaining.toUpperCase()}
                      </label>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 24,
                        }}
                      >
                        <img className="coin-image" src={IconCoin} />
                        <span
                          className="value-text"
                          style={{ color: 'black', flex: 1 }}
                        >
                          {`${localization.coins.toUpperCase()}: ${credits}`}
                        </span>
                        <AddCoinsModal />
                      </div>
                    </>
                  )}
                  <div className="action-btn-container">
                    <Button onClick={submitForm} disabled={isSubmitting}>
                      <span>{localization.confirmAndPay.toUpperCase()}</span>
                      <img src={forwardWhiteArrow} width="18" />
                    </Button>
                  </div>
                </Panel.Body>
              </Panel>
            </Form>
          )}
        </Formik>
      </Col>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),

  isLoggedIn: selectIsLoggedIn(state),

  language: selectLanguage(state),

  credits: selectCoinsBalance(state),

  cartItems: selectCartItemsByDate(state),

  cartItemDates: selectCartItemsDate(state),

  cartItemCount: selectcartItemCount(state),

  cartTotal: selectCartTotal(state),

  showAddressDialog: selectShowAddressDialog(state),
  showAddressMode: selectShowAddressMode(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCart: () => dispatch(ActionGetCart()),
  getUser: () => dispatch(ActionGetUser()),
  toggleAddressDialog: (open: boolean, mode?: string) =>
    dispatch(ActionToggleAddressDialog({ open, mode: mode || 'checkout' })),

  updateQuantity: (
    meal: DailyMeal,
    quantity: number,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => dispatch(ActionUpdateQuantity({ meal, quantity, onSuccess, onError })),

  removeFromCart: (
    meal: DailyMeal,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => dispatch(ActionRemoveFromCart({ meal, onSuccess, onError })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
