import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import { setToken } from '../services/client';
import { AuthState } from '../features/Auth/duck';
import { SettingsState } from '../features/Settings/duck';
import localization from '../localization';

export const history = createBrowserHistory();

const persistToken = createTransform(
  (inboundState) => inboundState,
  (outboundState: AuthState) => {
    const { token } = outboundState;
    if (token) setToken(token);
    return outboundState;
  },
  { whitelist: ['auth'] },
);

const persistLang = createTransform(
  (inboundState) => inboundState,
  (outboundState: SettingsState) => {
    const { language } = outboundState;
    localization.setLanguage(language);
    return outboundState;
  },
  { whitelist: ['settings'] },
);

const persistConfig = {
  key: 'spoonfulroot',
  version: 1,
  blacklist: ['shoppingCart'],
  whitelist: ['auth', 'settings', 'orders'],
  transforms: [persistToken, persistLang],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
