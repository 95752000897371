/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { ResponseLogin } from '../../../models/ResponseLogin';
// import { User } from '../../../models/User';
// import * as AuthService from '../../../services/auth';

export type ProfileState = {
  showAddressDialog: boolean;
  showAddressMode: string;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    showAddressDialog: false,
    showAddressMode: '',
  } as ProfileState,
  reducers: {
    toggleAddressDialog(
      state,
      action: PayloadAction<{
        open: boolean;
        mode: string;
      }>,
    ) {
      state.showAddressDialog = action.payload.open;
      state.showAddressMode = action.payload.mode || 'signup';
    },
  },
});

const { actions, reducer } = authSlice;

export const { toggleAddressDialog } = actions;

export default reducer;
