import * as ReactPixel from 'react-facebook-pixel';

export interface Options {
  autoConfig: boolean;
  debug: boolean;
}

export interface AdvancedMatching {
  ct: string;
  country: string;
  db: string;
  em: string;
  fn: string;
  ge: string;
  ln: string;
  ph: string;
  st: string;
  zp: string;
}

interface DefaultExport {
  init(
    pixelId: string,
    advancedMatching?: AdvancedMatching,
    options?: Options,
  ): void;
  pageView(): void;
  track(title: string, data: any): void;
  trackCustom(title: string, data: any): void;
}

// react-facebook-pixel typing is wrong, so export again. Can be removed after the library fixed the typing issue
const pixel = ((ReactPixel as any).default as unknown) as DefaultExport;
const ReactPixelOptions = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
pixel.init('710827106284993', undefined, ReactPixelOptions);

export default pixel;
