/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Image, Button, Label, Glyphicon } from 'react-bootstrap';
import _ from 'lodash';
import { Meal, Recipe } from '../../../../models/Meal';
import { AppDispatch, RootState } from '../../../../store';
import localization from '../../../../localization';
import { selectLanguage } from '../../../Settings/duck/selector';
import { star, starBlank, starHalf } from '../../../../img';
import { addToCart as ActionAddToCart } from '../../../Orders/duck';
import './MealDetailModal.scss';
import { DailyMeal } from '../../../../models/DailyMeal';

import * as MealsService from '../../../../services/meals';

interface Props {
  isVisible: boolean;
  meal: DailyMeal;
  language: string;
  onHide: () => void;
  addToCart: (
    meal: DailyMeal,
    quantity: number,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;
}

const MealDetailModal: React.FC<Props> = ({
  isVisible,
  meal,
  onHide,
  language,
  addToCart,
}) => {
  const [gotRating, setGotRating] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1);

  React.useEffect(() => {
    if (!gotRating) {
      MealsService.getDishRating(meal?.dish?.id).then((data) => {
        const avgRatings = data?.data?.average[0]?.average_rating || 0;
        setGotRating(true);
        setRating(avgRatings);
      });
    }
  }, [rating]);

  const renderRatingsContainer = () => {
    const ratingStars = [1, 2, 3, 4, 5];

    if (!rating || rating < 4) return null;

    return (
      <div className="ratings-container">
        <div className="ratings-container">
          {ratingStars.map((value) => {
            let icon = starBlank;
            if (value <= rating) icon = star;
            else if (value <= rating + 0.5) icon = starHalf;
            return <img className="star-icon" src={icon} width="18" />;
          })}
        </div>
        <div>
          <span className="ratings-amount">
            {`${_.ceil(rating, 1).toFixed(1)} ${
              localization.menuModalUserRatings
            }`}
          </span>
        </div>
      </div>
    );
  };

  const renderMealRecipes = () => {
    if (!meal.dish || meal.dish.recipes.length === 0) return null;
    return (
      <div className="recipes-container">
        <div>
          {meal?.dish?.recipes.map((recipe: Recipe) => {
            const recipeImage = meal.dish.getIconImageFor(recipe);
            if (recipeImage) return <img src={recipeImage} />;
            return null;
          })}
        </div>
      </div>
    );
  };

  const increase = () => {
    if (quantity < meal.stock) setQuantity((state) => state + 1);
  };

  const decrease = () => {
    if (quantity) setQuantity((state) => state - 1);
  };

  const onPressAddToCart = () => {
    if (meal.stock === 0 || quantity > meal.stock) return;

    addToCart(
      meal,
      quantity,
      () => {
        onHide();
      },
      (error) => {
        alert(error);
      },
    );
  };

  const getLocalizedMealAttribute = (enAttr: string, zhAttr: string) => {
    if (!meal) return '';
    const en = (meal.dish as any)[enAttr] || '';
    if (language === 'zht') return (meal.dish as any)[zhAttr] || en;
    return en;
  };

  const localizedDescription = getLocalizedMealAttribute(
    'description',
    'descriptionZh',
  );

  const localiedIngredients = getLocalizedMealAttribute(
    'ingredients',
    'ingredientsZh',
  );

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      onExited={() => {
        setQuantity(1);
      }}
    >
      <div className="close-btn-container">
        <Button className="close-btn" onClick={onHide} />
      </div>
      <Modal.Body>
        {meal && (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div
              className="meal-details-left-col"
              style={{ position: 'relative' }}
            >
              {meal.stock === 0 && (
                <Label
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    fontSize: 14,
                    backgroundColor: '#f7b703e3',
                  }}
                >
                  <Glyphicon
                    glyph="ban-circle"
                    style={{
                      marginRight: 5,
                      fontSize: 18,
                      verticalAlign: 'text-bottom',
                    }}
                  />
                  {localization.menuModalSoldOut}
                </Label>
              )}
              <Image src={meal.dish.imageUrl} responsive />
              <div className="text title">
                {getLocalizedMealAttribute('name', 'nameZh')}
              </div>
              <div>
                {meal && (
                  <div className="nutrition-container">
                    <div>
                      <div className="secondary-text">
                        {localization.calories.toLowerCase()}
                      </div>
                      <div className="secondary-text">{meal.dish.calories}</div>
                    </div>
                    <div>
                      <div className="secondary-text">
                        {localization.protein.toLowerCase()}
                      </div>
                      <div className="secondary-text">{meal.dish.protein}</div>
                    </div>
                    <div>
                      <div className="secondary-text">
                        {localization.carbs.toLowerCase()}
                      </div>
                      <div className="secondary-text">{meal.dish.carbs}</div>
                    </div>
                    <div>
                      <div className="secondary-text">
                        {localization.fats.toLowerCase()}
                      </div>
                      <div className="secondary-text">{meal.dish.fat}</div>
                    </div>
                  </div>
                )}
              </div>
              {renderRatingsContainer()}
              <div className="separator" />
            </div>
            <div className="meal-details-right-col">
              {localizedDescription !== '' && (
                <div className="description-container">
                  <div className="text selected-btn">
                    {localization.menuModalDescription}
                  </div>
                  <div className="description-text">{localizedDescription}</div>
                </div>
              )}

              {localiedIngredients !== '' && (
                <div className="description-container">
                  <div className="text selected-btn">
                    {localization.menuModalIngredients}
                  </div>
                  <div className="description-text">{localiedIngredients}</div>
                </div>
              )}
              {renderMealRecipes()}
            </div>
            {meal.stock > 0 && (
              <div style={{ width: '100%', marginTop: '1em' }}>
                <div className="quantity-container">
                  <Button onClick={decrease} disabled={quantity === 1}>
                    -
                  </Button>
                  <div className="text">{quantity}</div>
                  <Button onClick={increase} disabled={quantity === meal.stock}>
                    +
                  </Button>
                  <Button onClick={onPressAddToCart}>
                    {localization.menuModalAddToCart}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  language: selectLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addToCart: (
    meal: DailyMeal,
    quantity: number,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => dispatch(ActionAddToCart({ meal, quantity, onSuccess, onError })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealDetailModal);
