import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import { RootState } from '../../../store';

import { CartItem } from '../../../models/CartItem';

export const selectOrders = (state: RootState) => state.orders;

export const selectCartItems = createSelector(
  selectOrders,
  (orders) => orders.cartItems,
);

export const selectCartItemsByDate = createSelector(
  selectCartItems,
  (cartItems) =>
    _.groupBy<CartItem>(cartItems, (item) =>
      moment.tz(item.dailyMeal.date, 'Asia/Hong_Kong').format('MM-DD-YYYY'),
    ),
);

export const selectCartItemsDate = createSelector(
  selectCartItemsByDate,
  (cartItems) =>
    Object.keys(cartItems).sort((a, b) => moment(a).unix() - moment(b).unix()),
);

export const selectcartItemCount = createSelector(
  selectCartItems,
  (cartItems) =>
    cartItems.reduce(
      (acc: number, cartItem: CartItem) => acc + cartItem.quantity,
      0,
    ),
);

export const selectCartTotal = createSelector(
  selectOrders,
  (orders) => orders.total,
);

export const selectTempCartItems = createSelector(
  selectOrders,
  (orders) => orders.tempCartItems,
);

export const selectTempCartItemsByDate = createSelector(
  selectTempCartItems,
  (cartItems) =>
    _.groupBy<CartItem>(cartItems, (item) =>
      moment.tz(item.dailyMeal.date, 'Asia/Hong_Kong').format('MM-DD-YYYY'),
    ),
);

export const selectTempCartItemsDate = createSelector(
  selectTempCartItemsByDate,
  (cartItems) =>
    Object.keys(cartItems).sort((a, b) => moment(a).unix() - moment(b).unix()),
);

export const selectTempCartItemCount = createSelector(
  selectTempCartItems,
  (cartItems) =>
    cartItems.reduce(
      (acc: number, cartItem: CartItem) => acc + cartItem.quantity,
      0,
    ),
);

export const selectTempCartTotal = createSelector(
  selectTempCartItems,
  (cartItems) =>
    cartItems.reduce(
      (acc: number, cartItem: CartItem) =>
        acc + cartItem.dailyMeal.dish.price * cartItem.quantity,
      0,
    ),
);
