import React from 'react';
import * as yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Loader } from '../../../components';
import localization from '../../../localization';
import * as AuthService from '../../../services/auth';
import { AppDispatch } from '../../../store';
import { ResponseLogin } from '../../../models/ResponseLogin';
import { gtagEventTracker } from '../../../helpers/trackers';
import { setUser } from '../duck';
import { transformCart } from '../../Orders/duck';
import { toggleAddressDialog as ActionToggleAddressDialog } from '../../Profile/duck';

interface FormValues {
  email: string;
  name: string;
  phone: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please provide your email')
    .email('Please provide a valid email'),
  name: yup.string().required('Please provide your name'),
  phone: yup
    .string()
    .max(8, 'Contact number length is incorrect')
    .min(8, 'Contact number length is incorrect')
    .required('Please provide a contact number'),
  password: yup
    .string()
    .min(8, 'Password should be at least 8 characters.')
    .required('Please provide your password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], "Passwords don't match")
    .required('Confirm Password is required'),
});

interface Props {
  onLoginPress: () => void;

  onLoginSuccess: (payload: ResponseLogin) => void;
}

const SignUpForm: React.FC<Props> = ({ onLoginPress, onLoginSuccess }) => {
  const onSubmit = (
    { confirmPassword, ...others }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    AuthService.signUp(others)
      .then((response) => {
        onLoginSuccess(response);
        gtagEventTracker('sign_up');
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // const repsonseFacebook = (response: any) => {
  //   AuthService.loginByFacebook(response.accessToken)
  //     .then((res: any) => {
  //       login(new Access(res.data));
  //     })
  //     .catch((err: Error) => {
  //       console.log('FB login error', err);
  //     });
  // };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          name: '',
          password: '',
          confirmPassword: '',
          phone: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            {isSubmitting && (
              <div className="dimmed-loader">
                <Loader />
              </div>
            )}
            <Field
              name="email"
              type="text"
              placeholder={`${localization.email}*`}
            />
            {errors.email && (
              <div className="error-message">{errors.email}</div>
            )}
            <Field
              name="name"
              type="text"
              placeholder={`${localization.name}*`}
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
            <Field
              name="phone"
              type="text"
              placeholder={`${localization.loginModalPhoneNumber}*`}
            />
            {errors.phone && (
              <div className="error-message">{errors.phone}</div>
            )}
            <Field
              name="password"
              type="password"
              placeholder={`${localization.loginModalPassword}*`}
              autocomplete="new-password"
            />
            {errors.password && (
              <div className="error-message">{errors.password}</div>
            )}
            <Field
              name="confirmPassword"
              type="password"
              placeholder={`${localization.loginModalConfirm}*`}
              autocomplete="new-password"
            />
            {errors.confirmPassword && (
              <div className="error-message">{errors.confirmPassword}</div>
            )}
            <Button
              className="next-btn bottom-spacing"
              type="submit"
              disabled={isSubmitting}
            >
              {localization.signup.toUpperCase()}
            </Button>
          </Form>
        )}
      </Formik>
      <div className="join-us">{localization.loginModalAlreadySignedUp}</div>
      <Button onClick={onLoginPress} className="sign-up-btn" bsStyle="link">
        {localization.login}
      </Button>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onLoginSuccess: (payload: ResponseLogin) => {
    dispatch(setUser(payload));
    dispatch(transformCart());
    dispatch(ActionToggleAddressDialog({ open: true, mode: 'signup' }));
  },
});

export default connect(null, mapDispatchToProps)(SignUpForm);
