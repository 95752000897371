/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Col, Grid, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import * as AuthService from '../../../services/auth';
import { Loader } from '../../../components';
import './AuthActionsStyling.scss';

interface FormValues {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required('Please provide your email'),
});

const ForgotPasswordContainer = () => {
  const [submitted, setSubmitted] = React.useState(false);

  if (submitted) return <Redirect to="/" push />;

  return (
    <Grid id="auth-actions-container" className="forget-password-form">
      <Col xs={12} sm={6} smOffset={3} lg={4} lgOffset={4}>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={(
            values: FormValues,
            { setSubmitting, setStatus, resetForm }: FormikHelpers<FormValues>,
          ) => {
            AuthService.resetPassword(values.email, '')
              .then((response) => {
                resetForm();
                setStatus('success');
              })
              .catch((error) => {
                alert(error);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              {isSubmitting && (
                <div className="dimmed-loader">
                  <Loader />
                </div>
              )}
              <div className="title">FORGET PASSWORD</div>
              <div className="forget-input-group">
                <label htmlFor="email">Email</label>
                <Field id="email" name="email" type="email" />
                {errors.email && (
                  <div style={{ color: 'red', marginTop: '.5rem' }}>
                    {errors.email}
                  </div>
                )}
              </div>
              <Button
                className="next-btn bottom-spacing"
                type="submit"
                disabled={isSubmitting}
              >
                RESET PASSWORD
              </Button>
            </Form>
          )}
        </Formik>
      </Col>
    </Grid>
  );
};

export default ForgotPasswordContainer;
