import * as React from 'react';
import './Loader.scss';

interface Props {
  size?: string;
  cartLoader?: boolean;
}

const Loader = ({ size = 'medium', cartLoader }: Props) =>
  cartLoader ? (
    <div
      style={{
        alignContent: 'center',
        justifyContent: 'center',
        marginTop: '10%',
      }}
    >
      <div className={`loader ${size}`} style={{ marginLeft: '45%' }} />
      <div
        style={{
          marginTop: 20,
          fontFamily: 'Josefin Sans',
          fontSize: 20,
          textAlign: 'center',
        }}
      >
        One moment..
        <br />
        we&apos;re just chopping your ingredients
      </div>
    </div>
  ) : (
    <div className={`loader ${size}`} />
  );
export default Loader;
